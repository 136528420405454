import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  // updateMaterialType,
  changeWarehouseStatus,
  updateWarehousependingDetails,
} from '../../../store/actions/vendor/warehouseList';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormSuccess } from '../../helper/FormSuccess';
import Spinner from 'react-bootstrap/Spinner';
import ErrorCard, { FormErrorCard } from '../../helper/ErrorCard';
import { CardLoader } from '../../helper/CustomLoader';
// import { TroubleshootRounded } from '@mui/icons-material';

const MaterialTypeForm = ({ warehouseId, slug, viewMood }) => {
  // const [error, setError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSELIST);
  // console.log("MaterialSTATE===>", data.singleFormData);
  const [remarkForm, setRemarkForm] = useState({
    dryMaterial: {
      value: '',
      whsremark: '',
    },
    wetMaterial: {
      value: '',
      whsremark: '',
    },
    agriAndAlliedIndustries: {
      value: '',
      whsremark: '',
    },
    autoMobiles: {
      value: '',
      whsremark: '',
    },
    autoComponents: {
      value: '',
      whsremark: '',
    },
    aviation: {
      value: '',
      whsremark: 'aviation custom action',
    },
    chemicals: [
      {
        type: 'dryup',
        chemical: {
          value: '',
          whsremark: 'HI',
        },
      },
      {
        type: 'wetup',
        chemical: {
          value: '',
          whsremark: 'hello',
        },
      },
    ],
    consumerDurables: {
      value: '',
      whsremark: '',
    },
    ecommerce: {
      value: '',
      whsremark: '',
    },
    educationRelated: {
      value: '',
      whsremark: '',
    },
    engineeringGoods: {
      value: '',
      whsremark: '',
    },
    fmcg: {
      value: '',
      whsremark: '',
    },
    healthAndPharma: {
      value: '',
      whsremark: '',
    },
    itAndItes: {
      value: '',
      whsremark: '',
    },
    manufacturing: {
      value: '',
      whsremark: '',
    },
    mediaAndEntertainment: {
      value: '',
      whsremark: '',
    },
    oilAndGas: {
      value: '',
      whsremark: '',
    },
    power: {
      value: '',
      whsremark: '',
    },
    renewableEnergy: {
      value: '',
      whsremark: '',
    },
    // retail: {
    //   value: '',
    //   whsremark: '',
    // },
    scienceAndTechnology: {
      value: '',
      whsremark: '',
    },
    steel: {
      value: '',
      whsremark: '',
    },
    telecommunication: {
      value: '',
      whsremark: '',
    },
    textTiles: {
      value: '',
      whsremark: '',
    },
    tourismAndHospitality: {
      value: '',
      whsremark: '',
    },
    prominentCustomersInTheWarehouse: {
      value: '',
      whsremark: '',
    },
  });

  const [materialForm, setMaterialForm] = useState({
    dryMaterial: false,
    wetMaterial: false,
    agriAndAlliedIndustries: false,
    autoMobiles: false,
    autoComponents: false,
    aviation: false,
    chemicalDry: false,
    chemicalWet: false,
    consumerDurables: false,
    ecommerce: false,
    educationRelated: false,
    engineeringGoods: false,
    fmcg: false,
    healthAndPharma: false,
    itAndItes: false,
    manufacturing: false,
    mediaAndEntertainment: false,
    oilAndGas: false,
    power: false,
    renewableEnergy: false,
    retail: false,
    scienceAndTechnology: false,
    steel: false,
    telecommunication: false,
    textTiles: false,
    tourismAndHospitality: false,
    prominentCustomersInTheWarehouse: '',
  });

  const {
    dryMaterial,
    wetMaterial,
    agriAndAlliedIndustries,
    autoMobiles,
    autoComponents,
    aviation,
    chemicalDry,
    chemicalWet,
    consumerDurables,
    ecommerce,
    educationRelated,
    engineeringGoods,
    fmcg,
    healthAndPharma,
    itAndItes,
    manufacturing,
    mediaAndEntertainment,
    oilAndGas,
    power,
    renewableEnergy,
    // retail,
    scienceAndTechnology,
    steel,
    telecommunication,
    textTiles,
    tourismAndHospitality,
    prominentCustomersInTheWarehouse,
  } = materialForm;

  const handleChange = (slug, e) => {
    console.log(e.target.name, remarkForm, 'on change section', e.target.name.split('.')[0]);
    const removeDots = e.target.name.split('.')[0];
    const remarkFormForm = remarkForm;
    remarkFormForm[slug].value = e.target.value;
    setRemarkForm({ ...remarkForm, [`${slug}`.value]: e.target.value });
    setMaterialForm({ ...materialForm, [removeDots]: e.target.value === 'true' });
    console.log(e.target.name, remarkForm, 'on change section 2');
  };

  const handleChangeAry = (chemicl, slug, e) => {
    setRemarkForm({ ...remarkForm, [`chemicals.${slug}.chemical`.value]: e.target.value });
    setMaterialForm({ ...materialForm, [chemicl]: e.target.value === 'true' });
  };
  const handleChange1 = (slug, e) => {
    // console.log(slug, '|****|', e.target.value);
    // setRemarkForm({ ...remarkForm, [`${slug}`.value]: e.target.value });
    setMaterialForm({
      ...materialForm,
      [`prominentCustomersInTheWarehouse`]: e.target.value,
    });
    console.log(materialForm, 'Hello action trigger');
  };

  const onSubmit = (fields) => {
    // console.log(fields, 'submit action trigger', materialForm.prominentCustomersInTheWarehouse);
    fields.dryMaterial = { value: dryMaterial, whsremark: remarkForm.dryMaterial.whsremark };
    fields.wetMaterial = { value: wetMaterial, whsremark: remarkForm.wetMaterial.whsremark };
    fields.agriAndAlliedIndustries = {
      value: agriAndAlliedIndustries,
      whsremark: remarkForm.agriAndAlliedIndustries.whsremark,
    };
    fields.autoMobiles = { value: autoMobiles, whsremark: remarkForm.autoMobiles.whsremark };
    fields.chemicals[0].chemical = {
      value: chemicalDry,
      whsremark: remarkForm.chemicals[0].chemical.whsremark,
    };
    fields.chemicals[1].chemical = {
      value: chemicalWet,
      whsremark: remarkForm.chemicals[1].chemical.whsremark,
    };
    fields.autoComponents = {
      value: autoComponents,
      whsremark: remarkForm.autoComponents.whsremark,
    };
    fields.aviation = { value: aviation, whsremark: remarkForm.aviation.whsremark };
    fields.consumerDurables = {
      value: consumerDurables,
      whsremark: remarkForm.consumerDurables.whsremark,
    };
    fields.ecommerce = { value: ecommerce, whsremark: remarkForm.ecommerce.whsremark };
    fields.educationRelated = {
      value: educationRelated,
      whsremark: remarkForm.educationRelated.whsremark,
    };
    fields.engineeringGoods = {
      value: engineeringGoods,
      whsremark: remarkForm.engineeringGoods.whsremark,
    };
    fields.healthAndPharma = {
      value: healthAndPharma,
      whsremark: remarkForm.healthAndPharma.whsremark,
    };
    fields.mediaAndEntertainment = {
      value: mediaAndEntertainment,
      whsremark: remarkForm.mediaAndEntertainment.whsremark,
    };
    fields.renewableEnergy = {
      value: renewableEnergy,
      whsremark: remarkForm.renewableEnergy.whsremark,
    };
    fields.fmcg = { value: fmcg, whsremark: remarkForm.fmcg.whsremark };
    fields.itAndItes = { value: itAndItes, whsremark: remarkForm.itAndItes.whsremark };
    fields.manufacturing = { value: manufacturing, whsremark: remarkForm.manufacturing.whsremark };
    fields.oilAndGas = { value: oilAndGas, whsremark: remarkForm.oilAndGas.whsremark };
    fields.power = { value: power, whsremark: remarkForm.power.whsremark };
    fields.scienceAndTechnology = {
      value: scienceAndTechnology,
      whsremark: remarkForm.scienceAndTechnology.whsremark,
    };
    fields.steel = { value: steel, whsremark: remarkForm.steel.whsremark };
    fields.telecommunication = {
      value: telecommunication,
      whsremark: remarkForm.telecommunication.whsremark,
    };
    fields.textTiles = { value: textTiles, whsremark: remarkForm.textTiles.whsremark };
    fields.tourismAndHospitality = {
      value: tourismAndHospitality,
      whsremark: remarkForm.tourismAndHospitality.whsremark,
    };
    // fields.prominentCustomersInTheWarehouse = {
    //   value: materialForm.prominentCustomersInTheWarehouse,
    //   whsremark: remarkForm.prominentCustomersInTheWarehouse.whsremark,
    // };

    const payload = {
      data: {
        fields: fields,
      },
      warehouseId: warehouseId,
      slug: slug,
    };
    console.log(payload, 'hello abcd');
    // return false;
    dispatch(updateWarehousependingDetails(payload));
  };

  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/update-warehouse-rejected/${warehouseId}/warehouse-photo`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);

  useEffect(() => {
    if (
      data?.singleFormData?.remarks === 0 &&
      data?.singleFormData?.warehouseRej?.warehouseMaterialTypes !== null &&
      data?.singleFormData?.warehouseRej?.warehouseMaterialTypes !== undefined
    ) {
      setMaterialForm({
        dryMaterial:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.dryMaterial === true
            ? true
            : false,
        wetMaterial:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.wetMaterial === true
            ? true
            : false,
        agriAndAlliedIndustries:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.agriAndAlliedIndustries ===
            true
            ? true
            : false,
        autoMobiles:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.autoMobiles === true
            ? true
            : false,
        autoComponents:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.autoComponents === true
            ? true
            : false,
        aviation:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.aviation === true
            ? true
            : false,
        chemicalDry:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.chemicals[0]?.active ===
            true
            ? true
            : false,
        chemicalWet:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.chemicals[1]?.active ===
            true
            ? true
            : false,
        consumerDurables:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.consumerDurables === true
            ? true
            : false,
        ecommerce:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.ecommerce === true
            ? true
            : false,
        educationRelated:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.educationRelated === true
            ? true
            : false,
        engineeringGoods:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.engineeringGoods === true
            ? true
            : false,
        fmcg:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.fmcg === true
            ? true
            : false,
        healthAndPharma:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.healthAndPharma === true
            ? true
            : false,
        itAndItes:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.itAndItes === true
            ? true
            : false,
        manufacturing:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.manufacturing === true
            ? true
            : false,
        mediaAndEntertainment:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.mediaAndEntertainment ===
            true
            ? true
            : false,
        oilAndGas:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.oilAndGas === true
            ? true
            : false,
        power:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.power === true
            ? true
            : false,
        renewableEnergy:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.renewableEnergy === true
            ? true
            : false,
        // retail: data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.dryMaterial?.value!==""?true:false,
        scienceAndTechnology:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.scienceAndTechnology ===
            true
            ? true
            : false,
        steel:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.steel === true
            ? true
            : false,
        telecommunication:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.telecommunication === true
            ? true
            : false,
        textTiles:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.textTiles === true
            ? true
            : false,
        tourismAndHospitality:
          data?.singleFormData?.warehouseRej?.warehouseMaterialTypes[0]?.tourismAndHospitality ===
            true
            ? true
            : false,
        // prominentCustomersInTheWarehouse: '',
      });
    } else {
      if (
        data?.singleFormData !== null &&
        data?.singleFormData?.warehouseRej !== null &&
        data?.singleFormData?.warehouseRej !== undefined &&
        data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks !== undefined &&
        Object.keys(data?.singleFormData?.warehouseRej.warehouseMaterialTypesRemarks).length > 0
      ) {
        // console.log(
        //   '|**|hey i am outer if material section|**|',
        //   data?.singleFormData?.warehouseRej.warehouseMaterialTypesRemarks,
        // );
        setRemarkForm({
          dryMaterial: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.dryMaterial
                ?.whsremark,
          },
          wetMaterial: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.wetMaterial
                ?.whsremark,
          },
          agriAndAlliedIndustries: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks
                ?.agriAndAlliedIndustries?.whsremark,
          },
          autoMobiles: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.autoMobiles
                ?.whsremark,
          },
          autoComponents: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.autoComponents
                ?.whsremark,
          },
          aviation: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.aviation
                ?.whsremark,
          },
          chemicals: [
            {
              type: 'dryup',
              chemical: {
                value: '',
                whsremark:
                  data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[0]
                    ?.chemical?.whsremark,
                // data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[0]
                //   ?.chemical?.whsremark,
              },
            },
            {
              type: 'wetup',
              chemical: {
                value: '',
                whsremark:
                  data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[1]
                    ?.chemical?.whsremark,
                // data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[1]
                //   ?.chemical?.whsremark,
              },
            },
          ],
          consumerDurables: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.consumerDurables
                ?.whsremark,
          },
          ecommerce: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.ecommerce
                ?.whsremark,
          },
          educationRelated: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.educationRelated
                ?.whsremark,
          },
          engineeringGoods: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.engineeringGoods
                ?.whsremark,
          },
          fmcg: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.fmcg?.whsremark,
          },
          healthAndPharma: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.healthAndPharma
                ?.whsremark,
          },
          itAndItes: {
            value: '',
            whsremark:
              data.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.itAndItes
                ?.whsremark,
          },
          manufacturing: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.manufacturing
                ?.whsremark,
          },
          mediaAndEntertainment: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks
                ?.mediaAndEntertainment?.whsremark,
          },
          oilAndGas: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.oilAndGas
                ?.whsremark,
          },
          power: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.power?.whsremark,
          },
          renewableEnergy: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.renewableEnergy
                ?.whsremark,
          },
          // retail: {
          //   value: '',
          //   whsremark:
          //     data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.retail?.whsremark,
          // },
          scienceAndTechnology: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks
                ?.scienceAndTechnology?.whsremark,
          },
          steel: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.steel?.whsremark,
          },
          telecommunication: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.telecommunication
                ?.whsremark,
          },
          textTiles: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.textTiles
                ?.whsremark,
          },
          tourismAndHospitality: {
            value: '',
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks
                ?.tourismAndHospitality?.whsremark,
          },
          prominentCustomersInTheWarehouse: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks
                ?.prominentCustomersInTheWarehouse?.value,
            whsremark:
              data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks
                ?.prominentCustomersInTheWarehouse?.whsremark,
          },
        });
        setMaterialForm({
          dryMaterial:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.dryMaterial
              ?.value === true
              ? true
              : false,
          wetMaterial:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.wetMaterial
              ?.value === true
              ? true
              : false,
          agriAndAlliedIndustries:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks
              ?.agriAndAlliedIndustries?.value === true
              ? true
              : false,
          autoMobiles:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.autoMobiles
              ?.value === true
              ? true
              : false,
          autoComponents:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.autoComponents
              ?.value === true
              ? true
              : false,
          aviation:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.aviation?.value ===
              true
              ? true
              : false,
          chemicalDry:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[0]
              ?.chemical?.value === true
              ? true
              : false,
          chemicalWet:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[1]
              ?.chemical?.value === true
              ? true
              : false,
          consumerDurables:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.consumerDurables
              ?.value === true
              ? true
              : false,
          ecommerce:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.ecommerce?.value ===
              true
              ? true
              : false,
          educationRelated:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.educationRelated
              ?.value === true
              ? true
              : false,
          engineeringGoods:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.engineeringGoods
              ?.value === true
              ? true
              : false,
          fmcg:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.fmcg?.value === true
              ? true
              : false,
          healthAndPharma:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.healthAndPharma
              ?.value === true
              ? true
              : false,
          itAndItes:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.itAndItes?.value ===
              true
              ? true
              : false,
          manufacturing:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.manufacturing
              ?.value === true
              ? true
              : false,
          mediaAndEntertainment:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.mediaAndEntertainment
              ?.value === true
              ? true
              : false,
          oilAndGas:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.oilAndGas?.value ===
              true
              ? true
              : false,
          power:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.power?.value === true
              ? true
              : false,
          renewableEnergy:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.renewableEnergy
              ?.value === true
              ? true
              : false,
          // retail: data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.dryMaterial?.value!==""?true:false,
          scienceAndTechnology:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.scienceAndTechnology
              ?.value === true
              ? true
              : false,
          steel:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.steel?.value === true
              ? true
              : false,
          telecommunication:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.telecommunication
              ?.value === true
              ? true
              : false,
          textTiles:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.textTiles?.value ===
              true
              ? true
              : false,
          tourismAndHospitality:
            data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.tourismAndHospitality
              ?.value === true
              ? true
              : false,
          // prominentCustomersInTheWarehouse: '',
        });
      }
    }
  }, [data?.singleFormData, data?.id]);

  return (
    <>
      {/* Loader */}

      {data?.isLoading ? (
        <CardLoader />
      ) : data?.isError !== '' ? (
        <ErrorCard message={data.isError} />
      ) : (
        <>
          {data?.addNewResponse?.statusCode === 200 ? (
            <FormSuccess
              onClick={() => dispatch(changeWarehouseStatus())}
              message={data.addNewResponse.message}
            />
          ) : null}
          <Formik
            enableReinitialize={true}
            initialValues={remarkForm}
            // validate={validate(validationSchema)}
            onSubmit={(fields) => onSubmit(fields)}
            render={({ errors, status, onChange, touched, values, setFieldValue }) => {
              return (
                <Form className='w-100'>
                  <div className='row bg-whitAndIte rounded mx-0 col-xxxl-11'>
                    <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                      <table className='table customTable'>
                        <tbody>
                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Dry Material:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  name='dryMaterial.value'
                                  onChange={(e) => {
                                    handleChange('dryMaterial', e);
                                  }}
                                  checked={dryMaterial}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  id='dryMaterial'
                                  value={true}
                                // disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='dryMaterial'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('dryMaterial', e)}
                                  name='dryMaterial.value'
                                  checked={!dryMaterial}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  id='dryMaterial1'
                                  value={false}
                                // disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='dryMaterial1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='dryMaterial.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Wet Material:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('wetMaterial', e)}
                                  checked={wetMaterial}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='wetMaterial.value'
                                  id='wetMaterial'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='wetMaterial'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('wetMaterial', e)}
                                  checked={!wetMaterial}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='wetMaterial.value'
                                  id='wetMaterial1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='wetMaterial1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='wetMaterial.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Agri & Allied Industries:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('agriAndAlliedIndustries', e)}
                                  checked={agriAndAlliedIndustries}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='agriAndAlliedIndustries.value'
                                  id='alliedIndustries'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='alliedIndustries'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('agriAndAlliedIndustries', e)}
                                  checked={!agriAndAlliedIndustries}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='agriAndAlliedIndustries.value'
                                  id='alliedIndustries1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='alliedIndustries1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='agriAndAlliedIndustries.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>AutoMobiles:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('autoMobiles', e)}
                                  checked={autoMobiles}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='autoMobiles.value'
                                  id='autoMobiles'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='autoMobiles'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('autoMobiles', e)}
                                  checked={!autoMobiles}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='autoMobiles.value'
                                  id='autoMobiles1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='autoMobiles1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='autoMobiles.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Auto Components:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('autoComponents', e)}
                                  checked={autoComponents}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='autoComponents.value'
                                  id='autoComponents'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='autoComponents'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('autoComponents', e)}
                                  checked={!autoComponents}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='autoComponents.value'
                                  id='autoComponents1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='autoComponents1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='autoComponents.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Aviation::</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('aviation', e)}
                                  checked={aviation}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='aviation.value'
                                  id='aviation'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='aviation'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('aviation', e)}
                                  checked={!aviation}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='aviation.value'
                                  id='aviation1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='aviation1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='aviation.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Chemicals (Dry):</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChangeAry('chemicalDry', 0, e)}
                                  checked={chemicalDry}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='chemicals[0].chemical.value'
                                  id='chemicalDry'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='chemicalDry'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChangeAry('chemicalDry', 0, e)}
                                  checked={!chemicalDry}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='chemicals[0].chemical.value'
                                  id='chemicalDry1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='chemicalDry1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                // chemicals[0].chemical.whsremark
                                name='chemicals[0].chemical.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Chemicals (Wet):</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChangeAry('chemicalWet', 1, e)}
                                  checked={chemicalWet}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='chemicals[1].chemical.value'
                                  id='chemicalWet'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='chemicalWet'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChangeAry('chemicalWet', 1, e)}
                                  checked={!chemicalWet}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='chemicals[1].chemical.value'
                                  id='chemicalWet1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='chemicalWet1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='chemicals[1].chemical.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Consumer Durables:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('consumerDurables', e)}
                                  checked={consumerDurables}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='consumerDurables.value'
                                  id='consumerDurables'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='consumerDurables'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('consumerDurables', e)}
                                  checked={!consumerDurables}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='consumerDurables.value'
                                  id='consumerDurables1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='consumerDurables1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='consumerDurables.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Ecommerce:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('ecommerce', e)}
                                  checked={ecommerce}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='ecommerce.value'
                                  id='ecommerce'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='ecommerce'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('ecommerce', e)}
                                  checked={!ecommerce}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='ecommerce.value'
                                  id='ecommerce1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='ecommerce1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='ecommerce.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Education Related:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('educationRelated', e)}
                                  checked={educationRelated}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='educationRelated'
                                  id='educationRelated'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='educationRelated'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('educationRelated', e)}
                                  checked={!educationRelated}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='educationRelated.value'
                                  id='educationRelated1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='educationRelated1'
                                >
                                  No
                                </label>
                              </div>
                            </td>

                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='educationRelated.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Engineering Goods:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('engineeringGoods', e)}
                                  checked={engineeringGoods}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='engineeringGoods.value'
                                  id='engineeringGood'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='engineeringGood'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('engineeringGoods', e)}
                                  checked={!engineeringGoods}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='engineeringGoods.value'
                                  id='engineeringGood1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='engineeringGood1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='engineeringGoods.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>FMCG:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('fmcg', e)}
                                  checked={fmcg}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='fmcg.value'
                                  id='fmcg'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label className='common-radio-deep-blue-label pl-4' htmlFor='fmcg'>
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('fmcg', e)}
                                  checked={!fmcg}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='fmcg.value'
                                  id='fmcg1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='fmcg1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='fmcg.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Health & Pharma:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('healthAndPharma', e)}
                                  checked={healthAndPharma}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='healthAndPharma.value'
                                  id='healthAndPharma'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='healthAndPharma'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('healthAndPharma', e)}
                                  checked={!healthAndPharma}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='healthAndPharma.value'
                                  id='healthAndPharma1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='healthAndPharma1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='healthAndPharma.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>IT & ITES:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('itAndItes', e)}
                                  checked={itAndItes}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='itAndItes.value'
                                  id='itAndItes'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='itAndItes'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('itAndItes', e)}
                                  checked={!itAndItes}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='itAndItes.value'
                                  id='itAndItes1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='itAndItes1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='itAndItes.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>
                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Manufacturing:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('manufacturing', e)}
                                  checked={manufacturing}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='manufacturing.value'
                                  id='manufacturing'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='manufacturing'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('manufacturing', e)}
                                  checked={!manufacturing}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='manufacturing.value'
                                  id='manufacturing1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='manufacturing1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='manufacturing.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Media & Entertainment:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('mediaAndEntertainment', e)}
                                  checked={mediaAndEntertainment}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='mediaAndEntertainment.value'
                                  id='entertainment'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='entertainment'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('mediaAndEntertainment', e)}
                                  checked={!mediaAndEntertainment}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='mediaAndEntertainment.value'
                                  id='entertainment1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='entertainment1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='mediaAndEntertainment.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>
                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Oil & Gas:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('oilAndGas', e)}
                                  checked={oilAndGas}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='oilAndGas.value'
                                  id='oilAndGas'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='oilAndGas'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('oilAndGas', e)}
                                  checked={!oilAndGas}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='oilAndGas.value'
                                  id='oilAndGas1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='oilAndGas1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='oilAndGas.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Power:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('power', e)}
                                  checked={power}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='power.value'
                                  id='power'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='power'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('power', e)}
                                  checked={!power}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='power.value'
                                  id='power1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='power1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='power.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>
                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Renewable Energy:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('renewableEnergy', e)}
                                  checked={renewableEnergy}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='renewableEnergy.value'
                                  id='energy'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='energy'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('renewableEnergy', e)}
                                  checked={!renewableEnergy}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='renewableEnergy.value'
                                  id='energy1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='energy1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='renewableEnergy.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          {/*<tr>
                    <td className="w-200px">
                      <p className="mb-0 pb-1">Retail:</p>
                    </td>
                   <td className="col-auto form-inline py-4 mb-1">
                      <div className="form-check common-radio-deep-blue mx-3">
                        <input onChange={(e)=>handleChange(e)} checked={retail} className="common-radio-deep-blue-input" type="radio" name="retail" id="retail" value={true} hidden="" disabled={viewMood} />
                        <label className="common-radio-deep-blue-label pl-4" htmlFor="retail">Yes</label>
                      </div>
                      <div className="form-check common-radio-deep-blue mx-3">
                        <input onChange={(e)=>handleChange(e)} checked={!retail} className="common-radio-deep-blue-input" type="radio" name="retail" id="retail1" value={false} hidden="" disabled={viewMood} />
                        <label className="common-radio-deep-blue-label pl-4" htmlFor="retail1">No</label>
                      </div> 
                    </td>
                  </tr>*/}
                          {/*<tr>
                    <td className="w-25px"> 	
                    </td> 	
               	
                    <td className="mw-250px form-inline form-group py-3 mb-0 pr-0" colSpan="2">	
                      <p>WHS Remarks: 
                       {data?.singleFormData&&data?.singleFormData?.materialTypeRemark&&data?.singleFormData?.materialTypeRemark?.retail?.whsstatus===true?"   okay":"   not okay"}
                       </p>	
                    </td>	
                    <td className="mw-150px">	
                      <input     disabled            
                         value={data?.singleFormData&&data?.singleFormData?.materialTypeRemark&&data?.singleFormData?.materialTypeRemark?.retail?.whsremark}	type="text" className="form-control  px-4 mb-2 mr-4" placeholder="Remarks" />	
                    </td>	
                  </tr>*/}
                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Science & Technology:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('scienceAndTechnology', e)}
                                  checked={scienceAndTechnology}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='scienceAndTechnology.value'
                                  id='scienceAndTechnology'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='scienceAndTechnology'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('scienceAndTechnology', e)}
                                  checked={!scienceAndTechnology}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='scienceAndTechnology.value'
                                  id='scienceAndTechnology1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='scienceAndTechnology1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='scienceAndTechnology.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Steel:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('steel', e)}
                                  checked={steel}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='steel.value'
                                  id='steel'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='steel'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('steel', e)}
                                  checked={!steel}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='steel.value'
                                  id='steel1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='steel1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='steel.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Telecommunication:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('telecommunication', e)}
                                  checked={telecommunication}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='telecommunication.value'
                                  id='telecommunication'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='telecommunication'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('telecommunication', e)}
                                  checked={!telecommunication}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='telecommunication.value'
                                  id='telecommunication1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='telecommunication1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='telecommunication.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>TexTiles:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('textTiles', e)}
                                  checked={textTiles}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='textTiles.value'
                                  id='texTiles'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='texTiles'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('textTiles', e)}
                                  checked={!textTiles}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='textTiles.value'
                                  id='texTiles1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='texTiles1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='textTiles.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Tourism & Hospitality:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('tourismAndHospitality', e)}
                                  checked={tourismAndHospitality}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='tourismAndHospitality.value'
                                  id='tourism'
                                  value={true}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='tourism'
                                >
                                  Yes
                                </label>
                              </div>
                              <div className='form-check common-radio-deep-blue mx-3'>
                                <Field
                                  onChange={(e) => handleChange('tourismAndHospitality', e)}
                                  checked={!tourismAndHospitality}
                                  className='common-radio-deep-blue-input'
                                  type='radio'
                                  name='tourismAndHospitality.value'
                                  id='tourism1'
                                  value={false}
                                  hidden=''
                                  disabled={viewMood}
                                />
                                <label
                                  className='common-radio-deep-blue-label pl-4'
                                  htmlFor='tourism1'
                                >
                                  No
                                </label>
                              </div>
                            </td>
                            <td className='text-left px-4 border' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='tourismAndHospitality.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>

                          <tr className='border'>
                            <td className='w-200px'>
                              <p className='mb-0 pb-1'>Prominent Customers in the Warehouse:</p>
                            </td>
                            <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                              {/* <input onChange={(e)=>handleChange(e)} checked={!prominentCustomersInTheWarehouse} className="common-radio-deep-blue-input" type="radio" name="prominentCustomersInTheWarehouse" id="prominentCustomersInTheWarehouse1" value={false} hidden="" disabled={viewMood} />
                              <label className="common-radio-deep-blue-label pl-4" htmlFor="prominentCustomersInTheWarehouse1">No</label> */}
                              <Field
                                onChange={(e) => {
                                  setFieldValue(
                                    `prominentCustomersInTheWarehouse.value`,
                                    e.target.value,
                                  );
                                  // }
                                  handleChange1('prominentCustomersInTheWarehouse', e);
                                }}
                                name={`prominentCustomersInTheWarehouse.value`}
                                // value={prominentCustomersInTheWarehouse.value}
                                type='text'
                                className='form-control'
                                readOnly={viewMood}
                              />
                            </td>
                            <td className='text-left px-4 border-0' colSpan='2'>
                              {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                              <label htmlFor='staticEmail' className='mb-2 px-2'>
                                Whs Remarks
                              </label>
                              <Field
                                disabled
                                name='prominentCustomersInTheWarehouse.whsremark'
                                type='text'
                                className='form-control  px-4 mb-2 mr-4'
                                placeholder='Remarks'
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={`col-12 mt-4 ${viewMood ? 'd-none' : ''}`}>
                      <div className='row justify-content-end'>
                        {data?.isError !== '' ? <FormErrorCard message={data?.isError} /> : null}

                        {data.isErrorMsg !== '' ? (
                          <FormErrorCard message={data?.isErrorMsg} />
                        ) : null}
                        <div className='col-auto'>
                          <button
                            type='submit'
                            disabled={data?.isPending}
                            className='btn btn-deep-blue add-className remove-class'
                          >
                            Save
                            {data?.isPending ? <Spinner animation='border' /> : null}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
          {/* )} */}
        </>
      )}
    </>
  );
};

export default MaterialTypeForm;

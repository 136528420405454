import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdditionalRequirementsForm from './AdditionalRequirementsForm';
import KPIForm from './KPIForm';
import OtherServiceRequirementForm from './OtherServiceRequirementForm';
// import ValueAddedServiceForm from "./ValueAddedServiceForm";
import StorageTypeForm from './StorageTypeForm';
import InventoryManagementForm from './InventoryManagementForm';
// import ReturnForm from "./ReturnForm";
// import ManPowerForm from "./ManPowerForm";
import OutBoundForm from './OutBoundForm';
import InBoundForm from './InBoundForm';
import GeneralForm from './GeneralForm';
import ContactInformationForm from './ContactInformationForm';
import RFQForm from './RFQForm';
import {
  finalRfq,
  responseRfq,
  initialRfqByIdAndType,
} from '../../../store/actions/customer/rfqAction';
import { useSelector, useDispatch } from 'react-redux';
import { FormSuccess } from '../../../components/helper/FormSuccess';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router';
import ManageRfqNewForm from '../../../pages/dashboard/rfq/ManageRfqNewForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '5px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    padding: '15px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const CreateRFQ = () => {
  const history = useHistory();

  const data = useSelector((state) => state.CUSTOMER_RFQ_INFO);
  // console.log("new",data)
  const dispatch = useDispatch();

  const rfqid = new URLSearchParams(window.location.search).get('rfqid');
  useEffect(() => {
    console.log(' >>>> Action trigger Act <<<< ');
  }, []);
  // console.log("params",rfqid)
  // console.log("kk",window.location.search)
  // useEffect(() => {
  //   if (data.rfqInitialDetail && data.rfqInitialDetail.length !== 0 && data.rfqInitialDetail.warehouseSpaceRequired !== null) {

  //     setState({
  //       "warehouseSpaceRequired": data.rfqInitialDetail.warehouseSpaceRequired,
  //       "customerRfqFormFilled": true
  //     })
  //     setRfqId(data)
  //   }

  // }, [data]);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [redirectSuccess, setRedirectSuccess] = React.useState(false);

  const handleChange = (panel, type) => (event, isExpanded) => {
    setErrorMsg('');
    if (rfqid) {
      console.log('trigger abcd ');
      dispatch(initialRfqByIdAndType(parseInt(rfqid), type, data?.rfqFirstForm?.warehouses));
    }
    setExpanded(isExpanded ? panel : false);
  };

  // Status Change confirmation
  const statusChange = () => {
    console.log(data, '|||&&&&&&&&||||');
    setErrorMsg('');
    setExpanded(false);
    console.log(data?.rfqFirstForm, '&&&Section Collection Act&&&');

    if (data?.rfqFirstForm?.rfqcontactinfo === false) {
      setExpanded('panel2');
      setErrorMsg('Contact Information Required');
      return false;
    }

    if (data?.rfqFirstForm?.rfqgeneral === false) {
      setExpanded('panel3');
      setErrorMsg('General Required');
      return false;
    }

    if (data?.rfqFirstForm?.rfqinbound === false) {
      setExpanded('panel4');
      setErrorMsg('Inbound Required');
      return false;
    }

    if (data?.rfqFirstForm?.rfqoutbound === false) {
      setExpanded('panel5');
      setErrorMsg('Outbound Required');
      return false;
    }

    if (data?.rfqFirstForm?.rfqinventoryManagement === false) {
      setExpanded('panel8');
      setErrorMsg('Inventory Managements Required');
      return false;
    }

    if (data?.rfqFirstForm?.rfqstoragetype === false) {
      setExpanded('panel9');
      setErrorMsg('Storage Type Required');
      return false;
    }

    if (data?.rfqFirstForm?.rfqotherservicerequirement === false) {
      setExpanded('panel11');
      setErrorMsg('Other Service Requirement');
      return false;
    }

    if (data?.rfqFirstForm?.rfqkpi === false) {
      setExpanded('panel12');
      setErrorMsg('KPI');
      return false;
    }

    if (data?.rfqFirstForm?.rfqadditionalrequirement === false) {
      setExpanded('panel12');
      setErrorMsg('Additional Requirements');
      return false;
    }

    // setExpanded('panel8');
    // console.log("Add section trigger abcd");
    // return false
    confirmAlert({
      // title: 'Confirm to submit',
      message: 'Are you sure to do final submit.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(
              finalRfq(
                {
                  warehouseSpaceRequired: 0,
                  customerRfqFormFilled: false,
                },
                rfqid,
              ),
            );
          },
        },
        {
          label: 'No',
          onClick: () => console.log('--No--'),
        },
      ],
    });

    // redir()
  };

  const redirect = () => {
    // console.log("Redirect");
    dispatch({ payload: {}, type: 'INITIAL_EMPTY' });
    dispatch(responseRfq([]));
    history.push('/managerfq?page=1');
  };

  const redir = () => {
    dispatch(responseRfq([]));
    console.log('Action abcd trigger123**');
    history.push('/managerfq?page=1');
  };

  useEffect(() => {
    if (rfqid) {
      // console.log("times")
      dispatch(initialRfqByIdAndType(rfqid, 'customerRfq', data?.rfqFirstForm?.warehouses));
    }
  }, [dispatch]);
  console.log(data.rfqResponse?.redirect, 'redirect section trigger acbd');

  return (
    <div>
      {data.rfqResponse?.statusCode === 200 && data.rfqResponse?.redirect === false ? (
        <FormSuccess
          onClick={() => dispatch(responseRfq([]))}
          message={data.rfqResponse?.message}
        />
      ) : null}

      {data?.rfqResponse?.statusCode === 200 && data?.rfqResponse?.redirect === true ? (
        <FormSuccess onClick={() => redir()} message={data.rfqResponse?.message} />
      ) : null}

      {data.rfqResponse?.statusCode === 308 ? (
        <FormSuccess onClick={redirect} message={data.rfqResponse?.message} />
      ) : null}

      {/* Create New Warehouse */}

      <ManageRfqNewForm />
      {/* Contact information */}

      {/* Contact information */}

      {/* Contact information */}

      {/* OutBount */}

      {/* Man Power */}
      {/* <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6', 'manPowers')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>  <span className="accord-heading">5. Man Power</span></Typography>
        </AccordionSummary>
        <AccordionDetails>

          <ManPowerForm rfqid={rfqid} />

        </AccordionDetails>
      </Accordion> */}

      {/* Return */}
      {/* <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7", "returnRfq")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            {" "}
            <span className="accord-heading">6. Return</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReturnForm rfqid={rfqid} />
        </AccordionDetails>
      </Accordion> */}

      {/* Inventory Management */}

      {/* Storage Type */}

      {/* Value Added Service */}
      {/* <Accordion
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10", "valueAddedServices")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            {" "}
            <span className="accord-heading">9. Value Added Service</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ValueAddedServiceForm rfqid={rfqid} />
        </AccordionDetails>
      </Accordion> */}

      {/* Other Service Requirement */}

      {/* Other Service Requirement */}

      {/* Additional Requirements */}

      <div className='errorSection' style={{ color: 'red' }}>
        {errorMsg ? errorMsg : ''}
      </div>
    </div>
  );
};

export default CreateRFQ;

import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout';
import { useLocation } from 'react-router-dom';
import axiosauth from '../../api/axios-auth';
import './FrequentlyAskedQuestions.css';
import FrequentlyHeader from './FrequentlyHeader';
import Frequentlyfaq from './Frequentlyfaq';

const FrequentlyAskedQuestions = () => {
  const [faq, setfaq] = useState([]);
  const [index, setindex] = useState(null);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem('userData') !== null) {
      if (JSON.parse(localStorage.getItem('userData'))?.account.accountType === 'customer') {
        axiosauth
          .get('/api/v1/cms/cmstype/testcust')
          .then((response) => {
            let res = JSON.parse(response.data);
            // console.log("")
            if (res.statusCode === 200) {
              setfaq(JSON.parse(res.data.data));
            } else {
              console.log('review_By_Id Fail');
            }
          })
          .catch((error) => {})
          .then(() => {
            console.log('-----always executes');
          });
      }
      if (JSON.parse(localStorage.getItem('userData'))?.account.accountType === 'vendor') {
        axiosauth
          .get('/api/v1/cms/cmstype/test')
          .then((response) => {
            let res = JSON.parse(response.data);
            // console.log("")
            if (res.statusCode === 200) {
              setfaq(JSON.parse(res.data.data));
            } else {
              console.log('review_By_Id Fail');
            }
          })
          .catch((error) => {})
          .then(() => {
            console.log('-----always executes');
          });
      }
    }
  }, [pathname]);

  const [faqs, setFaqs] = useState([
    {
      question: 'How many programmers does it take to screw a lightbulb?',
      answer:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.',
      open: true,
    },
    {
      question: 'Who is the most awesome person?',
      answer: 'You! The viewer!',
      open: false,
    },
    {
      question: 'How many questions does it take to makes a succesful FAQ Page?',
      answer: 'This many!',
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      }),
    );
  };

  return (
    <Layout>
      <div className='container mt-5'>
        <nav className='' aria-label='breadcrumb'>
          <ol className='breadcrumb h5 common-breadcrumb text-gray mb-0'>
            {/* <li className="breadcrumb-item"><Link to="#">Home</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Warehouse List</li> */}
          </ol>
        </nav>
      </div>

      <section className='faq bg-light-green' style={{ minHeight: '50vh' }}>
        <div className='container'>
          <div className='row'>
            <div className='section-heading col-12 mb-4 pt-4'>
              <h2 className='mb-0 text-center'>Frequently Asked Questions</h2>
              <FrequentlyHeader />
              <div className='faqs'>
                {faqs.map((faq, index) => (
                  <Frequentlyfaq faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                ))}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='accordion custom-faq bg-none'>
                {faq && faq.length > 0
                  ? faq.map((value, i) => {
                      return (
                        <div className='card'>
                          <div
                            onClick={() => {
                              if (index === null) {
                                setindex(i);
                              } else {
                                setindex(null);
                              }
                            }}
                            className='card-header'
                          >
                            <h2 className='mb-0'>
                              <button className='btn text-left btn-block' type='button'>
                                <p className='ml-4'> {value.name} </p>
                              </button>
                            </h2>
                          </div>
                          {console.log('sdjksdjk', index, i)}
                          {i === index ? (
                            <div>
                              <div className='card-body'>
                                {value.value && value.value.length > 0
                                  ? value.value.map((val, index) => {
                                      return (
                                        <div>
                                          <p className='ml-4'>
                                            <b>{val.name}</b>
                                          </p>
                                          <p className='ml-4'>{val.value}</p>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FrequentlyAskedQuestions;

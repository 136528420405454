import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { useHistory } from 'react-router-dom';
import memoize from 'memoize-one';
import VendorLayout from '../../layout/VendorLayout';
import MenuDrawer from '../../components/vendor/MenuDrawer';
import { getVendorDemograpgy } from '../../store/actions/dashboardAction'
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';

const paginationOptions = {
  rowsPerPageText: "Rows per page:",
  rangeSeparatorText: "of",
  noRowsPerPage: false,
  selectAllRowsItem: false,
  selectAllRowsItemText: "All"
};
const DemographySummary = () => {
  const [thing, setThing] = useState();
  const history = useHistory();
  // const handleAction = value => setThing(value);
  // const [location, setLocation] = useState("Uttar Pradesh")
  const dispatch = useDispatch();
  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");
  const [category, setCategory] = React.useState("");
  const dashboardData = useSelector((state) => state.DASHBOARD_INFO);
  const tableCustomStyles = {
    headRow: {
      style: {
        color: '#223336',
        backgroundColor: '#e7eef0'
      },
    },
    rows: {
      style: {
        color: "STRIPEDCOLOR",
        backgroundColor: "STRIPEDCOLOR"
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "NORMALCOLOR"
      }
    }
  }


  const demographyCall = (state) => {
    if (state !== "") {
      // setLocation(state)
      dispatch(getVendorDemograpgy({
        "state": state
      }))
    }
  }

  const options = [
    // { value: '0', label: 'Select State' },

    { value: '1', label: 'Andaman & Nicobar' },
    { value: '2', label: 'Andhra Pradesh' },
    { value: '3', label: 'Arunachal Pradesh' },
    { value: '4', label: 'Assam' },
    { value: '5', label: 'Bihar' },
    { value: '6', label: 'Chandigarh' },
    { value: '7', label: 'Chhattisgarh' },
    { value: '8', label: 'Dadra & Nagar Haveli' },
    { value: '9', label: 'Daman & Diu' },
    { value: '10', label: 'Delhi' },
    { value: '11', label: 'Goa' },
    { value: '12', label: 'Gujarat' },
    { value: '13', label: 'Haryana' },
    { value: '14', label: 'Himachal Pradesh' },
    { value: '15', label: 'Jammu & Kashmir' },
    { value: '16', label: 'Jharkhand' },
    { value: '17', label: 'Karnataka' },
    { value: '18', label: 'Kerala' },
    { value: '19', label: 'Lakshadweep' },
    { value: '20', label: 'Madhya Pradesh' },
    { value: '21', label: 'Maharashtra' },
    { value: '22', label: 'Manipur' },
    { value: '23', label: 'Meghalaya' },
    { value: '24', label: 'Mizoram' },
    { value: '25', label: 'Nagaland' },
    { value: '26', label: 'Orissa' },
    { value: '27', label: 'Pondicherry' },
    { value: '28', label: 'Punjab' },
    { value: '29', label: 'Rajasthan' },
    { value: '30', label: 'Sikkim' },
    { value: '31', label: 'Tamil Nadu' },
    { value: '32', label: 'Tripura' },
    { value: '33', label: 'Uttar Pradesh' },
    { value: '34', label: 'Uttaranchal' },
    { value: '35', label: 'West Bengal' },
  ];
  const tableDataRecord = (dashboardData.vendorDemography !== null && dashboardData.vendorDemography !== undefined ? dashboardData?.vendorDemography?.warehouseList : [])

  const filteredData = tableDataRecord.filter(
    (item) => item.warehouse.toLowerCase().includes(searchText.toLowerCase()) &&
      (category ? item.department === category : true)
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  const columns = memoize(handleAction => [
    { name: "Warehouse Name", selector: "warehouse", sortable: true },
    { name: "Total Space", selector: "totalspace", sortable: true },
    { name: "Available Space", selector: "availableSpace", sortable: true },
    {
      name: "Action",
      cell: (event) => <Button raised primary onClick={() => handleAction(event)}> View</Button>,
      selector: "view",
      sortable: true,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]);

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];

    for (let i = 1; i <= page; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`btn-pagination ${currentPage === i ? "active" : ""}`}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination-container">
        <button
          className="btn-pagination"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          ‹
        </button>
        {pageButtons}
        <button
          className="btn-pagination"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          ›
        </button>
      </div>
    );
  };

  const updateState = (state) => {
    // console.log(state.view, "State");
    history.replace(`/wh-detail/${state.view}`);
  }

  return (
    <VendorLayout>
      <div className="content-admin px-1">
        <MenuDrawer />
        <div className="row justify-content-end align-items-center py-3 px-4 mx-0">
          <div className='col-12 custom-shadow p-1 mb-1  tabs-scroll'>
            <ul className='nav nav-pills admin-tabs-blue' id='pills-tab' role='tablist'>
              <li className='nav-item'>
                <Link to='/vendor' className='nav-link  text-uppercase'>
                  Overview
                </Link>
              </li>
              <li className='nav-item vendorActive'>
                <Link to='/vendor/warehouse' className='nav-link text-uppercase'>
                  Warehouse
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/vendor/wms' className='nav-link text-uppercase'>
                  WMS
                </Link>
              </li>
            </ul>
          </div>


          <div className="col-12">
            <div className="tab-content summaryGraph" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-demographics-summary" role="tabpanel" aria-labelledby="pills-demographics-summary-tab">
                <div className="col-12 border px-0 card">
                  <div className="card-body">
                    <div className="row">
                      <DataTable
                        // columns={columns}
                        columns={columns(updateState)}
                        onSelectionChange={(e) => {
                          // console.log(e, "ON change actio n")
                        }}
                        data={paginatedData}
                        pagination
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        customStyles={tableCustomStyles}
                        highlightOnHover
                        pointerOnHover
                      // subHeader
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="pills-wms" role="tabpanel" aria-labelledby="pills-wms-tab">...</div>
            </div>
          </div>
        </div>
      </div>

    </VendorLayout>
  );
}

export default DemographySummary;

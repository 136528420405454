import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import ContactInformationForm from '../../../wrapper/customer/rfq/ContactInformationForm';
import GeneralForm from '../../../wrapper/customer/rfq/GeneralForm';
import InBoundForm from '../../../wrapper/customer/rfq/InBoundForm';
import OutBoundForm from '../../../wrapper/customer/rfq/OutBoundForm';
import ManPowerForm from '../../../wrapper/customer/rfq/ManPowerForm';
import ReturnForm from '../../../wrapper/customer/rfq/ReturnForm';
import InventoryManagementForm from '../../../wrapper/customer/rfq/InventoryManagementForm';
import StorageTypeForm from '../../../wrapper/customer/rfq/StorageTypeForm';
import ValueAddedServiceForm from '../../../wrapper/customer/rfq/ValueAddedServiceForm';
import OtherServiceRequirementForm from '../../../wrapper/customer/rfq/OtherServiceRequirementForm';
import { useForm, Controller, FormProvider, useFormContext } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { Typography, TextField, Button, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SpinnerLoader from './SpinnerLoader';
import { initialRfqByIdAndType } from '../../../store/actions/customer/rfqAction';

const steps = [
  'Contact info',
  'General',
  'Inbound',
  'Outbond',
  'Man Power',
  'Return',
  'Inventory',
  'Storage',
  'Value Added',
  'Other',
];

const stepsArray = steps.map((elem) => {
  return elem.toLowerCase().replace(' ', '-');
});

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
let flag = false;
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

const StepperSx = {
  '& .MuiStepConnector-root': {
    left: 'calc(-50% + 40px)',
    right: 'calc(50% + 40px)',
  },
  '& .MuiStepConnector-line': {
    marginTop: '22px',
  },
};

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <VideoLabelIcon />,
    5: <GroupAddIcon />,
    6: <VideoLabelIcon />,
    7: <GroupAddIcon />,
    8: <VideoLabelIcon />,
    9: <GroupAddIcon />,
    10: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function getStepContent(step, rfqId, slug, closeErrorMessage) {
  switch (slug) {
    case 'contact-info':
      return (
        <ContactInformationForm rfqId={rfqId} slug={slug} closeErrorMessage={closeErrorMessage} />
      );
    case 'general':
      return <GeneralForm rfqId={rfqId} slug={slug} closeErrorMessage={closeErrorMessage} />;
    case 'inbound':
      return <InBoundForm rfqId={rfqId} slug={slug} closeErrorMessage={closeErrorMessage} />;
    case 'outbond':
      return <OutBoundForm rfqId={rfqId} slug={slug} closeErrorMessage={closeErrorMessage} />;
    case 'man-power':
      return <ManPowerForm rfqId={rfqId} slug={slug} closeErrorMessage={closeErrorMessage} />;
    case 'return':
      return <ReturnForm rfqId={rfqId} slug={slug} closeErrorMessage={closeErrorMessage} />;
    case 'inventory':
      return (
        <InventoryManagementForm rfqId={rfqId} slug={slug} closeErrorMessage={closeErrorMessage} />
      );
    case 'storage':
      return <StorageTypeForm rfqId={rfqId} slug={slug} closeErrorMessage={closeErrorMessage} />;
    case 'value-added':
      return (
        <ValueAddedServiceForm rfqId={rfqId} slug={slug} closeErrorMessage={closeErrorMessage} />
      );
    case 'other':
      return (
        <OtherServiceRequirementForm
          rfqId={rfqId}
          slug={slug}
          closeErrorMessage={closeErrorMessage}
        />
      );
  }
}

export default function LinearStepper() {
  const { rfqId, slug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [stepError, setStepError] = useState('error message Display');

  const isStepFailed = (step) => {
    // return step === activeStep
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Clean up the timeout to avoid memory leaks
    // return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const index = stepsArray.findIndex((elem) => elem === slug);
    setActiveStep(index);

    if (rfqId !== undefined && slug !== undefined) {
      dispatch(initialRfqByIdAndType(rfqId, slug));
    }
  }, [slug]);

  const handleNext = () => {
    // alert(steps[activeStep+1])
    // event.preventDefault()

    if (activeStep <= steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
    history.push(`/conciserfq/${rfqId}/${stepsArray[activeStep + 1]}`);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleBack = () => {
    // alert(steps[activeStep-1])
    setActiveStep(activeStep - 1);
    history.push(`/conciserfq/${rfqId}/${stepsArray[activeStep - 1]}`);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const closeErrorMessage = () => {
    alert('hey i am closeErrorMessage Function');
  };
  return (
    <>
      <Stack sx={{ width: '100%', paddingBottom: '15px' }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          sx={StepperSx}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => {
            const labelProps = {};
            if (isStepFailed(index)) {
              labelProps.optional = (
                <Typography variant='caption' color='error'>
                  {stepError}
                </Typography>
              );

              labelProps.error = true;
            }

            return (
              <Step key={label}>
                <Typography align='center'>{label}</Typography>
                {Object.keys(labelProps).length > 0 ? (
                  <>
                    <StepLabel {...labelProps}></StepLabel>
                  </>
                ) : (
                  <>
                    <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}></StepLabel>
                  </>
                )}
              </Step>
            );
          })}
        </Stepper>
      </Stack>
      {activeStep === steps.length ? (
        <Typography variant='h3' align='center'>
          Thank You
        </Typography>
      ) : (
        <>
          <FormProvider>
            <form onSubmit={handleNext}>
              {/* {getStepContent(activeStep)}           */}
              {isLoading ? (
                <SpinnerLoader />
              ) : (
                getStepContent(activeStep, rfqId, slug, closeErrorMessage)
              )}

              <div className='nextPreviousform pb-0'>
                <span>
                  <MobileStepper
                    variant='dots'
                    steps={10}
                    position='static'
                    activeStep={activeStep}
                    sx={{ flexGrow: 1, width: '50%', margin: '0 auto' }}
                    nextButton={
                      <Button
                        size='small'
                        onClick={() => {
                          setIsLoading(true);
                          handleNext();
                        }}
                      >
                        Next
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                      </Button>
                    }
                    backButton={
                      <Button
                        size='small'
                        onClick={() => {
                          setIsLoading(true);
                          handleBack();
                        }}
                        disabled={activeStep === 0}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Back
                      </Button>
                    }
                  />
                </span>
              </div>
              {console.log('stepsArray')}
              {console.log(stepsArray)}
              {console.log(slug)}
              {console.log(activeStep)}
              {console.log('stepsArray')}
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
}

import React from 'react'
import {GoogleMapsAPI} from '../../../pages/warehouse/clientconfig'

const getLatLng = (props) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  const latlongReturn= fetch(`https://maps.google.com/maps/api/geocode/json?address=${props}&key=${GoogleMapsAPI}`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    return result.results[0].geometry.location
  })
  .catch((error) => {
    return 'error'
  });
  return latlongReturn;
}

export default getLatLng

import React, { useEffect, useState } from "react";
import Layout from "../../../../layout/Layout";
import CustomerLayout from "../../../../layout/CustomerLayout";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from '@mui/material/TablePagination';
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
// import Checkbox from '@mui/material/Checkbox';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";

import {
  customerBookingAuth,
  bookingList,
} from "../../../../store/actions/customer/bookingAction";
import { useSelector, useDispatch } from "react-redux";

function createData(
  name,
  warehouseid,
  location,
  whstatus,
  keyName,
  keyNumber,
  email,
  spcompany,
  contactPersion,
  contactNumber,
  providerEmail
) {
  return {
    name,
    warehouseid,
    location,
    whstatus,
    keyName,
    keyNumber,
    email,
    spcompany,
    contactPersion,
    contactNumber,
    providerEmail,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// const rows = [
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Donut', 452, 25.0, 51, 4.9),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
//     createData('Honeycomb', 408, 3.2, 87, 6.5),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Jelly Bean', 375, 0.0, 94, 0.0),
//     createData('KitKat', 518, 26.0, 65, 7.0),
//     createData('Lollipop', 392, 0.2, 98, 0.0),
//     createData('Marshmallow', 318, 0, 81, 2.0),
//     createData('Nougat', 360, 19.0, 9, 37.0),
//     createData('Oreo', 437, 18.0, 63, 4.0),
// ];

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "warehouseid",
    numeric: false,
    disablePadding: true,
    label: "WH ID",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "WH Location",
  },
  {
    id: "whstatus",
    numeric: true,
    disablePadding: false,
    label: "WH Status",
  },
  {
    id: "keyName",
    numeric: true,
    disablePadding: false,
    label: "Key Contact Name",
  },
  {
    id: "keyNumber",
    numeric: true,
    disablePadding: false,
    label: "Key Contact Number",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email Id",
  },
  {
    id: "spcompany",
    numeric: true,
    disablePadding: false,
    label: "Space Provider Company Name",
  },
  {
    id: "contactPersion",
    numeric: true,
    disablePadding: false,
    label: "Contact Person",
  },
  {
    id: "contactNumber",
    numeric: true,
    disablePadding: false,
    label: "Contact Number",
  },
  {
    id: "providerEmail",
    numeric: true,
    disablePadding: false,
    label: "Email Id",
  },
];

function EnhancedTableHead(props) {
  // eslint-disable-next-line
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
        </TableCell> */}
        <TableCell
            component="th"
            //  id={index}
             scope="row"
             padding="none"
        >
            Sr.No.
          </TableCell>
       
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="text-nowrap"
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Warehouse Contact
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const WarehouseStatsByTable = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.BOOKINGINFO);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    document.getElementsByTagName("footer")[0].classList.add("d-none");
  });

  useEffect(() => {
    let usr = JSON.parse(localStorage.getItem("userData"));
    if (data.bookingList && data.bookingList.length > 0) {
      let whList = [];
      data.bookingList.map((item) =>
        item.warehouse?.users.map((user) => {
          whList.push(
            createData(
              item.warehouse?.warehouseId,
              item.warehouse?.warehouseContactDetailInfo?.address?.district +
                " " +
                item.warehouse?.warehouseContactDetailInfo?.address?.state,
              "Active",
              usr.firstName + " " + usr.lastName,
              usr.phone,
              usr.email,
              user.companyName,
              user.firstName,
              user.phone,
              user.email,
              ""
            )
          );
          return "";
        })
      );
      setRows(whList);
    }
  }, [data]);

  // Booking Data Fetch

  useEffect(() => {
    dispatch(customerBookingAuth(parseInt(1)));

    return () => {
      dispatch(bookingList([]));
    };
  }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // eslint-disable-next-line
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Layout>
      <CustomerLayout title={`Dashboard`} subtitle={"My Operations"}>
        <div className="row">
          <div className="content col-12 shadow-sm pb-4">
            <div className="dashboard-btns pb-1 border-bottom mb-2">
              {/* <ul
                className="nav nav-pills common-tabs3 mb-1"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link btn-md btn py-2 ">
                    My Dashboard
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/customer-stats-by-table-contact"
                    className="nav-link btn-md btn py-2 active"
                  >
                    Contact
                  </Link>
                </li>
 
              </ul> */}
              <nav className="pt-3" aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent py-0">
                  <li class="breadcrumb-item ">
                    <Link to="/dashboard" className="text-dark">
                      My Dashboard
                    </Link>
                  </li>
                  <li
                    class="breadcrumb-item active text-warning"
                    aria-current="page"
                  >
                    Contact
                  </li>
                </ol>
              </nav>
            </div>

            <div className="tab-content" id="pills-tabContent">
              <div className="dashboard-cards row custom-table-tablecontainer">
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%", mb: 2 }}>
                    {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />
                        <TableBody>
                          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                                                     rows.slice().sort(getComparator(order, orderBy)) */}
                          {stableSort(rows, getComparator(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.name);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  onClick={(event) =>
                                    handleClick(event, row.name)
                                  }
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.name}
                                  selected={isItemSelected}
                                >
                               
                             
                                  <TableCell
                                     component="th"
                                    //  id={index}
                                     scope="row"
                                     padding="none"
                                  >
                                    {index+1}
                                  </TableCell>
                                  
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                  >
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.warehouseid}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.location}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.whstatus}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.keyName}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.keyNumber}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.email}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.spcompany}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.contactPersion}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.contactNumber}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.providerEmail}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow
                              style={{
                                height: (dense ? 33 : 53) * emptyRows,
                              }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        /> */}
                  </Paper>
                  {/* <FormControlLabel
                    control={
                      <Switch checked={dense} onChange={handleChangeDense} />
                    }
                    label="Dense padding"
                  /> */}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </CustomerLayout>
    </Layout>
  );
};

export default WarehouseStatsByTable;

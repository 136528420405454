import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncImage } from 'loadable-image';
import './main.css';

const ImageSlider = ({ setPopup, popup }) => {
  const items = useSelector((state) => state.WAREHOUSEINFO);
  const data = useSelector((state) => state);
  const [sliderShow, setSliderShow] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  // const [popup, setPopup] = useState(true);

  //   const { id, warehouseName, soldOut, type, category, warehouseImages } = item;

  // Favorite compare

  const warehouseImage =
    items?.warehouseDetailPage?.remarks === 1
      ? items?.warehouseDetailPage?.warehouseImages !== undefined
        ? [
            items?.warehouseDetailPage?.warehouseImages?.whsCoverImage?.coverImg?.value,
            ...items?.warehouseDetailPage?.warehouseImages?.whsIndoors?.indoor[0].value,
            ...items?.warehouseDetailPage?.warehouseImages?.whsOutdoors?.outdoor[0]?.value,
          ]
        : ''
      : items !== undefined &&
        items?.warehouseDetailPage !== undefined &&
        items?.warehouseDetailPage !== null &&
        items?.warehouseDetailPage?.warehouseImages !== undefined
      ? items?.warehouseDetailPage?.warehouseImages?.map((elem, index) => {
          return [elem?.cover, ...elem?.photo?.indoor, ...elem?.photo?.outdoor];
        })
      : [
          'https://img.freepik.com/premium-vector/window-operating-system-error-warning-dialog-window-popup-message-with-system-failure-flat-design_812892-54.jpg',
        ];

  const ImageList =
    items?.warehouseDetailPage?.remarks === 1 ? [...warehouseImage] : [...warehouseImage[0]];

  const Increment = () => {
    if (imageCount < ImageList.length - 1) {
      setImageCount(imageCount + 1);
    }
  };

  const Decrement = () => {
    if (imageCount > 0) {
      setImageCount(imageCount - 1);
    }
  };

  return (
    <div className='col-sm-12 position-absolute my-5' style={{ zIndex: 1, top: '50px' }}>
      <div
        className='card custom-warehouse-detail custom-shadow my-3 col-sm-8 left-0 right-0 top-0 bottom-0 pr-0 pl-0'
        onMouseEnter={() => setSliderShow(true)}
        onMouseLeave={() => setSliderShow(false)}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <div className='card card-overlay'>
          <div className='img-holder card-img px-4 py-4  mt-4'>
            {sliderShow
              ? imageCount !== 0 && (
                  <li
                    class='position-absolute'
                    style={{ top: '231px', left: '50px', zIndex: '999' }}
                    onClick={Decrement}
                  >
                    <img
                      src='https://cdn0.iconfinder.com/data/icons/flat-round-arrow-arrow-head/512/Red_Arrow_Head_Left-2-256.png'
                      width={'40px'}
                      className='cursors'
                    />
                  </li>
                )
              : ''}

            <img
              style={{ height: '400px', objectFit: 'cover', width: '943px' }}
              src={ImageList[imageCount]}
              alt=''
              className=''
            />

            {sliderShow
              ? imageCount !== ImageList.length - 1 && (
                  <li
                    class='position-absolute'
                    style={{ bottom: '210px', right: '50px' }}
                    onClick={Increment}
                  >
                    <img
                      src='https://cdn0.iconfinder.com/data/icons/flat-round-arrow-arrow-head/512/Red_Arrow_Head_Right-2-256.png'
                      width={'40px'}
                      className='cursors'
                    />
                  </li>
                )
              : ''}
            <img
              src='https://cdn-icons-png.flaticon.com/512/12373/12373420.png'
              class='position-absolute h3 text-secondary cursors'
              style={{ top: '0px', right: '5px', width: '40px' }}
              onClick={() => setPopup(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;

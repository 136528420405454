import React, { useEffect } from 'react';
import VendorLayout from '../../layout/VendorLayout';
import MenuDrawer from '../../components/vendor/MenuDrawer';
import WarehouseList from '../../wrapper/vendor/vendorWarehouse/WarehouseListRejected';
import { fetchWarehouseByPageRejectedNew } from '../../store/actions/vendor/warehouseList';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoader from '../../components/helper/CustomLoader';
import Pagination from 'react-js-pagination';
import { useHistory } from 'react-router-dom';
import ErrorCard from '../../components/helper/ErrorCard';

const MyWarehouse = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSELIST);
  const pageCount = new URLSearchParams(window.location.search).get('page');

  useEffect(() => {
    dispatch(fetchWarehouseByPageRejectedNew(parseInt(pageCount)));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());
    // dispatch(fetchWarehouseByPageRejected(pageNumber));
  };

  return (
    <VendorLayout>
      <div className='content-admin px-3 mt-2'>
        <div className='row justify-content-end align-items-center px-3 mx-0'>
          <MenuDrawer />
          <h5 className='backButton text-dark px-0'>
            <i
              onClick={() => history.goBack()}
              className='fas fa-chevron-left mr-3 cursorPointer'
            ></i>
            Rejected By WHS
          </h5>

          <div className='col-12 px-0'>
            <div className='row'>
              <div className='col-12 table-responsive  table-gray-admin'>
                {data.isLoading ? (
                  <CustomLoader />
                ) : data.isError !== '' ? (
                  <ErrorCard message={data.isError} />
                ) : (
                  <>
                    <div className='bg-white p-3'>
                      <table className='table'>
                        <thead className=''>
                          <tr>
                            <th className='text-nowrap  bg-dark text-white px-2'> S.No. </th>{' '}
                            <th className='text-nowrap bg-dark text-white px-2'>
                              {' '}
                              Warehouse Name{' '}
                            </th>{' '}
                            <th className='text-nowrap bg-dark text-white px-2'>
                              {' '}
                              Warehouse Category Name
                            </th>{' '}
                            <th className='bg-dark text-white px-2'> Warehouse Type Name</th>{' '}
                            <th className='bg-dark text-white px-2'> Action </th>{' '}
                          </tr>
                        </thead>

                        <tbody>
                          {data?.listOfWarehouse?.data?.warehouses &&
                            data?.listOfWarehouse?.data?.warehouses.length > 0 &&
                            data?.listOfWarehouse?.data?.warehouses.map((item, index) => {
                              return (
                                <WarehouseList
                                  data={item}
                                  key={index}
                                  index={(pageCount - 1) * 10 + (index + 1)}
                                />
                              );
                            })}

                        </tbody>
                      </table>
                      {data?.listOfWarehouse?.data?.warehouses !== undefined && data?.listOfWarehouse?.data?.warehouses !== null && data?.listOfWarehouse?.data?.warehouses.length === 0 ? (<div className="card-body text-center font-weight-bold"><span className="ml-1"> NO RECORD FOUND </span> </div>) : ("")}
                    </div>
                    {/* ============ Pagination ============ */}
                    {data.listOfWarehouse.data &&
                      data.listOfWarehouse.data.totalCount !== undefined && (
                        <div className='pagination-custom'>
                          <Pagination
                            activePage={parseInt(pageCount)}
                            itemsCountPerPage={10}
                            totalItemsCount={data.listOfWarehouse.data.totalCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            prevPageText={<i className='fas fa-chevron-left' />}
                            nextPageText={<i className='fas fa-chevron-right' />}
                            hideFirstLastPages={true}
                          />
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </VendorLayout >
  );
};

export default MyWarehouse;

import React, { useEffect, useState } from 'react';
import Layout from '../../../layout/Layout';
import RFQManageList from '../../../wrapper/customer/rfq/RFQManageList';
import CustomerLayout from '../../../layout/CustomerLayout';
// import SearchBox from '../../../components/helper/SearchBox';
import BrowserTitle from '../../../components/helper/BrowserTitle';
import { rfqCustomerAuth, rfqList } from '../../../store/actions/customer/rfqAction';
import { useSelector, useDispatch } from 'react-redux';
import { CardLoader, ItemNotFlund } from '../../../components/helper/CustomLoader';
import Pagination from 'react-js-pagination';
import { useHistory, Link } from 'react-router-dom';
// import axios from '../../../api/axios-auth';
// import Spinner from 'react-bootstrap/Spinner';
import { FormSuccess } from '../../../components/helper/FormSuccess';
import ManageRfqNewForm from './ManageRfqNewForm';

const ManageRfq = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.CUSTOMER_RFQ_INFO);
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const pageCount = new URLSearchParams(window.location.search).get('page');
  const [success, setSuccess] = useState(null);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());
    dispatch(rfqCustomerAuth(pageNumber));
  };

  useEffect(() => {
    dispatch(rfqCustomerAuth(parseInt(pageCount)));

    return () => {
      dispatch(rfqList([]));
    };
  }, [dispatch, pageCount]);

  // const [rfqLoad, setRfqLoad] = useState(false)

  // const createAndRedirectOnRfq = () => {
  //   setRfqLoad(true)

  //   dispatch({ payload: {}, type: "INITIAL_EMPTY" })
  //   dispatch({ payload: null, type: "CART_FAVORITE" })
  //   dispatch({ payload: [], type: "WAREHOUSE_LIST" })

  //   let data = {
  //     "warehouseSpaceRequired": 0,
  //     "warehouses": [],
  //     "location": {
  //       "city": {
  //         "name": ""
  //       },
  //       "area": {
  //         "name": ""
  //       }
  //     }
  //   }

  //   axios.post(`/api/v1/customerrfq`, data).then(response => {
  //     let res = JSON.parse(response.data)
  //     if (res.statusCode === 200) {
  //       dispatch(initialRfqByIdAndType(res.data.id, "customerRfq"))
  //       history.replace(`/createnewrfq?rfqid=${res.data.id}&hide=${false}`)
  //     }
  //   }).catch((error) => {
  //   }).then(() => {
  //     setRfqLoad(false)
  //     console.log("-----always executes");
  //   })
  // }

  useEffect(() => {
    document.getElementsByTagName('footer')[0].classList.add('d-none');
  });

  return (
    <Layout>
      <BrowserTitle title='RFQ' />

      {success && <FormSuccess onClick={() => setSuccess(null)} message={success} />}

      <CustomerLayout title={`RFQ`}>
        <div class='text-center bg-white border-top border-left border-right'>
          <h3 className='pt-2 pb-2 m-0'>Create Rfq</h3>
        </div>
        <ManageRfqNewForm />
      </CustomerLayout>
    </Layout>
  );
};

export default ManageRfq;

import React, { useState, useEffect } from 'react';
import {
  updateSafetySecurity,
  changeWarehouseStatus,
  updateWarehousependingDetails,
} from '../../../store/actions/vendor/warehouseList';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormSuccess } from '../../helper/FormSuccess';
import { readableDate } from '../../validation';
import Spinner from 'react-bootstrap/Spinner';
import ErrorCard, { FormErrorCard } from '../../helper/ErrorCard';
import { CardLoader } from '../../helper/CustomLoader';
import { Formik, Form, Field, ErrorMessage, getIn } from 'formik';

const SafetySecurityForm = ({ warehouseId, slug, viewMood }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSELIST);
  const [errors, setErrors] = useState({});
  const [formSub, setFormSub] = useState(false);
  const [yesForm, setYesForm] = useState({
    fireABCYes: 'no',
    fireCO2Yes: 'no',
    fireOtherYes: 'no',
    fireSprinklersYes: 'no',
    fireHydrantYes: 'no',
    cctvYes: 'no',
    waterYes: 'no',
    smokeYes: 'no',
    securityYes: 'no',
    metalYes: 'no',
    pestYes: 'no',
  });

  const {
    fireABCYes,
    fireCO2Yes,
    fireOtherYes,
    fireSprinklersYes,
    fireHydrantYes,
    cctvYes,
    waterYes,
    smokeYes,
    securityYes,
    metalYes,
    pestYes,
  } = yesForm;

  const yesFormHandle = (e) => {
    setYesForm({ ...yesForm, [e.target.name]: e.target.value });
  };

  // Main form summition controll

  const [safetyForm, setSafetyForm] = useState({
    fireExtinguishers: [
      {
        type: '',
        quantity: '',
        fireExtinguisher: {
          validFrom: '',
          validTill: '',
        },
      },
      {
        type: '',
        quantity: '',
        fireExtinguisher: {
          validFrom: '',
          validTill: '',
        },
      },
      {
        type: '',
        quantity: '',
        fireExtinguisher: {
          validFrom: '',
          validTill: '',
        },
      },
    ],
    fireSprinklers: {
      value: '',
      whsremark: '',
    },
    fireHydrant: {
      value: '',
      whsremark: '',
    },
    cctv: {
      value: '',
      whsremark: '',
    },
    waterTank: {
      value: '',
      whsremark: '',
    },
    smokeDetectorsAndFireAlarm: {
      value: '',
      whsremark: '',
    },
    securityGuard: {
      value: '',
      whsremark: '',
    },
    metalDetector: {
      value: '',
      whsremark: '',
    },
    pestControl: {
      value: '',
      whsremark: '',
    },
    safetySecurityOther: {
      value: '',
      whsremark: '',
    },
    fireABCQty: '',
    fireCO2Qty: '',
    fireOtherQty: '',
    waterQty: '',
    others: '',
  });

  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/update-warehouse-rejected/${warehouseId}/permits`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);

  useEffect(() => {
    // console.log(data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks, "||warehouseRej rejected||")
    if (
      data?.singleFormData?.remarks === 0 &&
      data?.singleFormData?.warehouseRej?.warehouseSecurities !== null &&
      data?.singleFormData?.warehouseRej?.warehouseSecurities !== undefined
    ) {
      setSafetyForm({
        fireExtinguishers: [
          {
            type: '',
            quantity:
              data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[0]
                ?.quantity,
            fireExtinguisher: {
              validFrom:
                data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[0]
                  ?.fireExtinguisher?.validFrom,
              validTill:
                data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[0]
                  ?.fireExtinguisher?.validTill,
            },
          },
          {
            type: '',
            quantity:
              data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[1]
                ?.quantity,
            fireExtinguisher: {
              validFrom:
                data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[1]
                  ?.fireExtinguisher?.validFrom,
              validTill:
                data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[1]
                  ?.fireExtinguisher?.validTill,
            },
          },
          {
            type: '',
            quantity:
              data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[2]
                ?.quantity,
            fireExtinguisher: {
              validFrom:
                data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[2]
                  ?.fireExtinguisher?.validFrom,
              validTill:
                data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[2]
                  ?.fireExtinguisher?.validTill,
            },
          },
        ],
        fireSprinklers: {
          value: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireSprinklers,
          whsremark: '',
        },
        fireHydrant: {
          value: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireHydrant,
          whsremark: '',
        },
        cctv: {
          value: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.cctv,
          whsremark: '',
        },
        waterTank: {
          value: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.waterTank?.quantity,
          whsremark: '',
        },
        smokeDetectorsAndFireAlarm: {
          value:
            data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.smokeDetectorsAndFireAlarm,
          whsremark: '',
        },
        securityGuard: {
          value: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.securityGuard,
          whsremark: '',
        },
        metalDetector: {
          value: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.metalDetector,
          whsremark: '',
        },
        pestControl: {
          value: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.pestControl,
          whsremark: '',
        },
        safetySecurityOther: {
          value: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.securityGuard,
          whsremark: '',
        },
        fireABCQty:
          data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[0]
            ?.quantity,
        fireCO2Qty:
          data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[1]
            ?.quantity,
        fireOtherQty:
          data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[2]
            ?.quantity,
        waterQty: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.waterTank?.quantity,
        others: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.others,
      });

      setYesForm({
        fireABCYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[0]
          ?.quantity
          ? 'yes'
          : 'no',
        fireCO2Yes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireExtinguishers[1]
          ?.quantity
          ? 'yes'
          : 'no',
        fireOtherYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]
          ?.fireExtinguishers[2]?.quantity
          ? 'yes'
          : 'no',
        fireSprinklersYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]
          ?.fireSprinklers
          ? 'yes'
          : 'no',
        fireHydrantYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.fireHydrant
          ? 'yes'
          : 'no',
        cctvYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.cctv ? 'yes' : 'no',
        waterYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.waterTank?.quantity
          ? 'yes'
          : 'no',
        smokeYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]
          ?.smokeDetectorsAndFireAlarm
          ? 'yes'
          : 'no',
        securityYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.securityGuard
          ? 'yes'
          : 'no',
        metalYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.metalDetector
          ? 'yes'
          : 'no',
        pestYes: data?.singleFormData?.warehouseRej?.warehouseSecurities[0]?.pestControl
          ? 'yes'
          : 'no',
      });
    } else {
      if (data?.singleFormData !== null && data?.singleFormData?.warehouseRej !== null) {
        if (data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks !== undefined) {
          if (
            Object.keys(data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks).length !== 0
          ) {
            // console.log('||warehouseRej||', data?.singleFormData?.warehouseRej, '||warehouseRej||');
            setSafetyForm(data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks);
            setYesForm({
              fireABCYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks?.fireABCQty
                ? 'yes'
                : 'no',
              fireCO2Yes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks?.fireCO2Qty
                ? 'yes'
                : 'no',
              fireOtherYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks
                ?.fireOtherQty
                ? 'yes'
                : 'no',
              fireSprinklersYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks
                ?.fireSprinklers?.value
                ? 'yes'
                : 'no',
              fireHydrantYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks
                .fireHydrant?.value
                ? 'yes'
                : 'no',
              cctvYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks.cctv?.value
                ? 'yes'
                : 'no',
              waterYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks?.waterQty
                ? 'yes'
                : 'no',
              smokeYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks
                ?.smokeDetectorsAndFireAlarm?.value
                ? 'yes'
                : 'no',
              securityYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks
                ?.securityGuard?.value
                ? 'yes'
                : 'no',
              metalYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks
                ?.metalDetector?.value
                ? 'yes'
                : 'no',
              pestYes: data?.singleFormData?.warehouseRej?.warehouseSecuritiesRemarks.pestControl
                ?.value
                ? 'yes'
                : 'no',
            });
          }
        }
      }
    }
  }, [data?.singleFormData]);

  const HandleChange = (e) => {
    setSafetyForm({ ...safetyForm, [e.target.name]: e.target.value });
    if (formSub) {
      handleValidation();
    }
  };

  const handleValidation = () => {
    let errors = {};
    let isError = true;
    if (fireABCYes === 'yes' && safetyForm.fireABCQty === '') {
      isError = false;
      errors['fireABCQty'] = 'Required';
    }

    if (fireABCYes === 'yes' && safetyForm.fireABCFrom === '') {
      isError = false;
      errors['fireABCFrom'] = 'Required';
    }

    if (fireABCYes === 'yes' && safetyForm.fireABCTill === '') {
      isError = false;
      errors['fireABCTill'] = 'Required';
    }

    if (fireCO2Yes === 'yes' && safetyForm.fireCO2Qty === '') {
      isError = false;
      errors['fireCO2Qty'] = 'Required';
    }

    if (fireCO2Yes === 'yes' && safetyForm.fireCO2From === '') {
      isError = false;
      errors['fireCO2From'] = 'Required';
    }

    if (fireCO2Yes === 'yes' && safetyForm.fireCO2Till === '') {
      isError = false;
      errors['fireCO2Till'] = 'Required';
    }

    if (fireOtherYes === 'yes' && safetyForm.fireOtherQty === '') {
      isError = false;
      errors['fireOtherQty'] = 'Required';
    }

    if (fireOtherYes === 'yes' && safetyForm.fireOtherFrom === '') {
      isError = false;
      errors['fireOtherFrom'] = 'Required';
    }

    if (fireOtherYes === 'yes' && safetyForm.fireOtherTill === '') {
      isError = false;
      errors['fireOtherTill'] = 'Required';
    }

    if (fireSprinklersYes === 'yes' && safetyForm.fireSprinklers === '') {
      isError = false;
      errors['fireSprinklers'] = 'Required';
    }

    if (fireHydrantYes === 'yes' && safetyForm.fireHydrant === '') {
      isError = false;
      errors['fireHydrant'] = 'Required';
    }

    if (cctvYes === 'yes' && safetyForm.cctv === '') {
      isError = false;
      errors['cctv'] = 'Required';
    }
    if (waterYes === 'yes' && safetyForm.waterQty === '') {
      isError = false;
      errors['waterQty'] = 'Required';
    }

    if (waterYes === 'yes' && safetyForm.waterLiter === '') {
      isError = false;
      errors['waterLiter'] = 'Required';
    }

    if (smokeYes === 'yes' && safetyForm.smoke === '') {
      isError = false;
      errors['smoke'] = 'Required';
    }

    if (securityYes === 'yes' && safetyForm.security === '') {
      isError = false;
      errors['security'] = 'Required';
    }

    if (metalYes === 'yes' && safetyForm.metal === '') {
      isError = false;
      errors['metal'] = 'Required';
    }

    if (pestYes === 'yes' && safetyForm.pest === '') {
      isError = false;
      errors['pest'] = 'Required';
    }

    if (safetyForm.others === '') {
      isError = false;
      errors['others'] = 'Required';
    }

    setErrors(errors);
    return isError;
  };

  const {
    fireABCQty,
    fireABCFrom,
    fireABCTill,
    fireCO2Qty,
    fireCO2From,
    fireCO2Till,
    fireOtherQty,
    fireOtherFrom,
    fireOtherTill,
    fireSprinklers,
    fireHydrant,
    cctv,
    waterQty,
    waterLiter,
    smoke,
    security,
    metal,
    pest,
    others,
  } = safetyForm;

  const safetySubmit = (e) => {
    e.preventDefault();
    setFormSub(true);
    if (handleValidation()) {
      let formData = {
        fireExtinguishers: [
          {
            type: 'code',
            quantity: fireABCYes === 'yes' ? fireABCQty : '',
            fireExtinguisher: {
              validFrom: fireABCYes === 'yes' ? fireABCFrom : '',
              validTill: fireABCYes === 'yes' ? fireABCTill : '',
            },
          },
          {
            type: 'co2',
            quantity: fireCO2Yes === 'yes' ? fireCO2Qty : '',
            fireExtinguisher: {
              validFrom: fireCO2Yes === 'yes' ? fireCO2From : '',
              validTill: fireCO2Yes === 'yes' ? fireCO2Till : '',
            },
          },
          {
            type: 'h20',
            quantity: fireOtherYes === 'yes' ? fireOtherQty : '',
            fireExtinguisher: {
              validFrom: fireOtherYes === 'yes' ? fireOtherFrom : '',
              validTill: fireOtherYes === 'yes' ? fireOtherTill : '',
            },
          },
        ],
        fireSprinklers: fireSprinklersYes === 'yes' ? fireSprinklers : '',
        fireHydrant: fireHydrantYes === 'yes' ? fireHydrant : '',
        cctv: cctvYes === 'yes' ? cctv : '',
        waterTank: {
          quantity: waterYes === 'yes' ? waterQty : '',
          storage: waterYes === 'yes' ? waterLiter : '',
        },
        smokeDetectorsAndFireAlarm: smokeYes === 'yes' ? smoke : '',
        securityGuard: securityYes === 'yes' ? security : '',
        metalDetector: metalYes === 'yes' ? metal : '',
        pestControl: pestYes === 'yes' ? pest : '',
        others: others,
        warehouse: warehouseId,
      };
      dispatch(updateSafetySecurity(formData));
    } else {
      console.log('submission Fail');
    }
  };

  return (
    <>
      {/* Loader */}

      {data?.isLoading ? (
        <CardLoader />
      ) : data?.isError !== '' ? (
        <ErrorCard message={data.isError} />
      ) : (
        <>
          {data?.addNewResponse?.statusCode === 200 ? (
            <FormSuccess
              onClick={() => dispatch(changeWarehouseStatus())}
              message={data.addNewResponse.message}
            />
          ) : null}

          <div className='row align-items-center pb-3 mx-0 overflow-auto'>
            <div className='col-12'>
              <Formik
                enableReinitialize={true}
                initialValues={safetyForm}
                // validationSchema={mhInfraSchema}
                onSubmit={(fields) => {
                  const payload = {
                    data: {
                      fields: fields,
                    },
                    warehouseId: warehouseId,
                    slug: slug,
                  };

                  dispatch(updateWarehousependingDetails(payload));
                }}
                render={({ errors, status, onChange, touched, values }) => {
                  return (
                    <Form>
                      <div className='row bg-white rounded mx-0 col-xxxl-11'>
                        <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                          <table className='table customTable'>
                            <tbody>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Fire Extinguisher (ABC):</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 w-200px mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireABCYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireABCYes'
                                      id='fireABCYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireABCYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireABCYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireABCYes'
                                      id='fireABCYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireABCYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <Field
                                    type='number'
                                    name='fireABCQty'
                                    // value={fireABCQty}
                                    // onChange={(e) => HandleChange(e)}
                                    disabled={fireABCYes === 'no'}
                                    className='form-control bg-white px-1 mb-1 mt-3 text-center col-9 ml-4'
                                    placeholder='Qty'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireABCYes === 'no' ? null : errors['fireABCQty']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    type='date'
                                    name='fireExtinguishers[0].fireExtinguisher.validFrom'
                                    disabled={fireABCYes === 'no'}
                                    className='form-control bg-white px-4 mb-2'
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireABCYes === 'no' ? null : errors['fireABCFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    type='date'
                                    name='fireExtinguishers[0].fireExtinguisher.validTill'
                                    disabled={fireABCYes === 'no'}
                                    className='form-control bg-white px-4 mb-2'
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireABCYes === 'no' ? null : errors['fireABCTill']}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <>
                                    <Field
                                      disabled
                                      name='fireExtinguishers[0].fireExtinguisher.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>

                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Fire Extinguisher (CO2):</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 w-200px mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireCO2Yes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireCO2Yes'
                                      id='fireCO2Yes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireCO2Yes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireCO2Yes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireCO2Yes'
                                      id='fireCO2Yes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireCO2Yes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <Field
                                    type='number'
                                    name='fireCO2Qty'
                                    // value={fireCO2Qty}
                                    // onChange={(e) => HandleChange(e)}
                                    disabled={fireCO2Yes === 'no'}
                                    className='form-control bg-white px-1 mb-1 mt-3 text-center col-9 ml-4'
                                    placeholder='Qty'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireCO2Yes === 'no' ? null : errors['fireCO2Qty']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    type='date'
                                    name='fireExtinguishers[1].fireExtinguisher.validFrom'
                                    disabled={fireCO2Yes === 'no'}
                                    className='form-control bg-white px-4 mb-2'
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireCO2Yes === 'no' ? null : errors['fireCO2From']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    type='date'
                                    name='fireExtinguishers[1].fireExtinguisher.validTill'
                                    disabled={fireCO2Yes === 'no'}
                                    className='form-control bg-white px-4 mb-2'
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireCO2Yes === 'no' ? null : errors['fireCO2Till']}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <>
                                    <Field
                                      disabled
                                      name='fireExtinguishers[1].fireExtinguisher.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Fire Extinguisher (Others):</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 w-200px mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireOtherYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireOtherYes'
                                      id='fireOtherYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireOtherYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireOtherYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireOtherYes'
                                      id='fireOtherYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireOtherYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <Field
                                    type='number'
                                    name='fireOtherQty'
                                    // value={fireOtherQty}
                                    // onChange={(e) => HandleChange(e)}
                                    disabled={fireOtherYes === 'no'}
                                    className='form-control bg-white px-1 mb-1 mt-3 text-center col-9 ml-4'
                                    placeholder='Qty'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireOtherYes === 'no' ? null : errors['fireOtherQty']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    type='date'
                                    name='fireExtinguishers[2].fireExtinguisher.validFrom'
                                    disabled={fireOtherYes === 'no'}
                                    className='form-control bg-white px-4 mb-2'
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireOtherYes === 'no' ? null : errors['fireOtherFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    type='date'
                                    name='fireExtinguishers[2].fireExtinguisher.validTill'
                                    disabled={fireOtherYes === 'no'}
                                    className='form-control bg-white px-4 mb-2'
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireOtherYes === 'no' ? null : errors['fireOtherTill']}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <>
                                    <Field
                                      disabled
                                      name='fireExtinguishers[2].fireExtinguisher.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>

                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Fire Sprinklers:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireSprinklersYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireSprinklersYes'
                                      id='fireSprinklersYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireSprinklersYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireSprinklersYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireSprinklersYes'
                                      id='fireSprinklersYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireSprinklersYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4' colSpan='2'>
                                  <Field
                                    type='number'
                                    name='fireSprinklers.value'
                                    disabled={fireSprinklersYes === 'no'}
                                    className='form-control bg-white px-4 mt-2 mb-2'
                                    placeholder='Qty'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireSprinklersYes === 'no' ? null : errors['fireSprinklers']}
                                  </span>
                                </td>
                                <td className='pb-4'></td>
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <>
                                    <Field
                                      disabled
                                      name='fireSprinklers.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Fire Hydrant:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireHydrantYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireHydrantYes'
                                      id='fireHydrantYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireHydrantYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={fireHydrantYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='fireHydrantYes'
                                      id='fireHydrantYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fireHydrantYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4' colSpan='2'>
                                  <Field
                                    type='number'
                                    name='fireHydrant.value'
                                    disabled={fireHydrantYes === 'no'}
                                    className='form-control bg-white px-4 mt-2 mb-2'
                                    placeholder='Qty'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {fireHydrantYes === 'no' ? null : errors['fireHydrant']}
                                  </span>
                                </td>
                                <td className='pb-4'></td>
                              </tr>

                              <td className='w-25px'></td>

                              <td className='w-25px'></td>
                              <td
                                className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                colSpan='2'
                              ></td>
                              <td className='mw-150px'>
                                <>
                                  <Field
                                    disabled
                                    name='fireHydrant.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </>
                              </td>
                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>CCTV:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={cctvYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='cctvYes'
                                      id='cctvYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='cctvYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={cctvYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='cctvYes'
                                      id='cctvYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='cctvYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4' colSpan='2'>
                                  <Field
                                    type='number'
                                    name='cctv.value'
                                    disabled={cctvYes === 'no'}
                                    className='form-control bg-white px-4 mt-2 mb-2'
                                    placeholder='Qty'
                                  />
                                  <span className='errorMsg'>
                                    {cctvYes === 'no' ? null : errors['cctv']}
                                  </span>
                                </td>
                                <td className='pb-4'></td>
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <>
                                    <Field
                                      disabled
                                      name='cctv.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Water Tank:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={waterYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='waterYes'
                                      id='waterYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='waterYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={waterYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='waterYes'
                                      id='waterYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='waterYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4' colSpan='2'>
                                  <Field
                                    type='number'
                                    name='waterQty'
                                    // value={waterQty}
                                    // onChange={(e) => HandleChange(e)}
                                    disabled={waterYes === 'no'}
                                    className='form-control bg-white px-4 mt-2 mb-2'
                                    placeholder='Qty'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {waterYes === 'no' ? null : errors['waterQty']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <Field
                                    type='number'
                                    name='waterTank.value'
                                    disabled={waterYes === 'no'}
                                    className='form-control bg-white px-4 mt-2 mb-2'
                                    placeholder='Total Storage in Liter'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {waterYes === 'no' ? null : errors['waterLiter']}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <>
                                    <Field
                                      disabled
                                      name='waterTank.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Smoke Detectors / Fire Alarm:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={smokeYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='smokeYes'
                                      id='smokeYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='smokeYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={smokeYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='smokeYes'
                                      id='smokeYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='smokeYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4' colSpan='2'>
                                  <Field
                                    type='number'
                                    name='smokeDetectorsAndFireAlarm.value'
                                    disabled={smokeYes === 'no'}
                                    className='form-control bg-white px-4 mt-2 mb-2'
                                    placeholder='Qty'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {smokeYes === 'no' ? null : errors['smoke']}
                                  </span>
                                </td>
                                <td className='pb-4'></td>
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <>
                                    <Field
                                      disabled
                                      name='smokeDetectorsAndFireAlarm.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Security Guard:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={securityYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='securityYes'
                                      id='securityYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='securityYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={securityYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='securityYes'
                                      id='securityYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='securityYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4' colSpan='2'>
                                  <Field
                                    type='number'
                                    name='securityGuard.value'
                                    disabled={securityYes === 'no'}
                                    className='form-control bg-white px-4 mt-2 mb-2'
                                    placeholder='Qty'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {securityYes === 'no' ? null : errors['security']}
                                  </span>
                                </td>
                                <td className='pb-4'></td>
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <>
                                    <Field
                                      disabled
                                      name='securityGuard.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Metal Detector:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={metalYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='metalYes'
                                      id='metalYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='metalYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={metalYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='metalYes'
                                      id='metalYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='metalYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4' colSpan='2'>
                                  <Field
                                    type='number'
                                    name='metalDetector.value'
                                    disabled={metalYes === 'no'}
                                    className='form-control bg-white px-4 mt-2 mb-2'
                                    placeholder='Qty'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {metalYes === 'no' ? null : errors['metal']}
                                  </span>
                                </td>
                                <td className='pb-4'></td>
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  {data?.singleFormData?.safetyAndSecurityRemark !== undefined &&
                                  data?.singleFormData?.safetyAndSecurityRemark !== null &&
                                  Object.keys(data?.singleFormData?.safetyAndSecurityRemark)
                                    .length > 0 ? (
                                    <>
                                      <Field
                                        disabled
                                        name='metalDetector.whsremark'
                                        type='text'
                                        className='form-control  px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Pest Control:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={pestYes === 'yes'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='pestYes'
                                      id='pestYes'
                                      value='yes'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='pestYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => yesFormHandle(e)}
                                      checked={pestYes === 'no'}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='pestYes'
                                      id='pestYes1'
                                      value='no'
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='pestYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4' colSpan='2'>
                                  <label htmlFor='' className='mb-0 text-uppercase small px-4'>
                                    Select Last Date of Pest Control
                                  </label>
                                  <Field
                                    type='date'
                                    name='pestControl.value'
                                    disabled={pestYes === 'no'}
                                    className='form-control bg-white px-4'
                                    readOnly={viewMood}
                                  />
                                  <span className='errorMsg'>
                                    {pestYes === 'no' ? null : errors['pest']}
                                  </span>
                                </td>
                                <td className='pb-4'></td>
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <>
                                    <Field
                                      disabled
                                      name='pestControl.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-5 pb-1'>Others:</p>
                                </td>
                                <td className='' colSpan='4'>
                                  <Field
                                    type='text'
                                    name='others'
                                    // onChange={(e) => HandleChange(e)}
                                    className='form-control bg-white px-4 rounded-sm'
                                    placeholder=''
                                    rows='3'
                                    readOnly={viewMood}
                                  ></Field>

                                  <span className='errorMsg'>{errors['others']}</span>
                                </td>
                              </tr>
                              <tr></tr>
                            </tbody>
                          </table>
                        </div>

                        <div className={`col-12 mt-4 ${viewMood ? 'd-none' : ''}`}>
                          <div className='row justify-content-end'>
                            {Object.keys(errors).length !== 0 ? (
                              <FormErrorCard message='Fill All Required Fields' />
                            ) : null}
                            {data?.isError !== '' ? (
                              <FormErrorCard message={data?.isError} />
                            ) : null}
                            <div className='col-auto'>
                              <button
                                type='submit'
                                disabled={data.isPending}
                                className='btn btn-deep-blue add-className remove-className'
                              >
                                Save
                                {data?.isPending ? <Spinner animation='border' /> : null}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SafetySecurityForm;

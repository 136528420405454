import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import VendorLayout from '../../../layout/VendorLayout';
import MenuDrawer from '../../../components/vendor/MenuDrawer';
// import UpdateITInfraForm from '../../../components/vendor/warehouseForm/UpdateITInfraForm';
// import SafetySecurityForm from '../../../components/vendor/warehouseForm/SafetySecurityForm';
// import PermitForm from '../../../components/vendor/warehouseForm/PermitForm';
// import MaterialTypeForm from '../../../components/vendor/warehouseForm/MaterialTypeForm';
// import WarehousePhotoForm, {
//   UserContext,
// } from '../../../components/vendor/warehouseForm/WarehousePhotoForm';
// import FormEighty from '../../../components/vendor/warehouseForm/FormEighty';
// import UpdateWarehouse from '../../../components/vendor/warehouseForm/UpdateWarehouse';
import {
  fetchWarehouseByIdAndTypeNew,
  // fetchWarehouseById,
  // conciousUpdateWarehouseStatus,
  // clearSinglePageStateData,
  // clearLoader,
  clearErrorMessages,
  // changeWarehouseStatus,
  // formTrack,
} from '../../../store/actions/vendor/warehouseList';
import { useDispatch, useSelector } from 'react-redux';
// import UpdateMHInfraForm from '../../../components/vendor/warehouseForm/UpdateMHInfraForm';
import ConciseUpdateContactDetailForm from '../../../components/vendor/warehouseForm/ConciseUpdateContactDetailForm';
// import UpdateStorageSpaceForm from '../../../components/vendor/warehouseForm/UpdateStorageSpaceForm';
import ConciseUpdateStorageSpaceForm from '../../../components/vendor/warehouseForm/ConciseUpdateStorageSpaceForm';
import ConciseUpdatePhotoForm from '../../../components/vendor/warehouseForm/ConciseUpdatePhotoForm';

// import Spinner from 'react-bootstrap/Spinner';
// import {FormSuccess} from '../../../components/helper/FormSuccess';
// import { SAVEVALUE } from '../../../store/types';

import { CssBaseline, Container, Paper, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { FormProvider } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Typography, Button } from '@material-ui/core';
// import { makeStyles } from "@material-ui/core/styles";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import SpinnerLoader from '../../dashboard/rfq/SpinnerLoader';
const steps = ['Contact detail', 'Storage space', 'Warehouse Photo'];
const stepsslug = steps.map((elem, index) => {
  return elem.toLowerCase().replace(' ', '-');
});
//linear stepper outer function
const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
// let flag = false;
// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// }));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

const StepperSx = {
  '& .MuiStepConnector-root': {
    left: 'calc(-50% + 40px)',
    right: 'calc(50% + 40px)',
  },
  '& .MuiStepConnector-line': {
    marginTop: '22px',
  },
};

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <GroupAddIcon />,
    2: <SettingsIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function getStepContent(step, warehouseId, slug, closeErrorMessage) {
  // console.log(step, warehouseId, slug, 'Action trigger');
  switch (slug) {
    case undefined:
      if (step === 0 && warehouseId === undefined && slug === undefined) {
        return <ConciseUpdateContactDetailForm closeErrorMessage={closeErrorMessage} />;
      }
    case 'contact-detail':
      return (
        <ConciseUpdateContactDetailForm
          warehouseId={warehouseId}
          viewMood={false}
          slug={slug}
          closeErrorMessage={closeErrorMessage}
        />
      );
    case 'storage-space':
      return (
        <ConciseUpdateStorageSpaceForm
          warehouseId={warehouseId}
          viewMood={false}
          slug={slug}
          closeErrorMessage={closeErrorMessage}
        />
      );
    case 'warehouse-photo':
      return (
        <ConciseUpdatePhotoForm
          warehouseId={warehouseId}
          viewMood={false}
          slug={slug}
          closeErrorMessage={closeErrorMessage}
        />
      );
  }
}

const UpdateOfWarehouse = () => {
  const { warehouseId, slug } = useParams();
  const data = useSelector((state) => state.WAREHOUSELIST);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [expanded, setExpanded] = useState(false);
  // const [manuallyMsg, setManuallyMsg] = useState([]);

  //linear stepper state
  // const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [stepError, setStepError] = useState('error message Display');

  const isStepFailed = (step) => {
    // return step === activeStep
  };
  const [isLoading, setIsLoading] = useState(false);

  const closeErrorMessage = () => {
    dispatch(clearErrorMessages());
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
      history.push(
        `/vendor/warehouse/concise/create-warehouse/${warehouseId}/${stepsslug[activeStep + 1]}`,
      );
    }
    setIsLoading(false);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    history.push(
      `/vendor/warehouse/concise/create-warehouse/${warehouseId}/${stepsslug[activeStep - 1]}`,
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (slug === undefined) {
      setActiveStep(0);
    } else {
      const index = stepsslug.findIndex((elem) => elem === slug);
      // console.log(index, '||index||');
      setActiveStep(index);
    }
  }, [data, slug, dispatch]);

  // const data = useSelector((state) => state.WAREHOUSELIST);
  // const data1 = useSelector((state) => state.saveReducer);

  // console.log("dattt",data1)

  // const profile = useSelector((state) => state.USERPROFILE.userProfile);

  // const handleChange = (panel, type) => (event, isExpanded) => {
  //   console.log(
  //     'I am on tab click section isExpanded=>',
  //     isExpanded,
  //     'type=>',
  //     type,
  //     data.warehouseDetail.warehouseImages,
  //   );
  //   if (panel != 'panel10') {
  //     data.warehouseDetail.warehouseImages = false;
  //   }
  //   if (isExpanded) {
  //     if (type === 'wareUpdate') {
  //       // alert("I am first action ")
  //       dispatch(fetchWarehouseById(parseInt(warehouseId)));
  //     } else {
  //       console.log('Running action every time triger');
  //       dispatch(fetchWarehouseByIdAndType(warehouseId, type));
  //     }
  //   }

  // };

  // const changeAccordion = () => {
  //   console.log(data.panelRole, warehouseId, data.accordion, 'I am trigger after FormSuccess');
  //   dispatch(fetchWarehouseByIdAndType(warehouseId, data.accordion));
  //   setExpanded(data.panelRole);
  // };
  // function checkManuallyValidation(data) {
  //   console.log('I am set action trigger three times', data);
  //   return [
  //     data.warehouseDetail.warehouseContactDetail === true ? '' : 'Warehouse Contact is required',
  //     data.warehouseDetail.storageSpace === true ? '' : 'Storage Space is required',
  //     data.warehouseDetail.warehouseImages === true ? '' : 'Wharehouse Photo required',
  //   ];
  // }

  // function redirect() {
  //   alert('I am redirect section');
  // }

  useEffect(() => {
    // console.log('clear loader', warehouseId, slug);
    if (warehouseId !== undefined && slug !== undefined) {
      dispatch(fetchWarehouseByIdAndTypeNew(warehouseId, slug));
    }
    // alert('Hello');
    // dispatch(fetchWarehouseByIdAndType(warehouseId, 'warehouseContactDetails'));
    /* if(data.accordion==""){
      dispatch(fetchWarehouseById(parseInt(warehouseId)))
    }else{
      dispatch(fetchWarehouseByIdAndType(warehouseId,data.accordion))
    }*/
    // setExpanded('concise1');
    // setExpanded(data.panelRole);
    // dispatch(fetchWarehouseById(parseInt(warehouseId)))
    // return () => {
    //   dispatch(clearLoader);
    // };
  }, [slug]);

  // const save = useContext(UserContext);
  // console.log('saveee', save);

  return (
    <VendorLayout>
      <div className='content-admin px-2'>
        <div className='row justify-content-end align-items-center py-3 px-3 mx-0'>
          <MenuDrawer />
          <div className='col-12 '>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <h5 className='backButton mb-5 text-dark'>
                    <i
                      onClick={() => history.goBack()}
                      className='fas fa-chevron-left mr-3 cursorPointer'
                    ></i>
                    List Your Warehouse
                  </h5>

                  <>
                    <CssBaseline />
                    <Container p={4} style={{ padding: '0px' }}>
                      <Paper p={3} style={{ padding: '0px' }} className='py-4'>
                        <>
                          <Stack sx={{ width: '100%', paddingBottom: '15px' }} spacing={4}>
                            <Stepper
                              alternativeLabel
                              activeStep={activeStep}
                              sx={StepperSx}
                              connector={<ColorlibConnector />}
                            >
                              {steps.map((label, index) => {
                                const labelProps = {};
                                if (isStepFailed(index)) {
                                  labelProps.optional = (
                                    <Typography variant='caption' color='error'>
                                      {stepError}
                                    </Typography>
                                  );

                                  labelProps.error = true;
                                }

                                return (
                                  <Step key={label}>
                                    <Typography align='center'>{label}</Typography>
                                    {Object.keys(labelProps).length > 0 ? (
                                      <>
                                        <StepLabel {...labelProps}></StepLabel>
                                      </>
                                    ) : (
                                      <>
                                        <StepLabel
                                          {...labelProps}
                                          StepIconComponent={ColorlibStepIcon}
                                        ></StepLabel>
                                      </>
                                    )}
                                  </Step>
                                );
                              })}
                            </Stepper>
                          </Stack>
                          {activeStep === steps.length ? (
                            <Typography variant='h3' align='center'>
                              Thank You
                            </Typography>
                          ) : (
                            <>
                              {/* {data?.isPending ? <SpinnerLoader /> : null} */}
                              <FormProvider>
                                <form onSubmit={handleNext}>
                                  {getStepContent(activeStep, warehouseId, slug, closeErrorMessage)}

                                  {/* {warehouseId ? warehouseId : 'dddddd'} */}
                                  {/* {slug ? slug : 'yuyu'} */}
                                  {warehouseId !== undefined && slug !== undefined ? (
                                    <div className='nextPreviousform pb-0'>
                                      <span>
                                        <MobileStepper
                                          variant='dots'
                                          steps={3}
                                          position='static'
                                          activeStep={activeStep}
                                          sx={{ flexGrow: 1, width: '30%', margin: '0 auto' }}
                                          nextButton={
                                            <Button
                                              size='small'
                                              onClick={() => {
                                                setIsLoading(true);
                                                handleNext();
                                              }}
                                              disabled={true}
                                            // disabled={
                                            //   data?.singleFormData?.warehouseStorageSpaces ===
                                            //     null ||
                                            //     data?.singleFormData?.warehouseImages === null
                                            //     ? true
                                            //     : false
                                            // }
                                            // disabled={
                                            //   data?.singleFormData?.warehouseStorageSpaces ===
                                            //     null ||
                                            //   data?.singleFormData?.warehouseContacts === null ||
                                            //   data?.singleFormData?.warehouseImages === null
                                            // }
                                            >
                                              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                              {theme.direction === 'rtl' ? (
                                                <KeyboardArrowLeft />
                                              ) : (
                                                <KeyboardArrowRight />
                                              )}
                                            </Button>
                                          }
                                          backButton={
                                            <Button
                                              size='small'
                                              onClick={() => {
                                                setIsLoading(true);
                                                handleBack();
                                              }}
                                              disabled={activeStep === 0}
                                            >
                                              {theme.direction === 'rtl' ? (
                                                <KeyboardArrowRight />
                                              ) : (
                                                <KeyboardArrowLeft />
                                              )}
                                              Back
                                            </Button>
                                          }
                                        />
                                      </span>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </form>
                              </FormProvider>
                            </>
                          )}
                        </>
                      </Paper>
                    </Container>
                  </>
                  {/*test*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VendorLayout>
  );
};

export default UpdateOfWarehouse;

import React from 'react';
import { CssBaseline, Container, Paper, Box } from '@material-ui/core';
import LinaerStepper from './LinearStepper';
import Layout from '../../../layout/Layout';
import CustomerLayout from '../../../layout/CustomerLayout';

const ManageRfqNewForm = () => {
  return (
    <>
      <CssBaseline />
      <Container component={Box} p={4} style={{ padding: '0px' }}>
        <Paper component={Box} p={3} style={{ padding: '0px' }} className='py-4'>
          <LinaerStepper />
        </Paper>
      </Container>
    </>
  );
};

export default ManageRfqNewForm;

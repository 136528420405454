import React, { Component } from 'react';
import { connect } from 'react-redux';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {isPermission} from "../store/actions/sidemenuAction";

export default function (ComposedComponent) {
  class Authentication extends Component {

    permissionAuth(objectAry){
      let newAry=[]
      let finalUrlAry=[]
      for (var i = 0; i < objectAry.length; i++) {
          for (var parent = 0; parent < objectAry[i].items.length; parent++) {
              newAry.push(objectAry[i].items[parent].link)
          }
      }

      function newUrlFromUrl(url){
          return `${url.split('/')[2]}/${url.split('/')[3]}`
      }

      for (var f = 0; f < newAry.length; f++) {
          let firstMakeUrl=newAry[f].split('/')[2]
          let secondMakeUrl=newAry[f].split('/')[3]
          if(secondMakeUrl.split('?').length>1){
              secondMakeUrl=secondMakeUrl.split('?')[0]
          }
          finalUrlAry.push(`${firstMakeUrl}/${secondMakeUrl}`)    
      }

      if(finalUrlAry.includes(newUrlFromUrl(window.location.pathname))){
        console.log("Included in Array")
      }else{
        if(window.location.pathname!=="/vendor"){
          this.props.dispatch(isPermission("Permission denied"))
          this.props.history.push("/vendor");
          console.log("Hello abcd trigger two times action")
          setTimeout(() => {
            this.props.dispatch(isPermission(""))
          }, 5000);
        }
      }
    }
    componentWillMount() {
        //alert(window.location.pathname)
        console.log(this.props.permissionAuth,"Before Action permissionAuth")
        this.props.dispatch(isPermission(""))
        if(this.props.permissionAuth !==null && this.props.permissionAuth !==undefined){
          console.log(this.props,"|****&&*****|",this.props.permissionAuth)
          this.permissionAuth(this.props.permissionAuth);
        }
        if(!this.props.vendorAuthenticated) {
          this.props.history.push('/');
        }

        // if(!this.props.vendorAuthenticated && !this.props.authenticated) {
        //   this.props.history.push('/');
        // }
      
    }

    componentWillUpdate(nextProps) {

        if(!nextProps.vendorAuthenticated) {
          this.props.history.push('/');
        }
      
    }

    PropTypes = {
      router: PropTypes.object,
    }

    render () {
      // console.log(this.props,"GGGGGGGGG")
      return <ComposedComponent {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    if(localStorage.getItem('userType')==="vendor"){
      return { vendorAuthenticated: state.vendorAuthenticated,permissionAuth: state.SIDEMENU_INFO.sidemenu };
    }
    console.log("I am HOC component")
     /*else{
     return { authenticated: state.authenticated };  
    }*/

  }

  /*function mapDispatchToProps (dispatch) {
    return {
      isPermission: () => dispatch(isPermission('premission not allowed'))
    }
  }*/
  return connect(mapStateToProps)(Authentication);
}
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage, getIn } from 'formik';
import {
  changeWarehouseStatus,
  updateWarehousependingDetails,
} from '../../../store/actions/vendor/warehouseList';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormSuccess } from '../../helper/FormSuccess';
import Spinner from 'react-bootstrap/Spinner';
import ErrorCard, { FormErrorCard } from '../../helper/ErrorCard';
import { CardLoader } from '../../helper/CustomLoader';

const UpdateMHInfraForm = ({ warehouseId, slug, viewMood }) => {
  const history = useHistory();
  const [initValue, setInitValue] = useState({
    pickingTrolley: {
      value: '',
      whsremark: '',
    },
    hydraulicDockLevler: {
      value: '',
      whsremark: '',
    },
    batteryOperatedPalletTruck: {
      value: '',
      whsremark: '',
    },
    handPalletTruck: {
      value: '',
      whsremark: '',
    },
    electricStacker: {
      value: '',
      whsremark: '',
    },
    shrinkAndStretchWrapMachine: {
      value: '',
      whsremark: '',
    },
    pallets: {
      value: '',
      whsremark: '',
    },
    weighingMachine: {
      value: '',
      whsremark: '',
    },
    forkLifts: [
      {
        type: 'batteryoperatedup',
        forkLift: {
          value: '',
          whsremark: '',
        },
      },
      {
        type: 'dieseloperated',
        forkLift: {
          value: '',
          whsremark: '',
        },
      },
    ],
    hydraCrane: {
      value: '',
      whsremark: '',
    },
    others: {
      value: '',
      whsremark: '',
    },
  });

  const [yesForm, setYesForm] = useState({
    palletYes: false,
    handPalletYes: false,
    weighingMachineYes: false,
    pickingTrolleyYes: false,
    hydraulicYes: false,
    batteryYes: false,
    forkliftYes: false,
    forkliftDieselYes: false,
    electricYes: false,
    hydraYes: false,
    shrinkYes: false,
  });
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSELIST);

  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/update-warehouse-rejected/${warehouseId}/safety-info`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);

  useEffect(() => {
    if (
      data?.singleFormData?.remarks === 0 &&
      data?.singleFormData?.warehouseRej?.warehouseMHInfras !== null &&
      data?.singleFormData?.warehouseRej?.warehouseMHInfras !== undefined
    ) {
      setInitValue({
        pickingTrolley: {
          value: data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.pickingTrolley,
          whsremark: '',
        },
        hydraulicDockLevler: {
          value: data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.hydraulicDockLevler,
          whsremark: '',
        },
        batteryOperatedPalletTruck: {
          value:
            data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.batteryOperatedPalletTruck,
          whsremark: '',
        },
        handPalletTruck: {
          value: data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.handPalletTruck,
          whsremark: '',
        },
        electricStacker: {
          value: data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.electricStacker,
          whsremark: '',
        },
        shrinkAndStretchWrapMachine: {
          value:
            data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.shrinkAndStretchWrapMachine,
          whsremark: '',
        },
        pallets: {
          value: data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.pallets?.quantity,
          whsremark: '',
        },
        weighingMachine: {
          value:
            data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.weighingMachine?.validFrom,
          whsremark: '',
        },
        forkLifts: [
          {
            type: 'batteryoperatedup',
            forkLift: {
              value:
                data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.forkLiftsYes?.capacityTon,
              whsremark: '',
            },
          },
          {
            type: 'dieseloperated',
            forkLift: {
              value:
                data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.forkLiftsDieselYes
                  ?.capacityTon,
              whsremark: '',
            },
          },
        ],
        hydraCrane: {
          value: data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.hydraCrane?.loadCapacity,
          whsremark: '',
        },
        others: {
          value: data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.others,
          whsremark: '',
        },
      });

      setYesForm({
        palletYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.pallets?.quantity !== ''
            ? true
            : false,
        handPalletYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.handPalletTruck !== ''
            ? true
            : false,
        weighingMachineYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.weighingMachine?.validFrom !==
            ''
            ? true
            : false,
        pickingTrolleyYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.pickingTrolley !== ''
            ? true
            : false,
        hydraulicYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.hydraCrane?.loadCapacity !== ''
            ? true
            : false,
        batteryYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.batteryOperatedPalletTruck !==
            ''
            ? true
            : false,
        forkliftYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.forkLiftsYes?.capacityTon !== ''
            ? true
            : false,
        electricYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.electricStacker !== ''
            ? true
            : false,
        hydraYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.hydraCrane?.loadCapacity !== ''
            ? true
            : false,
        shrinkYes:
          data?.singleFormData?.warehouseRej?.warehouseMHInfras[0]?.shrinkAndStretchWrapMachine !==
            ''
            ? true
            : false,
      });
    } else {
      if (data?.singleFormData !== null && data?.singleFormData?.warehouseRej !== null) {
        if (data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks !== undefined) {
          if (
            Object.keys(data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks).length !== 0
          ) {
            // console.log(data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks, "warehosutiMHINFRA")
            setInitValue(data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks);
            setYesForm({
              palletYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks?.pallets?.value !== ''
                  ? true
                  : false,
              handPalletYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks?.handPalletTruck
                  ?.value !== ''
                  ? true
                  : false,
              weighingMachineYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks?.weighingMachine
                  ?.value !== ''
                  ? true
                  : false,
              pickingTrolleyYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks?.pickingTrolley
                  ?.value !== ''
                  ? true
                  : false,
              hydraulicYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks?.hydraulicDockLevler?.value !== '' ? true : false,
              batteryYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks
                  ?.batteryOperatedPalletTruck?.value !== ''
                  ? true
                  : false,
              forkliftYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks?.forkLifts[0]?.forkLift
                  ?.value !== ''
                  ? true
                  : false,
              electricYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks?.electricStacker
                  ?.value !== ''
                  ? true
                  : false,
              hydraYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks?.hydraCrane?.value !==
                  ''
                  ? true
                  : false,
              shrinkYes:
                data?.singleFormData?.warehouseRej?.warehouseMHInfrasRemarks
                  ?.shrinkAndStretchWrapMachine?.value !== ''
                  ? true
                  : false,
            });
          }
        }
      }
    }
  }, [data?.singleFormData]);

  const {
    palletYes,
    handPalletYes,
    weighingMachineYes,
    pickingTrolleyYes,
    hydraulicYes,
    batteryYes,
    forkliftYes,
    electricYes,
    hydraYes,
    shrinkYes,
  } = yesForm;

  const handleChange = (e) => {
    setYesForm({ ...yesForm, [e.target.name]: e.target.value === 'true' });
  };

  return (
    <>
      {/* Loader */}
      {data.isLoading ? (
        <CardLoader />
      ) : data.isError !== '' ? (
        <ErrorCard message={data.isError} />
      ) : (
        <>
          {data.addNewResponse.statusCode === 201 ? (
            <FormSuccess
              onClick={() => dispatch(changeWarehouseStatus())}
              message={data.addNewResponse.message}
            />
          ) : null}

          <div className='row align-items-center pb-3 mx-0 overflow-auto'>
            <div className='col-12'>
              <Formik
                enableReinitialize={true}
                initialValues={initValue}
                onSubmit={(fields) => {
                  const payload = {
                    data: {
                      fields: fields,
                    },
                    warehouseId: warehouseId,
                    slug: slug,
                  };
                  dispatch(updateWarehousependingDetails(payload));
                }}
                render={({ errors, status, onChange, touched, values }) => {
                  const fieldClass = (fieldName, isYes) => {
                    return (
                      'form-control bg-white px-4 mb-2' +
                      (isYes ? (errors[fieldName] && touched[fieldName] ? ' is-invalid' : '') : '')
                    );
                  };

                  return (
                    <Form>
                      <div className='row bg-white rounded mx-0 col-xxxl-11'>
                        <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                          <table className='table customTable'>
                            <tbody>
                              <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-2'>Pallets:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-3 mt-3 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={palletYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='palletYes'
                                      id='palletYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='palletYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!palletYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='palletYes'
                                      id='palletYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='palletYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p className='mb-2'>Qty:</p>
                                </td>
                                <td className=''>
                                  <Field
                                    name='pallets.value'
                                    type='number'
                                    disabled={!palletYes}
                                    className={
                                      'form-control bg-white px-4 mb-2' +
                                      (palletYes
                                        ? getIn(errors, 'pallets.quantity') &&
                                          getIn(touched, 'pallets.quantity')
                                          ? ' is-invalid'
                                          : ''
                                        : '')
                                    }
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='pallets.quantity'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                                <td className=''>
                                  <>
                                    <Field
                                      name='pallets.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                      disabled={true}
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-2'>Hand Pallet Truck(Hydraulic):</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-3 mt-3 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={handPalletYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='handPalletYes'
                                      id='handPalletYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='handPalletYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!handPalletYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='handPalletYes'
                                      id='handPalletYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='handPalletYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p className='mb-2'>Qty:</p>
                                </td>
                                <td className=''>
                                  <Field
                                    type='number'
                                    name='handPalletTruck.value'
                                    disabled={!handPalletYes}
                                    className={fieldClass('handPalletTruck', handPalletYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='handPalletTruck'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                                <td>
                                  <>
                                    <Field
                                      disabled
                                      name='handPalletTruck.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>

                              <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-2'>Weighing Machine:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mb-2 mt-2 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3 mt-1'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={weighingMachineYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='weighingMachineYes'
                                      id='weighingMachineYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='weighingMachineYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3 mt-1'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!weighingMachineYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='weighingMachineYes'
                                      id='weighingMachineYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='weighingMachineYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto'>
                                  <p className='mb-0'>Calliberated on:</p>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid Till
                                  </label>
                                  <Field
                                    name='weighingMachine.value'
                                    disabled={!weighingMachineYes}
                                    type='date'
                                    className={'form-control bg-white px-4 mb-2'}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                </td>
                                <td className='pb-4'>
                                  <Field
                                    disabled
                                    name='weighingMachine.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-2'>Picking Trolley:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-3 mt-3 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={pickingTrolleyYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='pickingTrolleyYes'
                                      id='pickingTrolleyYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='pickingTrolleyYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!pickingTrolleyYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='pickingTrolleyYes'
                                      id='pickingTrolleyYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='pickingTrolleyYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p className=''>Qty:</p>
                                </td>
                                <td className=''>
                                  <Field
                                    type='number'
                                    name='pickingTrolley.value'
                                    disabled={!pickingTrolleyYes}
                                    className={fieldClass('pickingTrolley', pickingTrolleyYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='pickingTrolley'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                                <td>
                                  <>
                                    <Field
                                      disabled
                                      name='pickingTrolley.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-2'>Hydraulic Dock Levler:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-3 mt-3 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={hydraulicYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='hydraulicYes'
                                      id='hydraulicYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='hydraulicYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!hydraulicYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='hydraulicYes'
                                      id='hydraulicYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='hydraulicYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p className='mb-2'>Qty:</p>
                                </td>
                                <td className=''>
                                  <Field
                                    type='number'
                                    name='hydraulicDockLevler.value'
                                    disabled={!hydraulicYes}
                                    className={fieldClass('hydraulicDockLevler', hydraulicYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='hydraulicDockLevler'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                                <td>
                                  <>
                                    <Field
                                      disabled
                                      name='hydraulicDockLevler.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-2'> Battery Operated Pallet Truck(BOPT)</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-3 mt-3 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={batteryYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='batteryYes'
                                      id='batteryYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='batteryYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!batteryYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='batteryYes'
                                      id='batteryYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='batteryYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p className='mb-2'>Qty:</p>
                                </td>
                                <td className=''>
                                  <Field
                                    type='number'
                                    name='batteryOperatedPalletTruck.value'
                                    disabled={!batteryYes}
                                    className={fieldClass('batteryOperatedPalletTruck', batteryYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='batteryOperatedPalletTruck'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                                <td>
                                  <>
                                    <Field
                                      disabled
                                      name='batteryOperatedPalletTruck.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>

                              <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-2'>Forklift (Battery Operated):</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-3 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={forkliftYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='forkliftYes'
                                      id='forkliftYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='forkliftYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!forkliftYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='forkliftYes'
                                      id='forkliftYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='forkliftYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto'>
                                  <p className='mb-0'> Capacity in ton:</p>
                                </td>
                                <td className=''>
                                  <Field
                                    type='number'
                                    name={`forkLifts[${0}].forkLift.value`}
                                    disabled={!forkliftYes}
                                    className={'form-control bg-white px-4 mb-2'}
                                    placeholder='Ton Capacity'
                                    readOnly={viewMood}
                                  />
                                </td>
                                <td>
                                  <>
                                    <Field
                                      disabled
                                      name={`forkLifts[${0}].forkLift.whsremark`}
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-2'>Electric Stacker:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-3 mt-3 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={electricYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='electricYes'
                                      id='electricYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='electricYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!electricYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='electricYes'
                                      id='electricYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='electricYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p className='mb-2'>Qty:</p>
                                </td>
                                <td className=''>
                                  <Field
                                    type='number'
                                    name='electricStacker.value'
                                    disabled={!electricYes}
                                    className={fieldClass('electricStacker', electricYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='electricStacker'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                                <td>
                                  <>
                                    <Field
                                      disabled
                                      name='electricStacker.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              {/* <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Hydra Crane:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-2 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3 mt-1'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={hydraYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='hydraYes'
                                      id='hydraYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='hydraYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3 mt-1'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!hydraYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='hydraYes'
                                      id='hydraYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='hydraYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto'>
                                  <p className='mb-0'>Load Capacity:</p>
                                </td>
                                <td className=''>
                                  <Field
                                    name='hydraCrane.value'
                                    as='select'
                                    className={
                                      'form-control custom-select bg-white px-4 common-select-deep-blue' +
                                      (hydraYes
                                        ? getIn(errors, 'hydraCrane.loadCapacity') &&
                                          getIn(touched, 'hydraCrane.loadCapacity')
                                          ? ' is-invalid'
                                          : ''
                                        : '')
                                    }
                                    id='exampleFormControlSelect1'
                                    disabled={viewMood || !hydraYes}
                                  >
                                    <option>Select</option>
                                    <option>10T</option>
                                    <option>15T</option>
                                    <option>20T</option>
                                    <option>others</option>
                                  </Field>
                                  <ErrorMessage
                                    name='hydraCrane.loadCapacity'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                                <td>
                                  <Field
                                    type='text'
                                    name='hydraCrane.whsremark'
                                    className='form-control bg-white px-4 mb-2'
                                    placeholder='Remarks'
                                    readOnly={viewMood}
                                    disabled={true}
                                  />
                                </td>
                              </tr> */}
                              <tr className='border'>
                                <td className=''>
                                  <p className='mb-0 pb-2'>Shrink / Stretch Wrap Machine:</p>
                                </td>
                                <td className='col-auto form-inline form-group form-inline py-4 mt-3 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={shrinkYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='shrinkYes'
                                      id='shrinkYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='shrinkYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      checked={!shrinkYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      name='shrinkYes'
                                      id='shrinkYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='shrinkYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto'>
                                  <p className='mb-2'>Qty:</p>
                                </td>
                                <td>
                                  <Field
                                    type='number'
                                    name='shrinkAndStretchWrapMachine.value'
                                    disabled={!shrinkYes}
                                    className={fieldClass('shrinkAndStretchWrapMachine', shrinkYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='shrinkAndStretchWrapMachine'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                                <td>
                                  <>
                                    <Field
                                      disabled
                                      name='shrinkAndStretchWrapMachine.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-5 pb-1'>Others:</p>
                                </td>
                                <td className='' colSpan='2'>
                                  <Field
                                    as='textarea'
                                    name='others.value'
                                    className={
                                      'form-control bg-white px-4 rounded-sm' +
                                      (errors.others && touched.others ? ' is-invalid' : '')
                                    }
                                    placeholder=''
                                    rows='3'
                                    readOnly={viewMood}
                                  ></Field>
                                  <ErrorMessage
                                    name='others'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                                <td className='' colSpan='1'></td>
                                <td className='' colSpan='1'>
                                  <>
                                    <Field
                                      disabled
                                      name='others.whsremark'
                                      type='text'
                                      className='form-control  px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className={`col-12 mt-4 ${viewMood ? 'd-none' : ''}`}>
                          <div className='row justify-content-end'>
                            {Object.keys(errors).length !== 0 ? (
                              <FormErrorCard message='Fill All Required Fields' />
                            ) : null}
                            {data.isError !== '' ? <FormErrorCard message={data.isError} /> : null}
                            <div className='col-auto'>
                              <button
                                type='submit'
                                disabled={data.isPending}
                                className='btn btn-deep-blue add-class remove-class'
                              >
                                Save
                                {data.isPending ? <Spinner animation='border' /> : null}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UpdateMHInfraForm;

import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import {
  consiousUpdateStorageSpaceNew,
  changeWarehouseStatus,
} from '../../../store/actions/vendor/warehouseList';
import './warehouseForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { CardLoader } from '../../helper/CustomLoader';
function UpdateStorageSpaceForm({ warehouseId, viewMood, slug, closeErrorMessage }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rackracksSlottedYes, setRackracksSlottedYes] = useState(true);
  const [rackracksSlottYes, setRackracksSlottYes] = useState(true);
  const data = useSelector((state) => state.WAREHOUSELIST);
  const floorTypesOptions = [
    // { value: '', label: 'Select Floor' },
    { value: 'Basement', label: 'Basement' },
    { value: 'Ground Floor', label: 'Ground Floor' },
    { value: '1st Floor', label: '1st Floor' },
    { value: '2nd Floor', label: '2nd Floor' },
  ];

  const [storeForm, setStoreForm] = useState({
    storageType: 'floorarea',
    noOfShift: '',
    palletsOnFloor: '',
    totalAvailableSpace: 0,
    floors: [
      {
        floorType: '',
        floorDimension: {
          dimension: 'SQFT',
          length: Number(),
          breath: Number(),
          height: Number(),
        },
        availableSpace: {
          length: Number(),
          breath: Number(),
          height: Number(),
        },
        unit: 'FEET',
      },
    ],
    racks: [
      {
        name: 'binsracks',
        rackInfo: {
          quantity: Number(),
          length: Number(),
          breath: Number(),
          height: Number(),
        },
      },
      {
        name: 'shelveracks',
        rackInfo: {
          quantity: Number(),
          length: Number(),
          breath: Number(),
          height: Number(),
        },
      },
    ],
    racksSlottedYes: true,
    racksShelveYes: true,
  });

  const storeSchema = Yup.object().shape({
    noOfShift: Yup.number().required('Required'),
    floors: Yup.array(
      Yup.object().shape({
        floorType: Yup.string().required('Required'),
        floorDimension: Yup.object().shape({
          length: Yup.number().required('Required'),
          breath: Yup.number().required('Required'),
          height: Yup.number().required('Required'),
        }),
      }),
    ),
    racks: Yup.array(
      Yup.object().shape({
        rackInfo: Yup.object().shape({
          ...(rackracksSlottedYes === true
            ? {
              quantity: Yup.number().required('quantity is required.'),
              length: Yup.number().required('length is required.'),
              breath: Yup.number().required('breath is required.'),
              height: Yup.number().required('height is required.'),
            }
            : {}),
          ...(rackracksSlottYes === true
            ? {
              quantity: Yup.number().required('quantity is required.'),
              length: Yup.number().required('length is required.'),
              breath: Yup.number().required('breath is required.'),
              height: Yup.number().required('height is required.'),
            }
            : {}),
        }),
      }),
    ),
  });

  const handleAddFloor = () => {
    var allItems = { ...storeForm };
    allItems.floors.push({
      floorType: '',
      floorDimension: {
        dimension: 'SQFT',
        length: 0,
        breath: 0,
        height: 0,
      },
      availableSpace: {
        length: 0,
        breath: 0,
        height: 0,
      },
      unit: 'FEET',
    });
    setStoreForm(allItems);
  };

  const handleChangeFloorType = (event, name, index) => {
    const updatedFloors = {
      ...storeForm,
    };
    updatedFloors.floors[index].floorType = event.value;
    setStoreForm(updatedFloors);
  };

  const handleDeleteFloor = (id) => {
    setStoreForm((prevState) => ({
      ...prevState,
      floors: prevState.floors.filter((_, i) => i !== id),
    }));
    // const updatedFloors = {
    //   ...storeForm
    // };
    // updatedFloors.floors.pop();
    // updatedFloors(updatedFloors)
    // setStoreForm(storeForm => storeForm.floors.slice(id, storeForm.floors.length - 1));
    // const newTaskArray = storeForm.floors.filter((items, index) => index !== id);
    // console.log(newTaskArray, "||newTaskArray||", storeForm.floors, "Hello abcd trigger")
    // setStoreForm({ ...storeForm.floors, floors: newTaskArray });
    // let as = updatedFloors.floors.filter((items, index) => index !== id)
    // // console.log(as, "||updatedFloors||", id)
    // setStoreForm({ floors: as });
  };

  useEffect(() => {
    if (warehouseId !== undefined && slug !== null) {
      if (
        data?.singleFormData?.warehouseRes?.warehouseStorageSpaces !== null &&
        data?.singleFormData?.warehouseRes?.warehouseStorageSpaces !== undefined
      ) {
        setStoreForm({ ...data?.singleFormData?.warehouseRes?.warehouseStorageSpaces[0] });
        setRackracksSlottedYes(
          data?.singleFormData?.warehouseRes?.warehouseStorageSpaces[0].racksSlottedYes,
        );
        setRackracksSlottYes(
          data?.singleFormData?.warehouseRes?.warehouseStorageSpaces[0].racksShelveYes,
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/concise/create-warehouse/${warehouseId}/warehouse-photo`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);

  return (
    <div className='row align-items-center pb-3 mx-0'>
      <div className='col-12'>
        {data.isLoading ? (
          <CardLoader />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={storeForm}
            validationSchema={storeSchema}
            onSubmit={(fields) => {
              // console
              // return false;
              let payloadData = {
                warehouseStorageSpaces: fields,
              };
              payloadData['warehouseId'] = warehouseId;
              payloadData['slug'] = 'storage-space';
              dispatch(consiousUpdateStorageSpaceNew(payloadData));
            }}
            render={(values, errors, setFieldValue) => {
              const fieldClass = (fieldName, isYes = true) => {
                return (
                  'form-control form-control-sm bg-white px-4 w-5' +
                  (isYes
                    ? values.errors[fieldName] && values.touched[fieldName]
                      ? ' is-invalid'
                      : ''
                    : '')
                );
              };

              const floorMultiRowValidation = (id, fieldname) => {
                if (values?.errors !== undefined && values?.errors[`floors`] !== undefined) {
                  if (values?.errors[`floors`][id]?.floorDimension !== undefined) {
                    if (values?.errors[`floors`][id]?.floorDimension[fieldname] !== undefined) {
                      return 'form-control form-control-sm bg-white px-4 w-5 is-invalid';
                    } else {
                      return 'form-control form-control-sm bg-white px-4 w-5';
                    }
                  } else {
                    return 'form-control form-control-sm bg-white px-4 w-5';
                  }
                } else {
                  return 'form-control form-control-sm bg-white px-4 w-5';
                }
              };

              const floorTypeValidation = (id, selected) => {
                if (values?.errors?.floors?.[id]?.floorType !== undefined) {
                  return ' w-60 mr-5 float-left is-invalid  border-radius';
                } else {
                  return ' w-60 mr-5 float-left  border-radius';
                }
              };

              const rackMultiRowValidation = (id, fieldname, status) => {
                console.log(id, fieldname, status, 'hello action trigger');
                if (values?.errors !== undefined && values?.errors[`racks`] !== undefined) {
                  // console.log(values?.errors[`racks`][id], "first condition crossed");
                  if (
                    values?.errors[`racks`][id]?.rackInfo[fieldname] !== undefined &&
                    status === true
                  ) {
                    console.log('Again validation shows');
                    return 'form-control bg-white px-4 is-invalid';
                  } else {
                    console.log('validation crossed');
                    return 'form-control bg-white px-4';
                  }
                } else {
                  console.log('validation crossed');
                  return 'form-control bg-white px-4';
                }
              };

              const totalLength = storeForm.floors.reduce(function (acc, current) {
                return acc + current?.floorDimension.length;
              }, 0);

              const totalBreath = storeForm.floors.reduce(function (acc, current) {
                return acc + current?.floorDimension.breath;
              }, 0);

              return (
                <>
                  <Form>
                    {/* {JSON.stringify(values.errors)} */}
                    <div
                      className={`${'row bg-white rounded mx-0 col-xxxl-11 justify-content-center'}`}
                    >
                      <div className='form-group form-inline w-70 mb-3 mt-2'>
                        <label htmlFor='staticEmail' className='mb-1 mr-3'>
                          Storage Type:
                        </label>
                        <div className='form-check common-radio-deep-blue mx-3'>
                          <Field
                            name='storageType'
                            className='common-radio-deep-blue-input'
                            type='radio'
                            id='floor-area'
                            value='floorarea'
                            hidden=''
                          />
                          <label className='common-radio-deep-blue-label pl-4' htmlFor='floor-area'>
                            Floor Area
                          </label>
                        </div>
                        <div className='form-check common-radio-deep-blue mx-3'>
                          <Field
                            name='storageType'
                            className='common-radio-deep-blue-input'
                            type='radio'
                            id='pallet-position'
                            value='palletposition'
                            hidden=''
                          />
                          <label
                            className='common-radio-deep-blue-label pl-4'
                            htmlFor='pallet-position'
                          >
                            Pallet Position
                          </label>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between h3 w-70'>
                        Floor Plan{' '}
                        <button type='button' class='btn btn-secondary' onClick={handleAddFloor}>
                          Add Floor
                        </button>
                      </div>
                      {/* {JSON.stringify(storeForm?.floors)} */}
                      {storeForm?.floors.map((field, index) => {
                        return (
                          <div className='main w-70 border my-3' key={index}>
                            <div>
                              {index !== 0 ? (
                                <span
                                  onClick={() => handleDeleteFloor(index)}
                                  className='ml-4 cursors float-right my-2'
                                >
                                  <DeleteIcon />
                                </span>
                              ) : (
                                <></>
                              )}
                              <label htmlFor='' className='px-1 fontSize'>
                                Floor *
                              </label>

                              <Select
                                value={{
                                  value:
                                    values.values.floors[index]?.floorType === ''
                                      ? ''
                                      : values.values.floors[index]?.floorType,
                                  label:
                                    values.values.floors[index]?.floorType === '' ||
                                      values.values.floors[index]?.floorType === undefined
                                      ? 'Select Floor'
                                      : values.values.floors[index]?.floorType,
                                }}
                                className={floorTypeValidation(`${index}`)}
                                options={floorTypesOptions}
                                name={`floors[${index}].floorType`}
                                onChange={(event) => {
                                  handleChangeFloorType(event, 'floorType', index);
                                  values.setFieldValue(`floors[${index}].floorType`, event.value);
                                }}
                              />

                              <ErrorMessage
                                name={`floors[${index}].floorType`}
                                component='div'
                                className='invalid-feedback float-left'
                              />
                            </div>
                            <div className='d-inline-flex'>
                              <span className='py-3 pr-4'>
                                <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                  Length *
                                </label>
                                <Field
                                  name={`floors[${index}].floorDimension.length`}
                                  value={storeForm.floors[index].floorDimension.length}
                                  type='number'
                                  placeholder='Length'
                                  className={floorMultiRowValidation(`${index}`, 'length')}
                                  onChange={(event) => {
                                    const storeFormSW = { ...storeForm };
                                    storeFormSW.floors[index].floorDimension.length = parseInt(
                                      event.target.value,
                                    );
                                    setStoreForm(storeFormSW);
                                    values.setFieldValue(
                                      `floors[${index}].floorDimension.length`,
                                      event.target.value,
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`floors[${index}].floorDimension.length`}
                                  component='div'
                                  className='invalid-feedback'
                                />
                              </span>
                              <span className='py-3 px-4'>
                                <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                  Breadth *
                                </label>
                                <Field
                                  type='number'
                                  name={`floors[${index}].floorDimension.breath`}
                                  value={storeForm.floors[index].floorDimension.breath}
                                  placeholder='Breadth'
                                  className={floorMultiRowValidation(`${index}`, 'breath')}
                                  onChange={(event) => {
                                    const storeFormSW = { ...storeForm };
                                    storeFormSW.floors[index].floorDimension.breath = parseInt(
                                      event.target.value,
                                    );
                                    setStoreForm(storeFormSW);
                                    values.setFieldValue(
                                      `floors[${index}].floorDimension.breath`,
                                      event.target.value,
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`floors[${index}].floorDimension.breath`}
                                  component='div'
                                  className='invalid-feedback'
                                />
                              </span>
                              <span className='py-3 px-4'>
                                <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                  Height *
                                </label>
                                <Field
                                  name={`floors[${index}].floorDimension.height`}
                                  value={storeForm.floors[index].floorDimension.height}
                                  type='number'
                                  placeholder='Height'
                                  className={floorMultiRowValidation(`${index}`, 'height')}
                                  onChange={(event) => {
                                    const storeFormSW = { ...storeForm };
                                    storeFormSW.floors[index].floorDimension.height = parseInt(
                                      event.target.value,
                                    );
                                    setStoreForm(storeFormSW);
                                    values.setFieldValue(
                                      `floors[${index}].floorDimension.height`,
                                      event.target.value,
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`floors[${index}].floorDimension.height`}
                                  component='div'
                                  className='invalid-feedback'
                                />
                              </span>
                            </div>
                            <div>
                              <label htmlFor='' className='px-1 fontSize'>
                                Available Space
                              </label>
                            </div>
                            <div className='d-inline-flex'>
                              <span className='pr-4'>
                                <Field
                                  name={`floors[${index}].availableSpace.length`}
                                  value={storeForm.floors[index].availableSpace.length}
                                  type='number'
                                  placeholder='Length'
                                  className={'form-control bg-white px-4 mb-3 mt-1'}
                                  onChange={(event) => {
                                    const storeFormSW = { ...storeForm };
                                    storeFormSW.floors[index].availableSpace.length = parseInt(
                                      event.target.value,
                                    );
                                    setStoreForm(storeFormSW);
                                    values.setFieldValue(
                                      `floors[${index}].availableSpace.length`,
                                      event.target.value,
                                    );
                                  }}
                                />
                              </span>
                              <span className='px-4'>
                                <Field
                                  name={`floors[${index}].availableSpace.breath`}
                                  value={storeForm.floors[index].availableSpace.breath}
                                  type='number'
                                  placeholder='Breath'
                                  className={'form-control bg-white px-4 mb-3 mt-1'}
                                  onChange={(event) => {
                                    const storeFormSW = { ...storeForm };
                                    storeFormSW.floors[index].availableSpace.breath = parseInt(
                                      event.target.value,
                                    );
                                    setStoreForm(storeFormSW);
                                    values.setFieldValue(
                                      `floors[${index}].availableSpace.breath`,
                                      event.target.value,
                                    );
                                  }}
                                />
                              </span>
                              <span className='px-4'>
                                <Field
                                  name={`floors[${index}].availableSpace.height`}
                                  value={storeForm.floors[index].availableSpace.height}
                                  type='number'
                                  placeholder='Height'
                                  className={'form-control bg-white px-4 mb-3 mt-1'}
                                  onChange={(event) => {
                                    const storeFormSW = { ...storeForm };
                                    storeFormSW.floors[index].availableSpace.height = parseInt(
                                      event.target.value,
                                    );
                                    setStoreForm(storeFormSW);
                                    values.setFieldValue(
                                      `floors[${index}].availableSpace.height`,
                                      event.target.value,
                                    );
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        );
                      })}
                      <div className='mb-3 w-70'>
                        <label className='mb-2'>Total Area(sqft):</label>
                        <div className='d-flex'>
                          <Field
                            className='form-control bg-white px-4 mr-3 col-2'
                            name={`totalAvailableSpace`}
                            value={totalLength * totalBreath}
                            disabled
                          />

                          {/* <Field
                            className='form-control bg-white px-4 mx-3 col-2'
                            value={0}
                            disabled
                          />
                          <Field
                            className='form-control bg-white px-4 mx-3 col-2'
                            value={0}
                            disabled
                          />
                          <Field
                            className='form-control bg-white px-4 mx-3 col-2'
                            value={0}
                            disabled
                          /> */}
                        </div>
                        <div></div>
                      </div>
                      <div className='my-2 w-70'>
                        <label className='mb-2'>Pallets on Floor</label>
                        <Field
                          className='form-control bg-white px-4 w-50'
                          name={`palletsOnFloor`}
                          type="number"
                          // onChange={(event) =>
                          //   handleChangheFloorKeys(event.target.value, `palletsOnFloor`)
                          // }
                          readOnly={viewMood}
                        />
                      </div>
                      <div className='my-3 border w-70'>
                        <label className='mb-2'>Racks(Slotted Angle) No of Bins:</label>
                        <div className='d-flex'>
                          <div className='form-check common-radio-deep-blue'>
                            <Field
                              name={`racksSlottedYes`}
                              onChange={(e) => {
                                values.setFieldValue('racksSlottedYes', true);
                                setRackracksSlottedYes(true);
                              }}
                              checked={values.values.racksSlottedYes}
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='racksSlotted'
                              value={true}
                              hidden=''
                              disabled={viewMood}
                            />
                            <label
                              className='common-radio-deep-blue-label pl-1'
                              htmlFor='racksSlotted'
                            >
                              Yes
                            </label>
                          </div>
                          <div className='form-check common-radio-deep-blue mx-3'>
                            <Field
                              name={`racksSlottedYes`}
                              // onChange={(e) => handleYes(e, false, 'racksSlottedYes')}
                              onChange={(e) => {
                                values.setFieldValue('racksSlottedYes', false);
                                setRackracksSlottedYes(false);
                              }}
                              checked={!values.values.racksSlottedYes}
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='racksSlotted1'
                              value={false}
                              hidden=''
                              disabled={viewMood}
                            />
                            <label
                              className='common-radio-deep-blue-label pl-1'
                              htmlFor='racksSlotted1'
                            >
                              No
                            </label>
                          </div>
                          <span className='py-3 pr-4'>
                            <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                              Quantity *
                            </label>
                            <Field
                              type='number'
                              name={`racks[0].rackInfo.quantity`}
                              className={rackMultiRowValidation(
                                `${0}`,
                                'quantity',
                                values.values.racksSlottedYes,
                              )}
                              // onChange={(event) =>
                              //   handleChangeRacks('quantity', event.target.value, 0)
                              // }
                              placeholder={'quantity'}
                              disabled={values.values.racksSlottedYes === true ? false : true}
                            />
                            <ErrorMessage
                              name={`racks[0].rackInfo.quantity`}
                              component='div'
                              className='invalid-feedback'
                            />
                          </span>
                          <span className='py-3 pr-4'>
                            <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                              Length *
                            </label>
                            <Field
                              type='number'
                              name={`racks[0].rackInfo.length`}
                              className={rackMultiRowValidation(
                                `${0}`,
                                'length',
                                values.values.racksSlottedYes,
                              )}
                              placeholder={'length'}
                              // onChange={(event) =>
                              //   handleChangeRacks('length', event.target.value, 0)
                              // }
                              disabled={values.values.racksSlottedYes === true ? false : true}
                            />
                            <ErrorMessage
                              name={`racks[0].rackInfo.length`}
                              component='div'
                              className='invalid-feedback'
                            />
                          </span>
                          <span className='py-3 pr-4'>
                            <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                              Breath *
                            </label>
                            <Field
                              type='number'
                              name={`racks[0].rackInfo.breath`}
                              className={rackMultiRowValidation(
                                `${0}`,
                                'breath',
                                values.values.racksSlottedYes,
                              )}
                              placeholder={'breath'}
                              // onChange={(event) =>
                              //   handleChangeRacks('breath', event.target.value, 0)
                              // }
                              disabled={values.values.racksSlottedYes === true ? false : true}
                            />
                            <ErrorMessage
                              name={`racks[0].rackInfo.breath`}
                              component='div'
                              className='invalid-feedback'
                            />
                          </span>
                          <span className='py-3 pr-4'>
                            <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                              Height *
                            </label>
                            <Field
                              type='number'
                              name={`racks[0].rackInfo.height`}
                              className={rackMultiRowValidation(
                                `${0}`,
                                'height',
                                values.values.racksSlottedYes,
                              )}
                              placeholder={'height'}
                              // onChange={(event) =>
                              //   handleChangeRacks('height', event.target.value, 0)
                              // }
                              disabled={values.values.racksSlottedYes === true ? false : true}
                            />
                            <ErrorMessage
                              name={`racks[0].rackInfo.height`}
                              component='div'
                              className='invalid-feedback'
                            />
                          </span>
                        </div>
                      </div>
                      {/* {JSON.stringify(values.values)} */}
                      <div className='my-3 border w-70'>
                        <label className='mb-2'>Racks(Shelve Racks) No of Shelve:</label>
                        <div className='d-flex'>
                          <div className='form-check common-radio-deep-blue'>
                            <Field
                              name={`racksShelveYes`}
                              checked={values.values.racksShelveYes}
                              onChange={(e) => {
                                values.setFieldValue('racksShelveYes', true);
                                setRackracksSlottYes(true);
                              }}
                              value={true}
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='racksShelveYes'
                            />
                            <label
                              className='common-radio-deep-blue-label pl-1'
                              htmlFor='racksShelveYes'
                            >
                              Yes
                            </label>
                          </div>
                          <div className='form-check common-radio-deep-blue mx-3'>
                            <Field
                              name={`racksShelveYes`}
                              onChange={(e) => {
                                console.log(e, values.values);
                                values.setFieldValue('racksShelveYes', false);
                                setRackracksSlottYes(false);
                              }}
                              checked={!values.values.racksShelveYes}
                              value={false}
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='racksShelveYes1'
                            />
                            <label
                              className='common-radio-deep-blue-label pl-1'
                              htmlFor='racksShelveYes1'
                            >
                              No
                            </label>
                          </div>
                          <span className='py-3 pr-4'>
                            <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                              Quantity *
                            </label>
                            <Field
                              type='number'
                              name={`racks[1].rackInfo.quantity`}
                              className={rackMultiRowValidation(
                                `${1}`,
                                'quantity',
                                values.values.racksShelveYes,
                              )}
                              placeholder={'quantity'}
                              // onChange={(event) =>
                              //   handleChangeRacks('quantity', event.target.value, 1)
                              // }
                              disabled={values.values.racksShelveYes === true ? false : true}
                            />
                            <ErrorMessage
                              name={`racks[1].rackInfo.quantity`}
                              component='div'
                              className='invalid-feedback'
                            />
                          </span>
                          <span className='py-3 pr-4'>
                            <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                              Length *
                            </label>
                            <Field
                              type='number'
                              name={`racks[1].rackInfo.length`}
                              className={rackMultiRowValidation(
                                `${1}`,
                                'length',
                                values.values.racksShelveYes,
                              )}
                              placeholder={'length'}
                              // onChange={(event) =>
                              //   handleChangeRacks('length', event.target.value, 1)
                              // }
                              disabled={values.values.racksShelveYes === true ? false : true}
                            />
                            <ErrorMessage
                              name={`racks[1].rackInfo.length`}
                              component='div'
                              className='invalid-feedback'
                            />
                          </span>
                          <span className='py-3 pr-4'>
                            <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                              Breath *
                            </label>
                            <Field
                              type='number'
                              name={`racks[1].rackInfo.breath`}
                              className={rackMultiRowValidation(
                                `${1}`,
                                'breath',
                                values.values.racksShelveYes,
                              )}
                              placeholder={'breath'}
                              // onChange={(event) =>
                              //   handleChangeRacks('breath', event.target.value, 1)
                              // }
                              disabled={values.values.racksShelveYes === true ? false : true}
                            />
                            <ErrorMessage
                              name={`racks[1].rackInfo.breath`}
                              component='div'
                              className='invalid-feedback'
                            />
                          </span>
                          <span className='py-3 pr-4'>
                            <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                              Height *
                            </label>
                            <Field
                              type='number'
                              name={`racks[1].rackInfo.height`}
                              className={rackMultiRowValidation(
                                `${1}`,
                                'height',
                                values.values.racksShelveYes,
                              )}
                              placeholder={'height'}
                              // onChange={(event) =>
                              //   handleChangeRacks('height', event.target.value, 1)
                              // }
                              disabled={values.values.racksShelveYes === true ? false : true}
                            />
                            <ErrorMessage
                              name={`racks[1].rackInfo.height`}
                              component='div'
                              className='invalid-feedback'
                            />
                          </span>
                        </div>
                      </div>
                      <div className='my-2 w-70'>
                        <label className='mb-2'>No of Shifts</label>
                        <Field
                          name={`noOfShift`}
                          placeholder='Enter No Of Shifts'
                          type="number"
                          onChange={(event) =>
                            values.setFieldValue('noOfShift', event.target.value)
                          }
                          className={fieldClass('noOfShift')}
                        />
                        <ErrorMessage
                          name={`noOfShift`}
                          component='div'
                          className='invalid-feedback'
                        />
                      </div>

                      <div className={`${'row justify-content-end'}`}>
                        <div className='col-auto'>
                          <button
                            type='submit'
                            className='btn btn-deep-blue add-className remove-className my-2'
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      {console.log('debugging')}
                      {console.log(values.values)}
                    </div>
                  </Form>
                </>
              );
            }}
          />
        )}
      </div>
    </div>
  );
}

export default UpdateStorageSpaceForm;

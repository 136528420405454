import React, { useState, useEffect, createContext } from 'react';
import {
  changeWarehouseStatus,
  // updatePhoto,'
  isLoading,
  updatePhotoNew,
  updateWarehousependingDetails,
} from '../../../store/actions/vendor/warehouseList';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosauth from '../../../api/axios-auth';
import { CardLoader } from '../../helper/CustomLoader';
import { useHistory } from 'react-router-dom';
export const UserContext = createContext();
const WarehousePhotoForm = ({ warehouseId, viewMood, slug, closeErrorMessage }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSELIST);
  const history = useHistory();
  const [imgId, setImgId] = useState(null);
  const [coverError, setCoverError] = useState('');
  const [indoorError, setIndoorError] = useState('');
  const [outdoorError, setOutdoorError] = useState('');
  // const [coverView, setCoverView] = useState(null);
  // const [coverViewDoor, setCoverViewDoor] = useState([]);
  // const [indoorViewDoor, setIndoorViewDoor] = useState([]);
  // const [outdoorViewDoor, setOutdoorViewDoor] = useState([]);
  const [inDoorView, setInDoorView] = useState([1]);
  const [outDoorView, setOutDoorView] = useState([1]);
  const [inDoor, setInDoor] = useState([]);
  const [outDoor, setOutDoor] = useState([]);

  const [indoorPhoto, setindoorPhoto] = useState([]);
  const [outdoorPhoto, setoutdoorPhoto] = useState([]);

  const [Photo, setPhoto] = useState({
    cover: '',
    photo: {
      indoor: [],
      outdoor: [],
    },
  });

  const [remarkForm, setRemarkForm] = useState({
    whsCoverImage: {
      type: '',
      coverImg: {
        value: '',
        whsremark: '',
      },
    },
    whsIndoors: {
      type: '',
      indoor: [
        {
          value: [],
          whsremark: '',
        },
      ],
    },
    whsOutdoors: {
      type: '',
      outdoor: [
        {
          value: [],
          whsremark: '',
        },
        {
          value: '',
          whsremark: '',
        },
      ],
    },
  });

  // const indoorPhotoArr = [
  //   'https://www.everysteph.com/wp-content/uploads/2018/08/headshot-1024x1024.jpg.webp',
  // ];

  // const outdoorPhotoArr = [
  //   'https://www.everysteph.com/wp-content/uploads/2018/08/headshot-1024x1024.jpg.webp',
  // ];

  // const cover =
  //   'https://t3.ftcdn.net/jpg/01/79/91/78/360_F_179917813_5wzagab80xZ8QOCE9QQsLNsFrtLh3EEy.jpg';

  useEffect(() => {
    if (
      data?.singleFormData?.remarks === 0 &&
      data?.singleFormData?.warehouseRej?.warehouseImages !== null &&
      data?.singleFormData?.warehouseRej?.warehouseImages !== undefined
    ) {
      setInDoorView(
        data?.singleFormData?.warehouseRej?.warehouseImages[0]?.photo?.indoor?.length > 0
          ? [
              ...Array(
                data?.singleFormData?.warehouseRej?.warehouseImages[0]?.photo?.indoor?.length,
              ),
            ]
          : [0],
      );

      setOutDoorView(
        data?.singleFormData?.warehouseRej?.warehouseImages[0]?.photo?.outdoor?.length > 0
          ? [
              ...Array(
                data?.singleFormData?.warehouseRej?.warehouseImages[0]?.photo?.outdoor?.length,
              ),
            ]
          : [0],
      );

      setRemarkForm({
        whsCoverImage: {
          type: '',
          coverImg: {
            value: data?.singleFormData?.warehouseRej?.warehouseImages[0]?.cover,
            whsremark: '',
          },
        },
        whsIndoors: {
          type: '',
          indoor: [
            {
              value: [...data?.singleFormData?.warehouseRej?.warehouseImages[0]?.photo?.indoor],
              whsremark: '',
            },
          ],
        },
        whsOutdoors: {
          type: '',
          outdoor: [
            {
              value: [...data?.singleFormData?.warehouseRej?.warehouseImages[0]?.photo?.outdoor],
              whsremark: '',
            },
            {
              value: '',
              whsremark: '',
            },
          ],
        },
      });
    } else {
      console.log('I am not in group ');
      if (
        data?.singleFormData !== null &&
        data?.singleFormData !== undefined &&
        data?.singleFormData?.warehouseRej !== null
      ) {
        console.log('hey i am running', data?.singleFormData);
        if (data?.singleFormData?.warehouseRej?.warehouseImagesRemarks !== undefined) {
          console.log('hey i am running one');
          console.log(data?.singleFormData?.warehouseImagesRemarks);
          setInDoorView(
            data?.singleFormData?.warehouseRej?.warehouseImagesRemarks?.whsIndoors?.indoor[0]?.value
              ?.length > 0
              ? [
                  ...Array(
                    data?.singleFormData?.warehouseRej?.warehouseImagesRemarks?.whsIndoors
                      ?.indoor[0]?.value.length,
                  ),
                ]
              : [0],
          );

          setOutDoorView(
            data?.singleFormData?.warehouseRej?.warehouseImagesRemarks?.whsOutdoors?.outdoor[0]
              ?.value?.length > 0
              ? [
                  ...Array(
                    data?.singleFormData?.warehouseRej?.warehouseImagesRemarks?.whsOutdoors
                      ?.outdoor[0]?.value?.length,
                  ),
                ]
              : [0],
          );
          if (
            Object.keys(data?.singleFormData?.warehouseRej?.warehouseImagesRemarks).length !== 0
          ) {
            setRemarkForm(data?.singleFormData?.warehouseRej?.warehouseImagesRemarks);
          }
        }
      }
    }
  }, [data?.singleFormData]);

  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/update-warehouse-rejected/${warehouseId}/form-80`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);

  const coverHandle = (e) => {
    // setCoverViewDoor([e.target.files[0]]);
    const dataCover = new FormData();
    dataCover.append('image', e.target.files[0]);
    dataCover.append('type', 'coverImage');
    dataCover.append('warehouse', warehouseId);
    dataCover.append('warehouseImage', imgId);
    dispatch(isLoading(true));
    axiosauth
      .post('/api/v1/warehouseimage', dataCover)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.statusCode === 200) {
          setCoverError('');
          // setCoverView(res.data);
          setRemarkForm((prevState) => ({
            ...prevState,
            whsCoverImage: {
              ...prevState.whsCoverImage,
              coverImg: {
                ...prevState.whsCoverImage.coverImg,
                value: res.data, // Update the value with the uploaded image data
              },
            },
          }));

          dispatch(isLoading(false));
        } else {
          console.log('LandlordFile Action Error');
          dispatch(isLoading(false));
        }
      })
      .catch((error) => {
        console.log('here', error);
        dispatch(isLoading(false));
      });
  };

  const inDoorHandle = (e, index) => {
    const updatedArray = { ...inDoor };
    updatedArray[index] = e.target.files[0];
    // setIndoorViewDoor([e.target.files[0]]);
    setInDoor(updatedArray);
    const dataInDoor = new FormData();
    dataInDoor.append('image', e.target.files[0]);
    dataInDoor.append('type', 'indoor');
    dataInDoor.append('warehouseImage', imgId);
    dataInDoor.append('warehouse', warehouseId);
    dispatch(isLoading(true));
    axiosauth
      .post('/api/v1/warehouseimage', dataInDoor)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.statusCode === 200) {
          setIndoorError('');

          //      whsOutdoors: {
          // type: '',
          // outdoor: [
          //   {
          //     value: [],

          setRemarkForm((prevState) => {
            const updatedIndoorPhotos = [...prevState.whsIndoors.indoor[0].value];
            updatedIndoorPhotos[index] = res.data;
            return {
              ...prevState,
              whsIndoors: {
                // ...prevState.whsIndoors,
                indoor: [
                  {
                    // ...prevState.whsIndoors.indoor,
                    value: updatedIndoorPhotos,
                  },
                ],
              },
            };
          });
          dispatch(isLoading(false));
        } else {
          console.log('LandlordFile Action Error');
          dispatch(isLoading(false));
        }
      })
      .catch((error) => {
        console.log('here', error);
        dispatch(isLoading(false));
      });
  };

  const outDoorHandle = (e, index) => {
    const updatedArray = { ...outDoor };
    updatedArray[index] = e.target.files[0];
    setOutDoor(updatedArray);
    // setOutdoorViewDoor([e.target.files[0]]);
    const dataOutdoor = new FormData();
    dataOutdoor.append('image', e.target.files[0]);
    dataOutdoor.append('type', 'outdoor');
    dataOutdoor.append('warehouseImage', imgId);
    dataOutdoor.append('warehouse', warehouseId);
    dispatch(isLoading(true));
    axiosauth
      .post('/api/v1/warehouseimage', dataOutdoor)
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.statusCode === 200) {
          setOutdoorError('');
          // setoutdoorPhoto([...outdoorPhoto, res.data]);
          // setPhoto((prevState) => ({
          //   ...prevState,
          //   photo: {
          //     ...prevState.photo,
          //     outdoor: [...prevState.photo.outdoor, res.data],
          //   },
          // }));

          setRemarkForm((prevState) => {
            const updatedIndoorPhotos = [...prevState.whsOutdoors.outdoor[0].value];
            updatedIndoorPhotos[index] = res.data;
            return {
              ...prevState,
              whsOutdoors: {
                // ...prevState.whsOutdoors,
                outdoor: [
                  // ...prevState.whsOutdoors.outdoor,
                  {
                    value: updatedIndoorPhotos,
                  }, // Update the value with the uploaded image data
                ],
              },
            };
          });

          dispatch(isLoading(false));
        } else {
          console.log('LandlordFile Action Error');
          isLoading(false);
        }
      })
      .catch((error) => {
        console.log('here', error);
        isLoading(false);
      });
  };

  const handleDeletOutDoorView = (id) => {
    const updatedOutdoorImage = remarkForm?.whsOutdoors?.outdoor[0]?.value?.filter(
      (_, index) => index !== id,
    );
    setRemarkForm((prevState) => {
      return {
        ...prevState,
        whsOutdoors: {
          // ...prevState.whsOutdoors,
          outdoor: [
            // ...prevState.whsOutdoors.outdoor,
            {
              value: updatedOutdoorImage,
            }, // Update the value with the uploaded image data
          ],
        },
      };
    });
    console.log(updatedOutdoorImage);
    const outDoorViewVal = outDoorView.filter((_, index) => index !== id);
    setOutDoorView(outDoorViewVal);
  };

  const handleDeletInDoorView = (id) => {
    const updatedOutdoorImage = remarkForm?.whsIndoors?.indoor[0]?.value?.filter(
      (_, index) => index !== id,
    );
    setRemarkForm((prevState) => {
      return {
        ...prevState,
        whsIndoors: {
          // ...prevState.whsIndoors,
          indoor: [
            {
              // ...prevState.whsIndoors.indoor,
              value: updatedOutdoorImage,
            },
          ],
        },
      };
    });
    console.log(updatedOutdoorImage);
    const inDoorViewVal = inDoorView.filter((_, index) => index !== id);
    setInDoorView(inDoorViewVal);
  };

  let enableCheckImage = (e) => {
    e.preventDefault();

    // if (
    //   inDoorView.length !== Photo?.photo?.indoor?.filter((elem) => elem.length > 0).length &&
    //   outDoorView.length !== Photo?.photo?.outdoor?.filter((elem) => elem.length > 0).length
    // ) {
    //   setIndoorError('Please Choose Indoor Photo');
    //   setOutdoorError('Please Choose Outdoor Photo');
    //   return;
    // }
    // if (
    //   inDoorView.length !== Photo?.photo?.indoor?.filter((elem) => elem.length > 0).length ||
    //   outDoorView.length !== Photo?.photo?.outdoor?.filter((elem) => elem.length > 0).length
    // ) {
    //   if (inDoorView.length !== Photo?.photo?.indoor?.filter((elem) => elem.length > 0).length) {
    //     setIndoorError('Please Choose Indoor Photo');
    //     return;
    //   }
    //   if (outDoorView.length !== Photo?.photo?.outdoor?.filter((elem) => elem.length > 0).length) {
    //     setOutdoorError('Please Choose Outdoor Photo');
    //     return;
    //   }
    // }

    /*indoor outdoor logic*/

    // if (Photo.cover === '') {
    //   setCoverError('Please choose Cover Image');
    //   return;
    // }

    // if (Photo.photo.indoor.length === 0) {
    //   alert('Indoor empty');
    //   setIndoorError('Please choose Indoor Image');
    //   return;
    // }
    // if (Photo.photo.outdoor.length === 0) {
    //   alert('Outdoor empty');
    //   setOutdoorError('Please choose  Outdoor Image');
    //   return;
    // }
    // slug, warehouseId;
    // console.log('hello i am running');
    // let updatePhotoData = {
    //   files: {
    //     ...remarkForm,
    //   },
    //   warehouseId: warehouseId,
    //   slug: slug,
    // };
    // console.log(updatePhotoData);
    // dispatch(updatePhotoNew(updatePhotoData));
    console.log('hey i am runningh');
    let payloadData = {
      data: {
        fields: remarkForm,
      },
      warehouseId: warehouseId,
      slug: slug,
    };
    dispatch(updateWarehousependingDetails(payloadData));
  };
  return (
    <>
      {data?.isLoading ? (
        <CardLoader />
      ) : (
        <div className='row align-items-center pb-3 mx-0'>
          <div className='col-12'>
            <form onSubmit={(e) => enableCheckImage(e)}>
              <div className='row bg-white rounded mx-0 col-xxxl-11'>
                <div className='col-12'>
                  <h5 className='py-3 mb-3 border-bottom'>Warehouse Cover Photo</h5>
                </div>
                <div className='col-12'>
                  <div className='cards'>
                    <div className='col-xl-6 float-sm-left'>
                      <div className='img-holder size-200px'>
                        <img
                          className='w-100 h-100 img-fluid rounded overflow-hideen'
                          id='imageResult'
                          src='/assets/images/upload-bg.png'
                          alt=''
                        />
                      </div>
                      <div className='card-img-overlay size-200px d-flex justify-content-center align-items-center'>
                        <div className='card-text'>
                          <input
                            onChange={(e) => coverHandle(e)}
                            id='upload-image-preview1'
                            type='file'
                            className='form-control border-0'
                            hidden
                            disabled={viewMood}
                          />
                          <label
                            id='upload-label'
                            htmlFor='upload-image-preview1'
                            className='font-weight-light text-muted'
                          ></label>
                          <div className='input-group-append'>
                            <label
                              htmlFor='upload-image-preview1'
                              className='btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4'
                            >
                              <div>
                                <img src='/assets/images/icons/upload-icon-deep-blue.png' alt='' />
                              </div>
                              Upload image
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='text-danger'>{coverError}</div>
                    </div>
                    <div className='col-xl-3 float-sm-right'>
                      {remarkForm?.whsCoverImage?.coverImg?.value ? (
                        <img
                          className='w-100 h-100 img-fluid rounded overflow-hideen'
                          id='imageResult'
                          src={remarkForm?.whsCoverImage?.coverImg?.value}
                          alt=''
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    {/*End */}
                  </div>
                </div>
                <div className='col-auto mw-150px mt-2 ml-2'>
                  <input
                    disabled
                    type='text'
                    className='form-control bg-white px-4 mb-2 mr-4'
                    placeholder='Remarks'
                    value={
                      remarkForm?.whsCoverImage?.coverImg?.whsremark !== ''
                        ? remarkForm?.whsCoverImage?.coverImg?.whsremark
                        : null
                    }
                  />
                </div>
              </div>

              {/* ########################################## */}

              <div className='row bg-white pt-3 rounded mx-0 col-xxxl-11'>
                <div className='col-12'>
                  <h5 className='py-3 mb-3 border-bottom'>Warehouse Indoor Photos</h5>
                </div>
                {inDoorView !== undefined &&
                  inDoorView.map((c, index) => {
                    return (
                      <div key={index} className='col-xl-12 col-lg-12 col-sm-6 mb-2'>
                        <div className='cards'>
                          <div className='col-xl-6 float-sm-left'>
                            <div className='img-holder size-200px'>
                              <img
                                className='w-100 h-100 img-fluid rounded overflow-hideen'
                                id='imageResult'
                                src='/assets/images/upload-bg.png'
                                alt=''
                              />
                            </div>
                            <div className='card-img-overlay size-200px d-flex justify-content-center align-items-center'>
                              <div className='card-text'>
                                <input
                                  type='file'
                                  onChange={(e) => inDoorHandle(e, index)}
                                  className='form-control border-0'
                                  id={`upload-image-preview115${index}`}
                                  hidden
                                  disabled={viewMood}
                                />
                                <label
                                  id='upload-label'
                                  htmlFor={`upload-image-preview115${index}`}
                                  className='font-weight-light text-muted'
                                ></label>
                                <div className='input-group-append'>
                                  <label
                                    htmlFor={`upload-image-preview115${index}`}
                                    className='btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4'
                                  >
                                    <div>
                                      <img
                                        src='/assets/images/icons/upload-icon-deep-blue.png'
                                        alt=''
                                      />
                                    </div>
                                    Upload image
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-xl-3 float-sm-right mb-5'>
                            <img
                              className='w-100 h-100 img-fluid rounded overflow-hideen'
                              id='imageResult'
                              src={remarkForm?.whsIndoors?.indoor[0]?.value[index]}
                              alt=''
                            />

                            {index !== 0 ? (
                              <span
                                onClick={() => handleDeletInDoorView(index)}
                                className='ml-4 cursors float-right my-2'
                              >
                                {/* {index} */}
                                <DeleteIcon />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className='text-danger pr-4 text-end'>{indoorError}</div>
                <div className='col-12 mt-4'>
                  {!viewMood && (
                    <div className='row justify-content-end'>
                      <div className='col-auto'>
                        <button
                          type='button'
                          onClick={() => {
                            setInDoorView([...inDoorView, inDoorView.length + 1]);
                            // setIndoorViewDoor([]);
                          }}
                          className='btn text-deep-blue text-uppercase font-weight-bold'
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  )}
                  <div className='col-auto w-30 mt-2 ml-2'>
                    <input
                      disabled
                      type='text'
                      className='form-control bg-white px-4 mb-2 mr-4'
                      placeholder='Remarks'
                      value={
                        remarkForm?.whsIndoors?.indoor[0]?.whsremark !== ''
                          ? remarkForm?.whsIndoors?.indoor[0]?.whsremark
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='row bg-white pt-3 rounded mx-0 col-xxxl-11'>
                <div className='col-12'>
                  <h5 className='py-3 mb-3 border-bottom'>Warehouse Outdoor Photos</h5>
                </div>
                {outDoorView !== undefined &&
                  outDoorView.map((c, index) => {
                    return (
                      <div key={index} className='col-xl-12 col-lg-12 col-sm-6 mb-2'>
                        <div className='cards'>
                          <div className='col-xl-6 float-sm-left'>
                            <div className='img-holder size-200px'>
                              <img
                                className='w-100 h-100 img-fluid rounded overflow-hideen'
                                id='imageResult'
                                src='/assets/images/upload-bg.png'
                                alt=''
                              />
                            </div>
                            <div className='card-img-overlay size-200px d-flex justify-content-center align-items-center'>
                              <div className='card-text'>
                                <input
                                  onChange={(e) => outDoorHandle(e, index)}
                                  id={`upload-image-previewout${index}`}
                                  type='file'
                                  className='form-control border-0'
                                  hidden
                                  disabled={viewMood}
                                />
                                <label
                                  id='upload-label'
                                  htmlFor={`upload-image-previewout${index}`}
                                  className='font-weight-light text-muted'
                                ></label>
                                <div className='input-group-append'>
                                  <label
                                    htmlFor={`upload-image-previewout${index}`}
                                    className='btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4'
                                  >
                                    <div>
                                      <img
                                        src='/assets/images/icons/upload-icon-deep-blue.png'
                                        alt=''
                                      />
                                    </div>
                                    Upload image
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-xl-3 float-sm-right mb-5'>
                            <img
                              className='w-100 h-100 img-fluid rounded overflow-hideen'
                              id='imageResult'
                              src={remarkForm?.whsOutdoors?.outdoor[0]?.value[index]}
                              alt=''
                            />
                            {index !== 0 ? (
                              <span
                                onClick={() => handleDeletOutDoorView(index)}
                                className='ml-4 cursors float-right my-2'
                              >
                                {/* {index} */}
                                <DeleteIcon />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className='text-danger pr-4 text-end'>{outdoorError}</div>
                <div className='col-12 mt-4'>
                  {!viewMood && (
                    <div className='row justify-content-end'>
                      <div className='col-auto'>
                        <button
                          type='button'
                          onClick={() => {
                            setOutDoorView([...outDoorView, outDoorView.length + 1]);
                            // setOutdoorViewDoor([]);
                          }}
                          className='btn text-deep-blue text-uppercase font-weight-bold'
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  )}
                  <div className='col-auto w-30 mt-2 ml-2'>
                    <input
                      disabled
                      type='text'
                      className='form-control bg-white px-4 mb-2 mr-4'
                      placeholder='Remarks'
                      value={
                        remarkForm?.whsOutdoors?.outdoor[0]?.whsremark !== ''
                          ? remarkForm?.whsOutdoors?.outdoor[0]?.whsremark
                          : null
                      }
                    />
                  </div>
                </div>
                <div className='col-12 mt-4'>
                  {!viewMood && (
                    <div
                      className={`${
                        data?.isError ? 'row justify-content-between' : 'row justify-content-end'
                      }`}
                    >
                      {data?.isError ? (
                        <div class='alert alert-warning alert-dismissible fade show' role='alert'>
                          {data.isError}.
                          <button
                            type='button'
                            class='btn-close'
                            data-bs-dismiss='alert'
                            aria-label='Close'
                            onClick={closeErrorMessage}
                          ></button>
                        </div>
                      ) : null}
                      <div className='col-auto'>
                        <button
                          type='submit'
                          className='btn btn-deep-blue add-className remove-className my-2'
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          {console.log('debugging')}
          {console.log(inDoorView)}
          {console.log(outDoorView)}
          {console.log(remarkForm)}
          {console.log('debugging')}
        </div>
      )}
    </>
  );
};

export default WarehousePhotoForm;

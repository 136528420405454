import React from 'react';
// export function subValues() {
export function FormSuccess({ onClick }) {
  return (
    <div className='form-modal'>
      <div className='modal-success'>
        <h5 className='text-capitalize px-2'>Form Created Successfully</h5>
        <button onClick={onClick}>OK</button>
      </div>
    </div>
  );
}

export function FormMessage({ onClick, message }) {
  return (
    <div className='form-modal'>
      <div className='modal-error'>
        <h5 className='text-capitalize px-2'>{message}</h5>
        <button onClick={onClick}>Close</button>
      </div>
    </div>
  );
}

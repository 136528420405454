import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {onlyRead} from '../../../store/actions/sidemenuAction';

function SidebarItem({ depthStep = 10, depth = 0, expanded, item, ...rest }) {
  const [collapsed, setCollapsed] = React.useState(true);
  const { label, link, items, Icon, onClick: onClickProp } = item;
  // console.log(item,"***This is link***",items);
  const dispatch = useDispatch();

  function toggleCollapse() {
    setCollapsed(prevValue => !prevValue);
  }

  function onClick(e) {
    
    if(item.link !== ''){
      if(item.canRead === true && item.canWrite === false){
        dispatch(onlyRead(true))
      }else{
        dispatch(onlyRead(false)) 
      }
    }

    if (Array.isArray(items)) {
      toggleCollapse();
    }
    if (onClickProp) {
      onClickProp(e, item);
    }
  }

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? (
      <ExpandLessIcon
        className={
          "sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded"
        }
      />
    ) : (
      <ExpandMoreIcon className="sidebar-item-expand-arrow" />
    );
  }

  return (
    <>
      <ListItem
        className="sidebar-item"
        onClick={onClick}
        button
        dense
        {...rest}
      >
        <div
          style={{ paddingLeft: depth * depthStep }}
          className="sidebar-item-content"
        >
          {Icon && <Icon className="sidebar-item-icon" fontSize="small" />}
          {
            label !==""?(
              <>
                {link ?
                <Link to={link}>
                <div className="sidebar-item-text labelColor"><i className={"fa " +Icon+ " iIcon mx-2"}></i>{label}</div>
                </Link>
                :
                <div className="sidebar-item-text labelColor"><i className={"fa " +Icon+ " iIcon mx-2"}></i>{label}</div>
                }
              </>
            ):""
          }
          
        </div>
        {expandIcon}
      </ListItem>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => {
              return(
                <>
                {subItem.canRead === true || subItem.canWrite === true ?

                  <React.Fragment key={`${subItem.name}${index}`}>
                  {subItem === "divider" ? (
                    <Divider style={{ margin: "6px 0" }} />
                  ) : (
                    <SidebarItem
                      depth={depth + 1}
                      depthStep={depthStep}
                      item={subItem}
                    />
                  )}
                  </React.Fragment>

                :null }
                </>
              )
            }
            )}
          </List>
        ) : null}
      </Collapse>
    </>
  );
}

function Sidebar({ items, depthStep, depth, expanded }) {
 
  return (
    <div className="sidebar">
      <List disablePadding dense>
        {items && items.map((sidebarItem, index) =>{

            return(
              <>
              {sidebarItem.canRead===true || sidebarItem.canWrite===true ?
              
              <React.Fragment key={`${sidebarItem.name}${index}`}>
              {sidebarItem === "divider" ? (
                <Divider style={{ margin: "6px 0" }} />
              ) : (
                <SidebarItem
                  depthStep={depthStep}
                  depth={depth}
                  expanded={expanded}
                  item={sidebarItem}
                />
              )}
            </React.Fragment>

            :null}
            
              </>
            )
        }

        )}
      </List>

    </div>
  );
}

export default Sidebar;

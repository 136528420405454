import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePermit,
  changeWarehouseStatus,
  updateWarehousependingDetails,
} from '../../../store/actions/vendor/warehouseList';
import { CardLoader } from '../../helper/CustomLoader';
import { useHistory } from 'react-router-dom';
import { FormSuccess } from '../../helper/FormSuccess';
import { Formik, Form, Field } from 'formik';
import Spinner from 'react-bootstrap/Spinner';
import { FormErrorCard } from '../../helper/ErrorCard';
import ErrorCard from '../../helper/ErrorCard';
import axiosauth from '../../../api/axios-auth';

const PermitForm = ({ warehouseId, slug, viewMood }) => {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [formSub, setFormSub] = useState(false);
  const pdata = useSelector((state) => state.WAREHOUSELIST);
  const dispatch = useDispatch();

  const [yesForm, setYesForm] = useState({
    landlordYes: false,
    ownershipYes: false,
    cluYes: false,
    completionYes: false,
    occupancyYes: false,
    buildingInsuranceYes: false,
    buildingStabilityYes: false,
    FireNOCYes: false,
    floorLoadYes: false,
    approvedBuildingYes: false,
    gstRegistrationYes: false,
    panchayatNOCYes: false,
    panCardYes: false,
    electricityBillYes: false,
    shopCertificateYes: false,
    tradeLicenseYes: false,
    laborLicenseYes: false,
    factoryLicenseYes: false,
    fssaiLicenseYes: false,
    pollutionPollutionYes: false,
  });
  const [imagesshow, setImagesshow] = useState({
    landlordFileview: '',
    ownershipFileview: '',
    completionFileview: '',
    occupancyFileview: '',
    buildingInsuranceFileview: '',
    buildingStabilityFileview: '',
    FireNOCFileview: '',
    floorLoadFileview: '',
    approvedBuildingFileview: '',
    photoFileview: '',
    profileFileview: '',
    gstRegistrationFileview: '',
    panchayatNOCFileview: '',
    shopCertificateFileview: '',
    tradeLicenseFileview: '',
    laborLicenseFileview: '',
    factoryLicenseFileview: '',
    fssaiLicenseFileview: '',
    pollutionPollutionFileview: '',
  });

  const {
    landlordYes,
    ownershipYes,
    cluYes,
    completionYes,
    occupancyYes,
    buildingInsuranceYes,
    buildingStabilityYes,
    FireNOCYes,
    floorLoadYes,
    approvedBuildingYes,
    gstRegistrationYes,
    panchayatNOCYes,
    panCardYes,
    electricityBillYes,
    shopCertificateYes,
    tradeLicenseYes,
    laborLicenseYes,
    factoryLicenseYes,
    fssaiLicenseYes,
    pollutionPollutionYes,
  } = yesForm;

  const yesHandle = (e) => {
    setYesForm({ ...yesForm, [e.target.name]: e.target.value === 'true' });
  };

  // Set Form State

  const [permitForm, setPermitForm] = useState({
    tradePermit: {
      gstRegistrationCertificate: {
        value: '',
        whsremark: '',
      },
      panCardOfCompany: {
        value: '',
        whsremark: '',
      },
      electricityBill: {
        value: '',
        whsremark: '',
      },
      shopAndEstablishmentCertificate: {
        value: '',
        whsremark: '',
      },
      tradeRelatedPermit: [
        {
          type: 'Panchayat / Municipal NOC',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Trade License',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Labor License',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Factory License (for VAS activities)',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'FSSAI License',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Pollution Under Control(PUC)',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
      ],
    },
    buildingPermit: {
      ownershipDocument: {
        value: '',
        whsremark: '',
      },
      cluCommercialWarehousing: {
        value: '',
        whsremark: '',
      },
      completionCertificate: {
        value: '',
        whsremark: '',
      },
      occupancyCertificate: {
        value: '',
        whsremark: '',
      },

      approvedBuildingLayout: {
        value: '',
        whsremark: '',
      },
      warehousePhotoGallery: {
        value: '',
        whsremark: '',
      },
      warehouseProfilePic: {
        value: '',
        whsremark: '',
      },
      buildingRelatedPermit: [
        {
          type: 'Copy Of Lease Agreement With Landlord',
          building: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Building Insurance',
          building: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Building Stability Certificate',
          building: {
            value: {
              validfromfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Fire NOC',
          building: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Floor Load Bearing Capacity Certificate',
          building: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
      ],
    },
    landlordFileLink: '',
    ownershipFileLink: '',
    cluFileLink: '',
    completionFileLink: '',
    occupancyFileLink: '',
    buildingInsuranceFileLink: '',
    buildingStabilityFileLink: '',
    FireNOCFileLink: '',
    floorLoadFileLink: '',
    approvedBuildingFileLink: '',
    photoFileLink: '',
    profileFileLink: '',
    gstRegistrationFileLink: '',
    panchayatNOCFileLink: '',
    shopCertificateFileLink: '',
    tradeLicenseFileLink: '',
    laborLicenseFileLink: '',
    factoryLicenseFileLink: '',
    fssaiLicenseFileLink: '',
    pollutionPollutionFileLink: '',
  });
  const {
    landlordFrom,
    landlordTill,
    landlordFileLink,
    ownershipFileLink,
    cluFileLink,
    completionFileLink,
    occupancyFileLink,

    buildingInsuranceFrom,
    buildingInsuranceTill,
    buildingInsuranceFileLink,

    buildingStabilityFrom,
    buildingStabilityTill,
    buildingStabilityFileLink,

    FireNOCFrom,
    FireNOCTill,
    FireNOCFileLink,

    floorLoadFrom,
    floorLoadTill,
    floorLoadFileLink,

    approvedBuildingFileLink,

    photoFileLink,

    profileFileLink,

    gstRegistrationFileLink,

    panchayatNOCFrom,
    panchayatNOCTill,
    panchayatNOCFileLink,

    panCardFileLink,

    electricityBillFileLink,

    shopCertificateFileLink,

    tradeLicenseFrom,
    tradeLicenseTill,
    tradeLicenseFileLink,

    laborLicenseFrom,
    laborLicenseTill,
    laborLicenseFileLink,

    factoryLicenseFrom,
    factoryLicenseTill,
    factoryLicenseFileLink,

    fssaiLicenseFrom,
    fssaiLicenseTill,
    fssaiLicenseFileLink,

    pollutionPollutionFrom,
    pollutionPollutionTill,
    pollutionPollutionFileLink,
  } = permitForm;

  const handleChange = (e) => {
    // console.log(e.target.name);
    if (e.target.name === 'landlordFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, landlordFileview: res.data });
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   [...permitForm.buildingPermit.buildingRelatedPermit];
            // permitFormS.landlordFileLink = res.data;
            // setPermitForm({
            //   ...permitFormS,
            //   // landlordFileLink: res.data
            // });
            // console.log(permitFormS,"permitFormS")
            // setPermitForm({ ...permitForm, landlordFile: val, landlordFileLink: res.data });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'ownershipFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;

      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, ownershipFileview: res.data });
            // console.log(imagesshow)

            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.ownershipFileLink = res.data;
            // setPermitForm({
            //   ...permitFormS,
            // });

            // setPermitForm({ ...permitForm, ownershipFile: val, ownershipFileLink: res.data });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'cluFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;

      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, cluFileview: res.data });
            // console.log(imagesshow);

            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.cluFileLink = res.data;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({ ...permitForm, cluFile: val, cluFileLink: res.data });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'completionFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;

      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, completionFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.completionFileLink = res.data;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({ ...permitForm, completionFile: val, completionFileLink: res.data });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'occupancyFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, occupancyFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.occupancyFileLink = res.data;
            // permitFormS.occupancyFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({ ...permitForm, occupancyFile: val, occupancyFileLink: res.data });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'buildingInsuranceFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, buildingInsuranceFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit = permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.buildingInsuranceFileLink = res.data;
            // permitFormS.buildingInsuranceFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }

    if (e.target.name === 'buildingStabilityFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, buildingStabilityFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.buildingStabilityFileLink = res.data;
            // permitFormS.buildingStabilityFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({
            //   ...permitForm,
            //   buildingStabilityFile: val,
            //   buildingStabilityFileLink: res.data,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'FireNOCFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, FireNOCFileview: res.data });
            // console.log(imagesshow);

            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.FireNOCFileLink = res.data;
            // permitFormS.FireNOCFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'floorLoadFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;
      // console.log('this is floorLoadFile');
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, floorLoadFileview: res.data });
            // console.log(imagesshow);
            // console.log(permitForm, "||permitForm||")
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.floorLoadFileLink = res.data;
            // permitFormS.floorLoadFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'approvedBuildingFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;

      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, approvedBuildingFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.approvedBuildingFileLink = res.data;
            // permitFormS.approvedBuildingFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({
            //   ...permitForm,
            //   approvedBuildingFile: val,
            //   approvedBuildingFileLink: res.data,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'photoFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, photoFileview: res.data });
            // console.log(imagesshow);

            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.photoFileLink = res.data;
            // permitFormS.photoFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'profileFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, profileFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.profileFileLink = res.data;
            // permitFormS.profileFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'gstRegistrationFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, gstRegistrationFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.gstRegistrationFileLink = res.data;
            // permitFormS.gstRegistrationFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({
            //   ...permitForm,
            //   gstRegistrationFile: val,
            //   gstRegistrationFileLink: res.data,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'panchayatNOCFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, panchayatNOCFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitForm.tradePermit.tradeRelatedPermit =
            //   permitForm.tradePermit.tradeRelatedPermit;
            // permitFormS.panchayatNOCFileLink = res.data;
            // permitFormS.panchayatNOCFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }

    if (e.target.name === 'panCardFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, panCardFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.panCardFileLink = res.data;
            // permitFormS.panCardFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({ ...permitForm, panCardFile: val, panCardFileLink: res.data });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'electricityBillFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, electricityBillFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.electricityBillFileLink = res.data;
            // permitFormS.electricityBillFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'shopCertificateFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, shopCertificateFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.shopCertificateFileLink = res.data;
            // permitFormS.shopCertificateFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'tradeLicenseFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, tradeLicenseFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit
            // permitFormS.tradeLicenseFileLink = res.data
            // permitFormS.tradeLicenseFile = val
            // setPermitForm({
            //   ...permitFormS,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'laborLicenseFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, laborLicenseFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.laborLicenseFileLink = res.data;
            // permitFormS.laborLicenseFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({ ...permitForm, laborLicenseFile: val, laborLicenseFileLink: res.data });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'factoryLicenseFile') {
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, factoryLicenseFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.factoryLicenseFileLink = res.data;
            // permitFormS.factoryLicenseFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({
            //   ...permitForm,
            //   factoryLicenseFile: val,
            //   factoryLicenseFileLink: res.data,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'fssaiLicenseFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;
      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, fssaiLicenseFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.fssaiLicenseFileLink = res.data;
            // permitFormS.fssaiLicenseFile = val;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({ ...permitForm, fssaiLicenseFile: val, fssaiLicenseFileLink: res.data });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    }
    if (e.target.name === 'pollutionPollutionFile') {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
      let val = e.target.value;

      let formData = new FormData();
      formData.append('image', e.currentTarget.files[0]);
      axiosauth
        .post('/api/v1/buildingtraderelated/fileupload', formData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setImagesshow({ ...imagesshow, pollutionPollutionFileview: res.data });
            // console.log(imagesshow);
            // const permitFormS = permitForm;
            // permitFormS.buildingPermit.buildingRelatedPermit =
            //   permitForm.buildingPermit.buildingRelatedPermit;
            // permitFormS.pollutionPollutionFileLink = res.data;
            // permitFormS.pollutionPollutionFile = res.data;
            // setPermitForm({
            //   ...permitFormS,
            // });
            // setPermitForm({
            //   ...permitForm,
            //   pollutionPollutionFile: val,
            //   pollutionPollutionFileLink: res.data,
            // });
          } else {
          }
        })
        .catch((error) => {
          // console.log('here', error);
        });
    } else {
      // setPermitForm({ ...permitForm, [e.target.name]: e.target.value });
    }

    // }
    if (formSub) {
      formVaidation();
    }
  };

  const formVaidation = () => {
    let errors = {};
    let isValid = true;

    if (landlordYes && permitForm.landlordFrom === '') {
      isValid = false;
      errors['landlordFrom'] = 'Required';
    }
    if (landlordYes && permitForm.landlordTill === '') {
      isValid = false;
      errors['landlordTill'] = 'Required';
    }
    if (landlordYes && permitForm.landlordTill < permitForm.landlordFrom) {
      isValid = false;
      errors['landlordTill'] = 'Valid Till Date Invalid';
    }
    if (landlordYes && permitForm.landlordFile === '') {
      isValid = false;
      errors['landlordFile'] = 'Required';
    }
    if (ownershipYes && permitForm.ownershipFile === '') {
      isValid = false;
      errors['ownershipFile'] = 'Required';
    }
    if (cluYes && permitForm.cluFile === '') {
      isValid = false;
      errors['cluFile'] = 'Required';
    }
    if (completionYes && permitForm.completionFile === '') {
      isValid = false;
      errors['completionFile'] = 'Required';
    }
    if (occupancyYes && permitForm.occupancyFile === '') {
      isValid = false;
      errors['occupancyFile'] = 'Required';
    }
    if (buildingInsuranceYes && permitForm.buildingInsuranceFrom === '') {
      isValid = false;
      errors['buildingInsuranceFrom'] = 'Required';
    }
    if (buildingInsuranceYes && permitForm.buildingInsuranceTill === '') {
      isValid = false;
      errors['buildingInsuranceTill'] = 'Required';
    }
    if (
      buildingInsuranceYes &&
      permitForm.buildingInsuranceTill < permitForm.buildingInsuranceFrom
    ) {
      isValid = false;
      errors['buildingInsuranceTill'] = 'Valid Till Date Invalid';
    }
    if (buildingInsuranceYes && permitForm.buildingInsuranceFile === '') {
      isValid = false;
      errors['buildingInsuranceFile'] = 'Required';
    }
    if (buildingStabilityYes && permitForm.buildingStabilityFrom === '') {
      isValid = false;
      errors['buildingStabilityFrom'] = 'Required';
    }
    if (buildingStabilityYes && permitForm.buildingStabilityTill === '') {
      isValid = false;
      errors['buildingStabilityTill'] = 'Required';
    }
    if (
      buildingStabilityYes &&
      permitForm.buildingStabilityTill < permitForm.buildingStabilityFrom
    ) {
      isValid = false;
      errors['buildingStabilityTill'] = 'Valid Till Date Invalid';
    }
    if (buildingStabilityYes && permitForm.buildingStabilityFile === '') {
      isValid = false;
      errors['buildingStabilityFile'] = 'Required';
    }
    if (FireNOCYes && permitForm.FireNOCFrom === '') {
      isValid = false;
      errors['FireNOCFrom'] = 'Required';
    }
    if (FireNOCYes && permitForm.FireNOCTill === '') {
      isValid = false;
      errors['FireNOCTill'] = 'Required';
    }
    if (FireNOCYes && permitForm.FireNOCTill < permitForm.FireNOCFrom) {
      isValid = false;
      errors['FireNOCTill'] = 'Valid Till Date Invalid';
    }
    if (FireNOCYes && permitForm.FireNOCFile === '') {
      isValid = false;
      errors['FireNOCFile'] = 'Required';
    }
    if (floorLoadYes && permitForm.floorLoadFrom === '') {
      isValid = false;
      errors['floorLoadFrom'] = 'Required';
    }
    if (floorLoadYes && permitForm.floorLoadTill === '') {
      isValid = false;
      errors['floorLoadTill'] = 'Required';
    }
    if (floorLoadYes && permitForm.floorLoadTill < permitForm.floorLoadFrom) {
      isValid = false;
      errors['floorLoadTill'] = 'Valid Till Date Invalid';
    }
    if (floorLoadYes && permitForm.floorLoadFile === '') {
      isValid = false;
      errors['floorLoadFile'] = 'Required';
    }
    if (approvedBuildingYes && permitForm.approvedBuildingFile === '') {
      isValid = false;
      errors['approvedBuildingFile'] = 'Required';
    }
    if (permitForm.photoFile === '') {
      // isValid = false;
      // errors['photoFile'] = "Required";
    }
    if (permitForm.profileFile === '') {
      // isValid = false;
      // errors['profileFile'] = "Required";
    }
    if (gstRegistrationYes && permitForm.gstRegistrationFile === '') {
      isValid = false;
      errors['gstRegistrationFile'] = 'Required';
    }
    if (panchayatNOCYes && permitForm.panchayatNOCFrom === '') {
      isValid = false;
      errors['panchayatNOCFrom'] = 'Required';
    }
    if (panchayatNOCYes && permitForm.panchayatNOCTill === '') {
      isValid = false;
      errors['panchayatNOCTill'] = 'Required';
    }
    if (panchayatNOCYes && permitForm.panchayatNOCTill < permitForm.panchayatNOCFrom) {
      isValid = false;
      errors['panchayatNOCTill'] = 'Valid Till Date Invalid';
    }
    if (panchayatNOCYes && permitForm.panchayatNOCFile === '') {
      isValid = false;
      errors['panchayatNOCFile'] = 'Required';
    }
    if (panCardYes && permitForm.panCardFile === '') {
      isValid = false;
      errors['panCardFile'] = 'Required';
    }
    if (electricityBillYes && permitForm.electricityBillFile === '') {
      isValid = false;
      errors['electricityBillFile'] = 'Required';
    }
    if (shopCertificateYes && permitForm.shopCertificateFile === '') {
      isValid = false;
      errors['shopCertificateFile'] = 'Required';
    }
    if (tradeLicenseYes && permitForm.tradeLicenseFrom === '') {
      isValid = false;
      errors['tradeLicenseFrom'] = 'Required';
    }
    if (tradeLicenseYes && permitForm.tradeLicenseTill === '') {
      isValid = false;
      errors['tradeLicenseTill'] = 'Required';
    }
    if (tradeLicenseYes && permitForm.tradeLicenseTill < permitForm.tradeLicenseFrom) {
      isValid = false;
      errors['tradeLicenseTill'] = 'Valid Till Date Invalid';
    }
    if (tradeLicenseYes && permitForm.tradeLicenseFile === '') {
      isValid = false;
      errors['tradeLicenseFile'] = 'Required';
    }
    if (laborLicenseYes && permitForm.laborLicenseFrom === '') {
      isValid = false;
      errors['laborLicenseFrom'] = 'Required';
    }
    if (laborLicenseYes && permitForm.laborLicenseTill === '') {
      isValid = false;
      errors['laborLicenseTill'] = 'Required';
    }
    if (laborLicenseYes && permitForm.laborLicenseTill < permitForm.laborLicenseFrom) {
      isValid = false;
      errors['laborLicenseTill'] = 'Valid Till Date Invalid';
    }
    if (laborLicenseYes && permitForm.laborLicenseFile === '') {
      isValid = false;
      errors['laborLicenseFile'] = 'Required';
    }
    if (factoryLicenseYes && permitForm.factoryLicenseFrom === '') {
      isValid = false;
      errors['factoryLicenseFrom'] = 'Required';
    }
    if (factoryLicenseYes && permitForm.factoryLicenseTill === '') {
      isValid = false;
      errors['factoryLicenseTill'] = 'Required';
    }
    if (factoryLicenseYes && permitForm.factoryLicenseTill < permitForm.factoryLicenseFrom) {
      isValid = false;
      errors['factoryLicenseTill'] = 'Valid Till Date Invalid';
    }
    if (factoryLicenseYes && permitForm.factoryLicenseFile === '') {
      isValid = false;
      errors['factoryLicenseFile'] = 'Required';
    }
    if (fssaiLicenseYes && permitForm.fssaiLicenseFrom === '') {
      isValid = false;
      errors['fssaiLicenseFrom'] = 'Required';
    }
    if (fssaiLicenseYes && permitForm.fssaiLicenseTill === '') {
      isValid = false;
      errors['fssaiLicenseTill'] = 'Required';
    }
    if (fssaiLicenseYes && permitForm.fssaiLicenseTill < permitForm.fssaiLicenseFrom) {
      isValid = false;
      errors['fssaiLicenseTill'] = 'Valid Till Date Invalid';
    }
    if (fssaiLicenseYes && permitForm.fssaiLicenseFile === '') {
      isValid = false;
      errors['fssaiLicenseFile'] = 'Required';
    }
    if (pollutionPollutionYes && permitForm.pollutionPollutionFrom === '') {
      isValid = false;
      errors['pollutionPollutionFrom'] = 'Required';
    }
    if (pollutionPollutionYes && permitForm.pollutionPollutionTill === '') {
      isValid = false;
      errors['pollutionPollutionTill'] = 'Required';
    }
    if (
      pollutionPollutionYes &&
      permitForm.pollutionPollutionTill < permitForm.pollutionPollutionFrom
    ) {
      isValid = false;
      errors['pollutionPollutionTill'] = 'Valid Till Date Invalid';
    }
    if (pollutionPollutionYes && permitForm.pollutionPollutionFile === '') {
      isValid = false;
      errors['pollutionPollutionFile'] = 'Required';
    }
    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    if (pdata?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/update-warehouse-rejected/${warehouseId}/material-type`);
      dispatch(changeWarehouseStatus());
    }
  }, [pdata, history, dispatch]);

  useEffect(() => {
    // console.log(pdata?.singleFormData?.warehouseRej, '||singleFormData||');
    if (
      pdata?.singleFormData?.remarks === 0 &&
      pdata?.singleFormData?.warehouseRej?.permits !== null &&
      pdata?.singleFormData?.warehouseRej?.permits !== undefined
    ) {
      setPermitForm({
        tradePermit: {
          gstRegistrationCertificate: {
            value:
              pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                ?.gstRegistrationCertificate,
            whsremark: '',
          },
          panCardOfCompany: {
            value: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.panCardOfCompany,
            whsremark: '',
          },
          electricityBill: {
            value: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.electricityBill,
            whsremark: '',
          },
          shopAndEstablishmentCertificate: {
            value:
              pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                ?.shopAndEstablishmentCertificate,
            whsremark: '',
          },
          tradeRelatedPermit: [
            {
              type: 'Panchayat / Municipal NOC',
              trade: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[0]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[0]?.validTill,
                },
                whsremark: '',
              },
            },
            {
              type: 'Trade License',
              trade: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[2]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[2]?.validTill,
                },
                whsremark: '',
              },
            },
            {
              type: 'Labor License',
              trade: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[1]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[1]?.validTill,
                },
                whsremark: '',
              },
            },
            {
              type: 'Factory License (for VAS activities)',
              trade: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[3]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[3]?.validTill,
                },
                whsremark: '',
              },
            },
            {
              type: 'FSSAI License',
              trade: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[4]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[4]?.validTill,
                },
                whsremark: '',
              },
            },
            {
              type: 'Pollution Under Control(PUC)',
              trade: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[5]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
                      ?.tradeRelatedPermitInfo[5]?.validTill,
                },
                whsremark: '',
              },
            },
          ],
        },
        buildingPermit: {
          ownershipDocument: {
            value:
              pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                ?.ownershipDocument,
            whsremark: '',
          },
          cluCommercialWarehousing: {
            value:
              pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                ?.cluCommercialWarehousing,
            whsremark: '',
          },
          completionCertificate: {
            value:
              pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                ?.completionCertificate,
            whsremark: '',
          },
          occupancyCertificate: {
            value:
              pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                ?.occupancyCertificate,
            whsremark: '',
          },

          approvedBuildingLayout: {
            value:
              pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                ?.approvedBuildingLayout,
            whsremark: '',
          },
          warehousePhotoGallery: {
            value:
              pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                ?.warehousePhotoGallery,
            whsremark: '',
          },
          warehouseProfilePic: {
            value:
              pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                ?.warehouseProfilePic,
            whsremark: '',
          },
          buildingRelatedPermit: [
            {
              type: 'Copy Of Lease Agreement With Landlord',
              building: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[1]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[1]?.validTill,
                },
                whsremark: '',
              },
            },
            {
              type: 'Building Insurance',
              building: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[0]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[0]?.validTill,
                },
                whsremark: '',
              },
            },
            {
              type: 'Building Stability Certificate',
              building: {
                value: {
                  validfromfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[3]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[3]?.validTill,
                },
                whsremark: '',
              },
            },
            {
              type: 'Fire NOC',
              building: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[4]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[4]?.validTill,
                },
                whsremark: '',
              },
            },
            {
              type: 'Floor Load Bearing Capacity Certificate',
              building: {
                value: {
                  validfrom:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[5]?.validForm,
                  validtill:
                    pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
                      ?.buildingRelatedPermitInfo[5]?.validTill,
                },
                whsremark: '',
              },
            },
          ],
        },
        landlordFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.buildingRelatedPermitInfo[1]?.filePath,
        ownershipFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit?.ownershipDocument,
        cluFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.cluCommercialWarehousing,
        completionFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.completionCertificate,
        occupancyFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.occupancyCertificate,
        buildingInsuranceFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.buildingRelatedPermitInfo[0]?.filePath,
        buildingStabilityFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.buildingRelatedPermitInfo[3]?.filePath,
        FireNOCFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.buildingRelatedPermitInfo[4]?.filePath,
        floorLoadFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.buildingRelatedPermitInfo[5]?.filePath,
        approvedBuildingFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.approvedBuildingLayout,
        photoFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.warehousePhotoGallery,
        profileFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
            ?.warehouseProfilePic,
        gstRegistrationFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.gstRegistrationCertificate,
        panchayatNOCFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[0]
            ?.filePath,
        shopCertificateFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
            ?.shopAndEstablishmentCertificate,
        tradeLicenseFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[2]
            ?.filePath,
        laborLicenseFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[1]
            ?.filePath,
        factoryLicenseFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[3]
            ?.filePath,
        fssaiLicenseFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[4]
            ?.filePath,
        pollutionPollutionFileLink:
          pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[5]
            ?.filePath,
      });

      setYesForm({
        landlordYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.buildingRelatedPermitInfo[1]?.validForm
          ? true
          : false,
        ownershipYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.ownershipDocument
          ? true
          : false,
        cluYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.cluCommercialWarehousing
          ? true
          : false,
        completionYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.completionCertificate
          ? true
          : false,
        occupancyYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.occupancyCertificate
          ? true
          : false,
        buildingInsuranceYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.buildingRelatedPermitInfo[0]?.validForm
          ? true
          : false,
        buildingStabilityYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.buildingRelatedPermitInfo[3]?.validForm
          ? true
          : false,
        FireNOCYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.buildingRelatedPermitInfo[4]?.validForm
          ? true
          : false,
        floorLoadYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.buildingRelatedPermitInfo[5]?.validForm
          ? true
          : false,
        approvedBuildingYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.buildingRelatedPermit
          ?.approvedBuildingLayout
          ? true
          : false,
        gstRegistrationYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
          ?.gstRegistrationCertificate
          ? true
          : false,
        panchayatNOCYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
          ?.tradeRelatedPermitInfo[0]?.validForm
          ? true
          : false,
        panCardYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated?.panCardOfCompany
          ? true
          : false,
        electricityBillYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
          ?.electricityBill
          ? true
          : false,
        shopCertificateYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
          ?.shopAndEstablishmentCertificate
          ? true
          : false,
        tradeLicenseYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
          ?.tradeRelatedPermitInfo[2]?.validForm
          ? true
          : false,
        laborLicenseYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
          ?.tradeRelatedPermitInfo[1]?.validForm
          ? true
          : false,
        factoryLicenseYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
          ?.tradeRelatedPermitInfo[3]?.validForm
          ? true
          : false,
        fssaiLicenseYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
          ?.tradeRelatedPermitInfo[4]?.validForm
          ? true
          : false,
        pollutionPollutionYes: pdata?.singleFormData?.warehouseRej?.permits[0]?.tradeRelated
          ?.tradeRelatedPermitInfo[5]?.validForm
          ? true
          : false,
      });
    } else {
      if (
        pdata?.singleFormData?.remarks === 1 &&
        pdata?.singleFormData !== null &&
        pdata?.singleFormData?.warehouseRej !== null
      ) {
        if (
          pdata?.singleFormData?.warehouseRej?.permitsRemarks !== undefined &&
          pdata?.singleFormData?.remarks === 1
        ) {
          if (Object.keys(pdata?.singleFormData?.warehouseRej?.permitsRemarks).length !== 0) {
            setPermitForm(pdata?.singleFormData?.warehouseRej?.permitsRemarks);
            // console.log(pdata?.singleFormData?.warehouseRej?.permitsRemarks, 'hello trigger');

            //yes no icon set
            const permit = pdata?.singleFormData?.warehouseRej?.permitsRemarks;
            // console.log(permit, '||permit||');
            if (
              permit?.buildingPermit?.buildingRelatedPermit !== undefined &&
              permit?.tradePermit?.tradeRelatedPermit !== undefined
            ) {
              setYesForm({
                landlordYes: permit?.buildingPermit?.buildingRelatedPermit[0]?.building?.value
                  ?.validfrom
                  ? true
                  : false,
                ownershipYes: permit?.ownershipFileLink ? true : false,
                cluYes: permit?.cluFileLink ? true : false,
                completionYes: permit?.buildingPermit?.buildingRelatedPermit[1]?.building?.value
                  ?.validtill
                  ? true
                  : false,
                occupancyYes: permit?.buildingPermit?.buildingRelatedPermit[2]?.building?.value
                  ?.validfromfrom
                  ? true
                  : false,
                buildingInsuranceYes: permit?.buildingPermit?.buildingRelatedPermit[2]?.building
                  ?.value?.validtill
                  ? true
                  : false,
                buildingStabilityYes: permit?.buildingPermit?.buildingRelatedPermit[3]?.building
                  ?.value?.validfrom
                  ? true
                  : false,
                FireNOCYes: permit?.buildingPermit?.buildingRelatedPermit[3]?.building?.value
                  ?.validtill
                  ? true
                  : false,
                floorLoadYes: permit?.buildingPermit?.buildingRelatedPermit[4]?.building?.value
                  ?.validfrom
                  ? true
                  : false,
                approvedBuildingYes: permit?.buildingPermit?.buildingRelatedPermit[4]?.building
                  ?.value?.validtill
                  ? true
                  : false,
                gstRegistrationYes: permit?.buildingPermit?.buildingRelatedPermit[2]?.building
                  ?.value?.validtill
                  ? true
                  : false,
                panchayatNOCYes: permit?.buildingPermit?.buildingRelatedPermit[2]?.building?.value
                  ?.validtill
                  ? true
                  : false,
                panCardYes: permit?.tradePermit?.panCardOfCompany?.value
                  ? true
                  : false,
                electricityBillYes: permit?.tradePermit?.electricityBill?.value
                  ? true
                  : false,
                shopCertificateYes: permit?.tradePermit?.shopAndEstablishmentCertificate?.value
                  ? true
                  : false,
                tradeLicenseYes: permit?.tradePermit?.tradeRelatedPermit[1]?.trade?.value?.validfrom
                  ? true
                  : false,
                laborLicenseYes: permit?.tradePermit?.tradeRelatedPermit[2]?.trade?.value?.validtill
                  ? true
                  : false,
                factoryLicenseYes: permit?.tradePermit?.tradeRelatedPermit[2]?.trade?.value
                  ?.validfrom
                  ? true
                  : false,
                fssaiLicenseYes: permit?.tradePermit?.tradeRelatedPermit[3]?.trade?.value?.validtill
                  ? true
                  : false,
                pollutionPollutionYes: permit?.tradePermit?.tradeRelatedPermit[3]?.trade?.value
                  ?.validfrom
                  ? true
                  : false,
              });
            }
          }
        }
      }
    }
  }, [pdata?.singleFormData]);

  const getImgName = (thePath) => {
    if (thePath !== undefined && thePath !== '') {
      return thePath.split(`\\`).pop();
    }
    return 'Choose a file';
  };

  return (
    <>
      {pdata?.isLoading ? (
        <CardLoader />
      ) : pdata?.isError !== '' ? (
        <ErrorCard message={pdata.isError} />
      ) : (
        <>
          {pdata?.addNewResponse?.statusCode === 200 ? (
            <FormSuccess
              onClick={() => dispatch(changeWarehouseStatus())}
              message={pdata.addNewResponse.message}
            />
          ) : null}

          <div className='row align-items-center pb-3 mx-0 overflow-auto'>
            <div className='col-12'>
              <Formik
                enableReinitialize={true}
                initialValues={permitForm}
                // validationSchema={mhInfraSchema}
                onSubmit={(fields) => {
                  fields.landlordFileLink = imagesshow.landlordFileview
                    ? imagesshow.landlordFileview
                    : permitForm.landlordFileLink;
                  fields.ownershipFileLink = imagesshow.ownershipFileview
                    ? imagesshow.ownershipFileview
                    : permitForm.ownershipFileLink;
                  fields.completionFileLink = imagesshow.completionFileview
                    ? imagesshow.completionFileview
                    : permitForm.completionFileLink;
                  fields.laborLicenseFileLink = imagesshow.laborLicenseFileview
                    ? imagesshow.laborLicenseFileview
                    : permitForm.laborLicenseFileLink;
                  fields.electricityBillFileLink = imagesshow.electricityBillFileview
                    ? imagesshow.electricityBillFileview
                    : permitForm.electricityBillFileLink;
                  fields.panCardFileLink = imagesshow.panCardFileview
                    ? imagesshow.panCardFileview
                    : permitForm.panCardFileLink;
                  fields.FireNOCFileLink = imagesshow.FireNOCFileview
                    ? imagesshow.FireNOCFileview
                    : permitForm.FireNOCFileLink;
                  fields.cluFileLink = imagesshow.cluFileview
                    ? imagesshow.cluFileview
                    : permitForm.cluFileLink;
                  fields.occupancyFileLink = imagesshow.occupancyFileview
                    ? imagesshow.occupancyFileview
                    : permitForm.occupancyFileLink;
                  fields.buildingInsuranceFileLink = imagesshow.buildingInsuranceFileview
                    ? imagesshow.buildingInsuranceFileview
                    : permitForm.buildingInsuranceFileLink;
                  fields.buildingStabilityFileLink = imagesshow.buildingStabilityFileview
                    ? imagesshow.buildingStabilityFileview
                    : permitForm.buildingStabilityFileLink;
                  fields.floorLoadFileLink = imagesshow.floorLoadFileview
                    ? imagesshow.floorLoadFileview
                    : permitForm.floorLoadFileLink;
                  fields.approvedBuildingFileLink = imagesshow.approvedBuildingFileview
                    ? imagesshow.approvedBuildingFileview
                    : permitForm.approvedBuildingFileLink;
                  fields.photoFileLink = imagesshow.photoFileview
                    ? imagesshow.photoFileview
                    : permitForm.photoFileLink;
                  fields.profileFileLink = imagesshow.profileFileview
                    ? imagesshow.profileFileview
                    : permitForm.profileFileLink;
                  fields.gstRegistrationFileLink = imagesshow.gstRegistrationFileview
                    ? imagesshow.gstRegistrationFileview
                    : permitForm.gstRegistrationFileLink;
                  fields.panchayatNOCFileLink = imagesshow.panchayatNOCFileview
                    ? imagesshow.panchayatNOCFileview
                    : permitForm.panchayatNOCFileLink;
                  fields.shopCertificateFileLink = imagesshow.shopCertificateFileview
                    ? imagesshow.shopCertificateFileview
                    : permitForm.shopCertificateFileLink;
                  fields.tradeLicenseFileLink = imagesshow.tradeLicenseFileview
                    ? imagesshow.tradeLicenseFileview
                    : permitForm.tradeLicenseFileLink;
                  fields.factoryLicenseFileLink = imagesshow.factoryLicenseFileview
                    ? imagesshow.factoryLicenseFileview
                    : permitForm.factoryLicenseFileLink;
                  fields.fssaiLicenseFileLink = imagesshow.fssaiLicenseFileview
                    ? imagesshow.fssaiLicenseFileview
                    : permitForm.fssaiLicenseFileLink;
                  fields.pollutionPollutionFileLink = imagesshow.pollutionPollutionFileview
                    ? imagesshow.pollutionPollutionFileview
                    : permitForm.pollutionPollutionFileLink;

                  // console.log(fields, "i AM FIELD DATA ")
                  const payload = {
                    data: {
                      fields: fields,
                    },
                    warehouseId: warehouseId,
                    slug: slug,
                  };
                  // return false

                  // console.log('Final Payload send to server', payload);
                  dispatch(updateWarehousependingDetails(payload));
                }}
                render={({ errors, status, onChange, touched, values, setFieldValue }) => {
                  return (
                    <Form>
                      <div className='row bg-white pb-5 rounded mx-0 col-xxxl-11'>
                        <div className='col-12'>
                          <h5 className='pb-3 mb-3 border-bottom'>Building Related</h5>
                        </div>
                        <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                          <table className='table customTable'>
                            <tbody>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>
                                    Copy Of Lease Agreement With Landlord:
                                  </p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <Field
                                      name='landlordYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={landlordYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='landlordYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='landlordYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <Field
                                      name='landlordYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!landlordYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='landlordYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='landlordYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[0].building.value.validfrom'
                                    type='date'
                                    readOnly={viewMood}
                                    disabled={!landlordYes}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      let permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[0].building.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[0].building.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[0].building
                                        .value.validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!landlordYes ? null : errors['landlordFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[0].building.value.validtill'
                                    type='date'
                                    readOnly={viewMood}
                                    disabled={!landlordYes}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      let permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[0].building.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[0].building.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[0].building
                                        .value.validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!landlordYes ? null : errors['landlordTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='landlordFile'
                                      onChange={(e) => {
                                        handleChange(e);
                                        setImagesshow({
                                          landlordFileview: e.currentTarget.files[0].name,
                                        });
                                      }}
                                      disabled={!landlordYes || viewMood}
                                      type='file'
                                      id='landlordFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />
                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {getImgName(imagesshow.landlordFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='landlordFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!landlordYes ? null : errors['landlordFile']}
                                  </span>
                                </td>

                                {permitForm.landlordFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.landlordFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.buildingRelatedPermit[0].building.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Ownership Document Copy:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='ownershipYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={ownershipYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='ownershipYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='ownershipYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='ownershipYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!ownershipYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='ownershipYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='ownershipYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='ownershipFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!ownershipYes || viewMood}
                                      type='file'
                                      id='ownershipFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />
                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {getImgName(imagesshow.ownershipFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='ownershipFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!ownershipYes ? null : errors['ownershipFile']}
                                  </span>
                                </td>
                                {permitForm.ownershipFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.ownershipFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.ownershipDocument.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>CLU - Commercial / Warehousing:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='cluYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={cluYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='cluYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='cluYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='cluYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!cluYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='cluYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='cluYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='cluFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!cluYes || viewMood}
                                      type='file'
                                      id='cluFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />
                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.cluFile)} */}
                                      {getImgName(imagesshow.cluFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='cluFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!cluYes ? null : errors['cluFile']}
                                  </span>
                                </td>
                                {permitForm.cluFileLink !== '' &&
                                  permitForm.cluFileLink !== undefined ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.cluFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.cluCommercialWarehousing.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Completion Certificate:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='completionYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={completionYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='completionYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='completionYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='completionYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!completionYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='completionYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='completionYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='completionFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!completionYes || viewMood}
                                      type='file'
                                      id='completionFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />
                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {getImgName(imagesshow.completionFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='completionFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!completionYes ? null : errors['completionFile']}
                                  </span>
                                </td>
                                {permitForm.completionFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.completionFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.completionCertificate.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>

                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Occupancy Certificate:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='occupancyYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={occupancyYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='occupancyYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='occupancyYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='occupancyYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!occupancyYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='occupancyYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='occupancyYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='occupancyFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!occupancyYes || viewMood}
                                      type='file'
                                      id='occupancyFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.occupancyFile)} */}
                                      {getImgName(imagesshow.occupancyFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='occupancyFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!occupancyYes ? null : errors['occupancyFile']}
                                  </span>
                                </td>
                                {permitForm.occupancyFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.occupancyFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Building Insurance:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='buildingInsuranceYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={buildingInsuranceYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='buildingInsuranceYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='buildingInsuranceYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='buildingInsuranceYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!buildingInsuranceYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='buildingInsuranceYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='buildingInsuranceYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[1].building.value.validfrom'
                                    disabled={!buildingInsuranceYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      let permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[1].building.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[1].building.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[1].building
                                        .value.validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!buildingInsuranceYes ? null : errors['buildingInsuranceFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[1].building.value.validtill'
                                    disabled={!buildingInsuranceYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      let permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[1].building.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[1].building.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[1].building
                                        .value.validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!buildingInsuranceYes ? null : errors['buildingInsuranceTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='buildingInsuranceFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!buildingInsuranceYes || viewMood}
                                      type='file'
                                      id='buildingInsuranceFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {getImgName(imagesshow.buildingInsuranceFileview)}
                                      {/* {getImgName(permitForm.buildingInsuranceFile)} */}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='buildingInsuranceFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!buildingInsuranceYes ? null : errors['buildingInsuranceFile']}
                                  </span>
                                </td>
                                {permitForm.buildingInsuranceFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.buildingInsuranceFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.buildingRelatedPermit[1].building.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Building Stability Certificate:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='buildingStabilityYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={buildingStabilityYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='buildingStabilityYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='buildingStabilityYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='buildingStabilityYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!buildingStabilityYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='buildingStabilityYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='buildingStabilityYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[2].building.value.validfromfrom'
                                    type='date'
                                    readOnly={viewMood}
                                    disabled={!buildingStabilityYes}
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[2].building.value.validfromfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[2].building.value.validfromfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[2].building
                                        .value.validfromfrom
                                    }
                                    className='form-control bg-white px-4 mb-2'
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!buildingStabilityYes ? null : errors['buildingStabilityFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[2].building.value.validtill'
                                    disabled={!buildingStabilityYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[2].building.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[2].building.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[2].building
                                        .value.validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!buildingStabilityYes ? null : errors['buildingStabilityTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='buildingStabilityFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!buildingStabilityYes || viewMood}
                                      type='file'
                                      id='buildingStabilityFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.buildingStabilityFile)} */}
                                      {getImgName(imagesshow.buildingStabilityFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='buildingStabilityFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!buildingStabilityYes ? null : errors['buildingStabilityFile']}
                                  </span>
                                </td>
                                {permitForm.buildingStabilityFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.buildingStabilityFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.buildingRelatedPermit[2].building.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>

                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Fire NOC:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='FireNOCYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={FireNOCYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='FireNOCYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='FireNOCYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='FireNOCYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!FireNOCYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='FireNOCYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='FireNOCYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[3].building.value.validfrom'
                                    disabled={!FireNOCYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[3].building.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[3].building.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[3].building
                                        .value.validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!FireNOCYes ? null : errors['FireNOCFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[3].building.value.validtill'
                                    disabled={!FireNOCYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[3].building.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[3].building.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[3].building
                                        .value.validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!FireNOCYes ? null : errors['FireNOCTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='FireNOCFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!FireNOCYes || viewMood}
                                      type='file'
                                      id='FireNOCFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.FireNOCFile)} */}
                                      {getImgName(imagesshow.FireNOCFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='FireNOCFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!FireNOCYes ? null : errors['FireNOCFile']}
                                  </span>
                                </td>
                                {permitForm.FireNOCFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.FireNOCFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.buildingRelatedPermit[3].building.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>

                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>
                                    Floor Load Bearing Capacity Certificate:
                                  </p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='floorLoadYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={floorLoadYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='floorLoadYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='floorLoadYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='floorLoadYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!floorLoadYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='floorLoadYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='floorLoadYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[4].building.value.validfrom'
                                    disabled={!floorLoadYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[4].building.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[4].building.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[4].building
                                        .value.validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!floorLoadYes ? null : errors['floorLoadFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='buildingPermit.buildingRelatedPermit[4].building.value.validtill'
                                    disabled={!floorLoadYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.buildingPermit.buildingRelatedPermit[4].building.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'buildingPermit.buildingRelatedPermit[4].building.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.buildingPermit.buildingRelatedPermit[4].building
                                        .value.validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!floorLoadYes ? null : errors['floorLoadTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='floorLoadFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!floorLoadYes || viewMood}
                                      type='file'
                                      id='floorLoadFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.floorLoadFile)} */}
                                      {getImgName(imagesshow.floorLoadFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='floorLoadFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!floorLoadYes ? null : errors['floorLoadFile']}
                                  </span>
                                </td>
                                {permitForm.floorLoadFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.floorLoadFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.buildingRelatedPermit[4].building.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>

                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Approved Building Layout Copy:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='approvedBuildingYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={approvedBuildingYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='approvedBuildingYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='approvedBuildingYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='approvedBuildingYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!approvedBuildingYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='approvedBuildingYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='approvedBuildingYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='approvedBuildingFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!approvedBuildingYes || viewMood}
                                      type='file'
                                      id='approvedBuildingFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.approvedBuildingFile)} */}
                                      {getImgName(imagesshow.approvedBuildingFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='approvedBuildingFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!approvedBuildingYes ? null : errors['approvedBuildingFile']}
                                  </span>
                                </td>
                                {permitForm.approvedBuildingFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.approvedBuildingFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.approvedBuildingLayout.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Warehouse Photo Gallery:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'></div>
                                  <div className='form-check common-radio-deep-blue mx-3'></div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='photoFile'
                                      onChange={(e) => handleChange(e)}
                                      type='file'
                                      id='photoFile'
                                      className='custom-file-input'
                                      hidden=''
                                      disabled={viewMood}
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.photoFile)} */}
                                      {getImgName(imagesshow.photoFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='photoFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>{!errors['photoFile']}</span>
                                </td>
                                {permitForm.photoFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.photoFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.warehousePhotoGallery.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Warehouse Profile Pic:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-2 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'></div>
                                  <div className='form-check common-radio-deep-blue mx-3'></div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='profileFile'
                                      onChange={(e) => handleChange(e)}
                                      type='file'
                                      id='profileFile'
                                      className='custom-file-input'
                                      hidden=''
                                      disabled={viewMood}
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.profileFile)} */}
                                      {getImgName(imagesshow.profileFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='profileFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>{!errors['profileFile']}</span>
                                </td>
                                {permitForm.profileFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.profileFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='buildingPermit.warehouseProfilePic.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className='row bg-white pb-3 rounded mx-0 col-xxxl-11'>
                        <div className='col-12'>
                          <h5 className='py-3 mb-3 border-bottom'>Trade Related</h5>
                        </div>
                        <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                          <table className='table customTable'>
                            <tbody>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>GST Registration Certificate:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='gstRegistrationYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={gstRegistrationYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='gstRegistrationYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='gstRegistrationYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='gstRegistrationYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!gstRegistrationYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='gstRegistrationYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='gstRegistrationYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='gstRegistrationFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!gstRegistrationYes || viewMood}
                                      type='file'
                                      id='gstRegistrationFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.gstRegistrationFile)} */}
                                      {getImgName(imagesshow.gstRegistrationFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='gstRegistrationFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!gstRegistrationYes ? null : errors['gstRegistrationFile']}
                                  </span>
                                </td>
                                {permitForm.gstRegistrationFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.gstRegistrationFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.gstRegistrationCertificate.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Panchayat / Municipal NOC:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='panchayatNOCYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={panchayatNOCYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='panchayatNOCYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='panchayatNOCYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='panchayatNOCYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!panchayatNOCYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='panchayatNOCYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='panchayatNOCYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[0].trade.value.validfrom'
                                    disabled={!panchayatNOCYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[0].trade.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[0].trade.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[0].trade.value
                                        .validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!panchayatNOCYes ? null : errors['panchayatNOCFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[0].trade.value.validtill'
                                    disabled={!panchayatNOCYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[0].trade.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[0].trade.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[0].trade.value
                                        .validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!panchayatNOCYes ? null : errors['panchayatNOCTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='panchayatNOCFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!panchayatNOCYes || viewMood}
                                      type='file'
                                      id='panchayatNOCFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.panchayatNOCFile)} */}
                                      {getImgName(imagesshow.panchayatNOCFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='panchayatNOCFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!panchayatNOCYes ? null : errors['panchayatNOCFile']}
                                  </span>
                                </td>
                                {permitForm.panchayatNOCFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.panchayatNOCFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.tradeRelatedPermit[0].trade.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>PAN Card copy of Company:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='panCardYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={panCardYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='panCardYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='panCardYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='panCardYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!panCardYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='panCardYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='panCardYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='panCardFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!panCardYes || viewMood}
                                      type='file'
                                      id='panCardFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.panCardFile)} */}
                                      {getImgName(imagesshow.panCardFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='panCardFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!panCardYes ? null : errors['panCardFile']}
                                  </span>
                                </td>
                                {permitForm.panCardFileLink !== '' &&
                                  permitForm.panCardFileLink !== undefined ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.panCardFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.panCardOfCompany.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Electricity Bill Copy:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='electricityBillYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={electricityBillYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='electricityBillYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='electricityBillYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='electricityBillYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!electricityBillYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='electricityBillYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='electricityBillYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='electricityBillFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!electricityBillYes || viewMood}
                                      type='file'
                                      id='electricityBillFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.electricityBillFile)} */}
                                      {getImgName(imagesshow.electricityBillFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='electricityBillFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!electricityBillYes ? null : errors['electricityBillFile']}
                                  </span>
                                </td>
                                {permitForm.electricityBillFileLink !== '' &&
                                  permitForm.electricityBillFileLink !== undefined ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.electricityBillFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.electricityBill.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Shop & Establishment Certificate:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='shopCertificateYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={shopCertificateYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='shopCertificateYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='shopCertificateYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='shopCertificateYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!shopCertificateYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='shopCertificateYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='shopCertificateYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'></td>
                                <td className='pb-4'></td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='shopCertificateFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!shopCertificateYes || viewMood}
                                      type='file'
                                      id='shopCertificateFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.shopCertificateFile)} */}
                                      {getImgName(imagesshow.shopCertificateFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='shopCertificateFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!shopCertificateYes ? null : errors['shopCertificateFile']}
                                  </span>
                                </td>
                                {permitForm.shopCertificateFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.shopCertificateFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.shopAndEstablishmentCertificate.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Trade License:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='tradeLicenseYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={tradeLicenseYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='tradeLicenseYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='tradeLicenseYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='tradeLicenseYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!tradeLicenseYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='tradeLicenseYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='tradeLicenseYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[1].trade.value.validfrom'
                                    disabled={!tradeLicenseYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[1].trade.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[1].trade.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[1].trade.value
                                        .validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!tradeLicenseYes ? null : errors['tradeLicenseFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[1].trade.value.validtill'
                                    disabled={!tradeLicenseYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[1].trade.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[1].trade.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[1].trade.value
                                        .validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!tradeLicenseYes ? null : errors['tradeLicenseTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='tradeLicenseFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!tradeLicenseYes || viewMood}
                                      type='file'
                                      id='tradeLicenseFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.tradeLicenseFile)} */}
                                      {getImgName(imagesshow.tradeLicenseFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='tradeLicenseFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!tradeLicenseYes ? null : errors['tradeLicenseFile']}
                                  </span>
                                </td>
                                {permitForm.tradeLicenseFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.tradeLicenseFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.tradeRelatedPermit[1].trade.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>

                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Labor License:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-isabled={viewMood}3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='laborLicenseYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={laborLicenseYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='laborLicenseYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='laborLicenseYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='laborLicenseYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!laborLicenseYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='laborLicenseYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='laborLicenseYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[2].trade.value.validfrom'
                                    disabled={!laborLicenseYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[2].trade.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[2].trade.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[2].trade.value
                                        .validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!laborLicenseYes ? null : errors['laborLicenseFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[2].trade.value.validtill'
                                    disabled={!laborLicenseYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[2].trade.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[2].trade.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[2].trade.value
                                        .validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!laborLicenseYes ? null : errors['laborLicenseTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='laborLicenseFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!laborLicenseYes || viewMood}
                                      type='file'
                                      id='laborLicenseFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.laborLicenseFile)} */}
                                      {getImgName(imagesshow.laborLicenseFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='laborLicenseFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!laborLicenseYes ? null : errors['laborLicenseFile']}
                                  </span>
                                </td>
                                {permitForm.laborLicenseFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.laborLicenseFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.tradeRelatedPermit[2].trade.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>
                                    Factory License (htmlFor VAS activities):
                                  </p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='factoryLicenseYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={factoryLicenseYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='factoryLicenseYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='factoryLicenseYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='factoryLicenseYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!factoryLicenseYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='factoryLicenseYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='factoryLicenseYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[3].trade.value.validfrom'
                                    disabled={!factoryLicenseYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[3].trade.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[3].trade.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[3].trade.value
                                        .validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!factoryLicenseYes ? null : errors['factoryLicenseFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[3].trade.value.validtill'
                                    disabled={!factoryLicenseYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[3].trade.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[3].trade.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[3].trade.value
                                        .validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!factoryLicenseYes ? null : errors['factoryLicenseTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='factoryLicenseFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!factoryLicenseYes || viewMood}
                                      type='file'
                                      id='factoryLicenseFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.factoryLicenseFile)} */}
                                      {getImgName(imagesshow.factoryLicenseFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='factoryLicenseFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!factoryLicenseYes ? null : errors['factoryLicenseFile']}
                                  </span>
                                </td>
                                {permitForm.factoryLicenseFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.factoryLicenseFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>

                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.tradeRelatedPermit[3].trade.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>FSSAI License:</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='fssaiLicenseYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={fssaiLicenseYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='fssaiLicenseYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fssaiLicenseYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='fssaiLicenseYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!fssaiLicenseYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='fssaiLicenseYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='fssaiLicenseYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[4].trade.value.validfrom'
                                    disabled={!fssaiLicenseYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[4].trade.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[4].trade.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[4].trade.value
                                        .validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!fssaiLicenseYes ? null : errors['fssaiLicenseFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[4].trade.value.validtill'
                                    disabled={!fssaiLicenseYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[4].trade.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[4].trade.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[4].trade.value
                                        .validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!fssaiLicenseYes ? null : errors['fssaiLicenseTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='fssaiLicenseFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!fssaiLicenseYes || viewMood}
                                      type='file'
                                      id='fssaiLicenseFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.fssaiLicenseFile)} */}
                                      {getImgName(imagesshow.fssaiLicenseFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='fssaiLicenseFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!fssaiLicenseYes ? null : errors['fssaiLicenseFile']}
                                  </span>
                                </td>
                                {permitForm.fssaiLicenseFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.fssaiLicenseFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.tradeRelatedPermit[4].trade.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                              <tr className='border'>
                                <td className='mw-200px'>
                                  <p className='mb-0 pb-1'>Pollution Under Control(PUC):</p>
                                </td>
                                <td className='col-auto form-inline form-group py-4 mt-3 mb-0 border-0'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='pollutionPollutionYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={pollutionPollutionYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='pollutionPollutionYes'
                                      value={true}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='pollutionPollutionYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      name='pollutionPollutionYes'
                                      onChange={(e) => yesHandle(e)}
                                      checked={!pollutionPollutionYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='pollutionPollutionYes1'
                                      value={false}
                                      hidden=''
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='pollutionPollutionYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid from
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[5].trade.value.validfrom'
                                    disabled={!pollutionPollutionYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[5].trade.value.validfrom =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[5].trade.value.validfrom',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[5].trade.value
                                        .validfrom
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!pollutionPollutionYes
                                      ? null
                                      : errors['pollutionPollutionFrom']}
                                  </span>
                                </td>
                                <td className='pb-4'>
                                  <label htmlFor='' className='mb-0 px-4 small text-uppercase'>
                                    Valid till
                                  </label>
                                  <Field
                                    name='tradePermit.tradeRelatedPermit[5].trade.value.validtill'
                                    disabled={!pollutionPollutionYes}
                                    type='date'
                                    readOnly={viewMood}
                                    className='form-control bg-white px-4 mb-2'
                                    onChange={(event) => {
                                      const permits = { ...permitForm };
                                      permits.tradePermit.tradeRelatedPermit[5].trade.value.validtill =
                                        event.target.value;
                                      setPermitForm(permits);
                                      setFieldValue(
                                        'tradePermit.tradeRelatedPermit[5].trade.value.validtill',
                                        event.target.value,
                                      );
                                    }}
                                    value={
                                      permitForm.tradePermit.tradeRelatedPermit[5].trade.value
                                        .validtill
                                    }
                                    placeholder=''
                                  />
                                  <span className='errorMsg'>
                                    {!pollutionPollutionYes
                                      ? null
                                      : errors['pollutionPollutionTill']}
                                  </span>
                                </td>
                                <td className='pb-4 vertical-top'>
                                  <div className='custom-file'>
                                    <input
                                      name='pollutionPollutionFile'
                                      onChange={(e) => handleChange(e)}
                                      disabled={!pollutionPollutionYes || viewMood}
                                      type='file'
                                      id='pollutionPollutionFile'
                                      className='custom-file-input'
                                      hidden=''
                                    />

                                    <span
                                      id='custom-file-name'
                                      className='d-block text-center custom-file-name px-0 splitText'
                                    >
                                      {/* {getImgName(permitForm.pollutionPollutionFile)} */}
                                      {getImgName(imagesshow.pollutionPollutionFileview)}
                                    </span>
                                    <label
                                      className='custom-file-upload-label btn-deep-blue btn text-nowrap px-3'
                                      htmlFor='pollutionPollutionFile'
                                    >
                                      Upload File
                                    </label>
                                  </div>
                                  <span className='errorMsg'>
                                    {!pollutionPollutionYes
                                      ? null
                                      : errors['pollutionPollutionFile']}
                                  </span>
                                </td>
                                {permitForm.pollutionPollutionFileLink !== '' ? (
                                  <td className='text-center text-nowrape mt-4 d-flex'>
                                    <div className='btn btn-group px-0 mt-1'>
                                      <a
                                        href={permitForm.pollutionPollutionFileLink}
                                        className='btn px-3 py-2 btn-deep-blue'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        download=''
                                      >
                                        <i className='fas fa-eye'></i>
                                      </a>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                              <tr>
                                <td className='w-25px'></td>

                                <td className='w-25px'></td>
                                <td
                                  className='mw-250px form-inline form-group py-3 mb-0 pr-0'
                                  colSpan='2'
                                ></td>
                                <td className='mw-150px'>
                                  <Field
                                    disabled
                                    name='tradePermit.tradeRelatedPermit[5].trade.whsremark'
                                    type='text'
                                    className='form-control  px-4 mb-2 mr-4'
                                    placeholder='Remarks'
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className={`col-12 mt-4 ${viewMood ? 'd-none' : ''}`}>
                          <div className='row justify-content-end'>
                            {Object.keys(errors).length !== 0 ? (
                              <FormErrorCard message='Fill All Required Fields' />
                            ) : null}
                            {pdata?.isError !== '' ? (
                              <FormErrorCard message={pdata?.isError} />
                            ) : null}

                            <div className='col-auto'>
                              <button
                                type='submit'
                                disabled={pdata?.isPending}
                                className='btn btn-deep-blue add-class remove-class'
                              >
                                Save
                                {pdata?.isPending ? <Spinner animation='border' /> : null}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PermitForm;

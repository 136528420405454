import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  changeWarehouseStatus,
  updateWarehousependingDetails,
} from '../../../store/actions/vendor/warehouseList';
import * as Yup from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import './warehouseForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { CardLoader } from '../../helper/CustomLoader';

function UpdateStorageSpaceForm({ warehouseId, viewMood, slug, closeErrorMessage }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSELIST);

  const floorTypesOptions = [
    // { value: '', label: 'Select Floor' },
    { value: 'Basement', label: 'Basement' },
    { value: 'Ground Floor', label: 'Ground Floor' },
    { value: '1st Floor', label: '1st Floor' },
    { value: '2nd Floor', label: '2nd Floor' },
  ];

  const [storeForm, setStoreForm] = useState({
    storageType: 'floorarea',
    noOfShift: '',
    palletsOnFloor: '',
    totalAvailableSpace: 0,
    noOfShiftremarks: '',
    palletsOnFloorremarks: '',
    totalAvailableSpaceremarks: '',
    floors: [
      {
        floorType: floorTypesOptions[0].value,
        // floorType: '',
        floorDimension: {
          dimension: 'SQFT',
          length: '',
          breath: '',
          height: '',
          floorsRemarks: '',
        },
        availableSpace: {
          length: '',
          breath: '',
          height: '',
        },
        unit: 'FEET',
      },
    ],
    racks: [
      {
        name: 'binsracks',
        rackInfo: {
          quantity: '',
          length: '',
          breath: '',
          height: '',
          racksSlottedYesremarks: '',
        },
      },
      {
        name: 'shelveracks',
        rackInfo: {
          quantity: '',
          length: '',
          breath: '',
          height: '',
          racksShelveYesremarks: '',
        },
      },
    ],
    racksSlottedYes: true,
    racksShelveYes: true,
  });

  const storeSchema = Yup.object().shape({
    noOfShift: Yup.number().required('Required'),
    floors: Yup.array(
      Yup.object().shape({
        floorType: Yup.string().required('Required'),
        floorDimension: Yup.object().shape({
          length: Yup.number().required('Required'),
          breath: Yup.number().required('Required'),
          height: Yup.number().required('Required'),
        }),
      }),
    ),
    racks: Yup.array(
      Yup.object().shape({
        rackInfo: Yup.object().shape({
          ...(storeForm.racksSlottedYes === true
            ? {
                quantity: Yup.number().required('quantity is required.'),
                length: Yup.number().required('length is required.'),
                breath: Yup.number().required('breath is required.'),
                height: Yup.number().required('height is required.'),
              }
            : {}),
          ...(storeForm.racksShelveYes === true
            ? {
                quantity: Yup.number().required('quantity is required.'),
                length: Yup.number().required('length is required.'),
                breath: Yup.number().required('breath is required.'),
                height: Yup.number().required('height is required.'),
              }
            : {}),
        }),
      }),
    ),
  });

  const handleAddFloor = () => {
    const storeForms = { ...storeForm };
    storeForms.floors.push({
      floorType: '',
      floorDimension: {
        dimension: 'SQFT',
        length: '',
        breath: '',
        height: '',
      },
      availableSpace: {
        length: '',
        breath: '',
        height: '',
      },
      unit: 'FEET',
    });
    setStoreForm(storeForms);
  };

  const handleChangheFloorKeys = (event, name) => {
    setStoreForm({ ...storeForm, [name]: event });
  };
  const handleChangeFloorType = (event, name, index) => {
    const updatedFloors = [...storeForm.floors];
    updatedFloors[index].floorType = event.value;
    setStoreForm({ ...storeForm, floors: updatedFloors });
  };

  const handleChangeFloor = (value, name, index) => {
    const updatedFloors = [...storeForm.floors];
    updatedFloors[index].floorDimension[name] = value;
    setStoreForm({ ...storeForm, floors: updatedFloors });
  };

  const handleChangeRacks = (key, value, index) => {
    const updatedFloors = [...storeForm.racks];
    updatedFloors[index].rackInfo[key] = value;
    setStoreForm({ ...storeForm, racks: updatedFloors });
  };

  const handleDeleteFloor = (id) => {
    setStoreForm((prevState) => ({
      ...prevState,
      floors: prevState.floors.filter((_, i) => i !== id),
    }));
  };

  const handleChangeSpace = () => {};

  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/update-warehouse-rejected/${warehouseId}/it-infra`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);

  useEffect(() => {
    if (
      data?.singleFormData?.remarks === 0 &&
      data?.singleFormData?.warehouseRej?.warehouseStorageSpaces !== null &&
      data?.singleFormData?.warehouseRej?.warehouseStorageSpaces !== undefined
    ) {
      setStoreForm(data?.singleFormData?.warehouseRej?.warehouseStorageSpaces[0]);
    } else {
      if (data?.singleFormData !== null && data?.singleFormData?.warehouseRej !== null) {
        if (data?.singleFormData?.warehouseRej?.warehouseStorageSpacesRemarks !== undefined) {
          if (
            Object.keys(data?.singleFormData?.warehouseRej?.warehouseStorageSpacesRemarks)
              .length !== 0
          ) {
            setStoreForm(data?.singleFormData?.warehouseRej?.warehouseStorageSpacesRemarks);
          }
        }
      }
    }
  }, [data?.singleFormData]);

  const handleYes = (e) => {
    setStoreForm({ ...storeForm, [e.target.name]: e.target.value === 'true' });
  };

  return (
    <>
      {data?.isLoading ? (
        <CardLoader />
      ) : (
        <div className='row align-items-center pb-3 mx-0'>
          <div className='col-12'>
            <Formik
              enableReinitialize={true}
              initialValues={storeForm}
              validationSchema={storeSchema}
              onSubmit={(fields) => {
                let payloadData = {
                  data: {
                    fields: fields,
                  },
                  warehouseId: warehouseId,
                  slug: slug,
                };
                dispatch(updateWarehousependingDetails(payloadData));
              }}
              render={(values, errors, setFieldValue) => {
                const fieldClass = (fieldName, isYes = true) => {
                  return (
                    'form-control form-control-sm bg-white px-4 w-5' +
                    (isYes
                      ? values.errors[fieldName] && values.touched[fieldName]
                        ? ' is-invalid'
                        : ''
                      : '')
                  );
                };

                const floorMultiRowValidation = (id, fieldname) => {
                  if (values?.errors !== undefined && values?.errors[`floors`] !== undefined) {
                    if (values?.errors[`floors`][id]?.floorDimension !== undefined) {
                      if (values?.errors[`floors`][id]?.floorDimension[fieldname] !== undefined) {
                        return 'form-control form-control-sm bg-white px-4 w-5 is-invalid';
                      } else {
                        return 'form-control form-control-sm bg-white px-4 w-5';
                      }
                    } else {
                      return 'form-control form-control-sm bg-white px-4 w-5';
                    }
                  } else {
                    return 'form-control form-control-sm bg-white px-4 w-5';
                  }
                };

                const floorTypeValidation = (id, selected) => {
                  if (values?.errors?.floors?.[id]?.floorType !== undefined) {
                    return ' w-60 mr-5 float-left is-invalid  border-radius';
                  } else {
                    return ' w-60 mr-5 float-left  border-radius';
                  }
                };

                const rackMultiRowValidation = (id, fieldname, status) => {
                  if (values?.errors !== undefined && values?.errors[`racks`] !== undefined) {
                    if (
                      values?.errors[`racks`][id]?.rackInfo[fieldname] !== undefined &&
                      status === true
                    ) {
                      return 'form-control bg-white px-4 is-invalid';
                    } else {
                      return 'form-control bg-white px-4';
                    }
                  } else {
                    return 'form-control bg-white px-4';
                  }
                };
                console.log(storeForm, 'reducer action ** ');
                // const totalLength = 10
                // const totalBreath = 10
                const totalLength =
                  storeForm.floors !== undefined &&
                  storeForm.floors.reduce(function (acc, current) {
                    return acc + current?.floorDimension.length;
                  }, 0);

                const totalBreath =
                  storeForm.floors !== undefined &&
                  storeForm.floors.reduce(function (acc, current) {
                    return acc + current?.floorDimension.breath;
                  }, 0);
                return (
                  <>
                    <Form>
                      <div className='row bg-white rounded mx-0 col-xxxl-11 justify-content-center'>
                        <div className='form-group form-inline w-90 mb-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-1 mr-3'>
                            Storage Type:
                          </label>
                          <div className='form-check common-radio-deep-blue mx-3'>
                            <Field
                              name='storageType'
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='floor-area'
                              value='floorarea'
                              hidden=''
                              disabled={viewMood}
                            />
                            <label
                              className='common-radio-deep-blue-label pl-4'
                              htmlFor='floor-area'
                            >
                              Floor Area
                            </label>
                          </div>
                          <div className='form-check common-radio-deep-blue mx-3'>
                            <Field
                              name='storageType'
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='pallet-position'
                              value='palletposition'
                              hidden=''
                              disabled={viewMood}
                            />
                            <label
                              className='common-radio-deep-blue-label pl-4'
                              htmlFor='pallet-position'
                            >
                              Pallet Position
                            </label>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between h3 w-90'>
                          Floor Plan{' '}
                          <button
                            type='button'
                            class='btn btn-secondary'
                            onClick={handleAddFloor}
                            disabled={viewMood}
                          >
                            Add Floor
                          </button>
                        </div>
                        {storeForm.floors !== undefined &&
                          storeForm?.floors.map((field, index) => {
                            return (
                              <div className='main w-90 border my-3' key={index}>
                                <div>
                                  {index !== 0 ? (
                                    <span
                                      onClick={() => handleDeleteFloor(index)}
                                      className='ml-4 cursors float-right my-2'
                                    >
                                      <DeleteIcon />
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  <label htmlFor='' className='px-1 fontSize'>
                                    Floor *
                                  </label>

                                  <div class='w-100 d-flex justify-content-between'>
                                    <Select
                                      value={{
                                        value:
                                          values.values.floors[index]?.floorType === ''
                                            ? ''
                                            : values.values.floors[index]?.floorType,
                                        label:
                                          values.values.floors[index]?.floorType === '' ||
                                          values.values.floors[index]?.floorType === undefined
                                            ? 'Select Floor'
                                            : values.values.floors[index]?.floorType,
                                      }}
                                      className={floorTypeValidation(`${index}`)}
                                      options={floorTypesOptions}
                                      name={`floors[${index}].floorType`}
                                      onChange={(event) => {
                                        const storeForms = { ...storeForm };
                                        storeForms.floors[index].floorType = event.value;
                                        // handleChangeFloor(event, 'floorType', index);
                                        setStoreForm(storeForms);
                                        values.setFieldValue(
                                          `floors[${index}].floorType`,
                                          event.value,
                                        );
                                      }}
                                      disabled={viewMood}
                                    />

                                    <Field
                                      disabled={viewMood}
                                      name={`floors[${index}].floorDimension.floorsRemarks`}
                                      type='text'
                                      className='form-control bg-white float-center w-25'
                                      placeholder='Remarks'
                                    />
                                  </div>
                                </div>
                                <div className='d-inline-flex'>
                                  <span className='py-3 pr-4'>
                                    <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                      Length *
                                    </label>
                                    <Field
                                      name={`floors[${index}].floorDimension.length`}
                                      type='number'
                                      placeholder='Length'
                                      className={floorMultiRowValidation(`${index}`, 'length')}
                                      onChange={(event) => {
                                        const storeForms = { ...storeForm };
                                        storeForms.floors[index].floorDimension.length =
                                          event.target.value;
                                        setStoreForm(storeForms);
                                        values.setFieldValue(
                                          `floors[${index}].floorDimension.length`,
                                          event.target.value,
                                        );
                                      }}
                                      value={storeForm.floors[index].floorDimension.length}
                                      disabled={viewMood}
                                    />
                                    <ErrorMessage
                                      name={`floors[${index}].floorDimension.length`}
                                      component='div'
                                      className='invalid-feedback'
                                    />
                                  </span>
                                  <span className='py-3 px-4'>
                                    <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                      Breadth *
                                    </label>
                                    <Field
                                      type='number'
                                      name={`floors[${index}].floorDimension.breath`}
                                      placeholder='Breadth'
                                      className={floorMultiRowValidation(`${index}`, 'breath')}
                                      onChange={(event) => {
                                        const storeForms = { ...storeForm };
                                        storeForms.floors[index].floorDimension.breath =
                                          event.target.value;
                                        setStoreForm(storeForms);
                                        values.setFieldValue(
                                          `floors[${index}].floorDimension.breath`,
                                          event.target.value,
                                        );
                                      }}
                                      value={storeForm.floors[index].floorDimension.breath}
                                      disabled={viewMood}
                                    />
                                    <ErrorMessage
                                      name={`floors[${index}].floorDimension.breath`}
                                      component='div'
                                      className='invalid-feedback'
                                    />
                                  </span>
                                  <span className='py-3 px-4'>
                                    <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                      Height *
                                    </label>
                                    <Field
                                      name={`floors[${index}].floorDimension.height`}
                                      type='number'
                                      placeholder='Height'
                                      className={floorMultiRowValidation(`${index}`, 'height')}
                                      onChange={(event) => {
                                        const storeForms = { ...storeForm };
                                        storeForms.floors[index].floorDimension.height =
                                          event.target.value;
                                        setStoreForm(storeForms);
                                        values.setFieldValue(
                                          `floors[${index}].floorDimension.height`,
                                          event.target.value,
                                        );
                                      }}
                                      value={storeForm.floors[index].floorDimension.height}
                                      disabled={viewMood}
                                    />
                                    <ErrorMessage
                                      name={`floors[${index}].floorDimension.height`}
                                      component='div'
                                      className='invalid-feedback'
                                    />
                                  </span>
                                </div>
                                <div>
                                  <label htmlFor='' className='px-1 fontSize'>
                                    Available Space
                                  </label>
                                </div>
                                <div className='d-inline-flex'>
                                  <span className='pr-4'>
                                    <Field
                                      name={`floors[${index}].availableSpace.length`}
                                      type='number'
                                      placeholder='Length'
                                      className={
                                        'form-control form-control-sm bg-white px-4 w-5 mb-3'
                                      }
                                      onChange={(event) => {
                                        const storeForms = { ...storeForm };
                                        storeForms.floors[index].availableSpace.length =
                                          event.target.value;
                                        setStoreForm(storeForms);
                                        values.setFieldValue(
                                          `floors[${index}].availableSpace.length`,
                                          event.target.value,
                                        );
                                      }}
                                      value={storeForm.floors[index].availableSpace.length}
                                      disabled={viewMood}
                                    />
                                  </span>
                                  <span className='px-4'>
                                    <Field
                                      type='number'
                                      name={`floors[${index}].availableSpace.breath`}
                                      placeholder='Breadth'
                                      className={'form-control form-control-sm bg-white px-4 w-5'}
                                      onChange={(event) => {
                                        const storeForms = { ...storeForm };
                                        storeForms.floors[index].availableSpace.breath =
                                          event.target.value;
                                        setStoreForm(storeForms);
                                        values.setFieldValue(
                                          `floors[${index}].availableSpace.breath`,
                                          event.target.value,
                                        );
                                      }}
                                      value={storeForm.floors[index].availableSpace.breath}
                                      // onChange={(event) => handleChangeSpace(event, index)}
                                      disabled={viewMood}
                                    />
                                  </span>
                                  <span className='px-4'>
                                    <Field
                                      name={`floors[${index}].availableSpace.height`}
                                      type='number'
                                      placeholder='Height'
                                      className={'form-control form-control-sm bg-white px-4 w-5'}
                                      onChange={(event) => {
                                        const storeForms = { ...storeForm };
                                        storeForms.floors[index].availableSpace.height =
                                          event.target.value;
                                        setStoreForm(storeForms);
                                        values.setFieldValue(
                                          `floors[${index}].availableSpace.height`,
                                          event.target.value,
                                        );
                                      }}
                                      value={storeForm.floors[index].availableSpace.height}
                                      disabled={viewMood}
                                    />
                                  </span>
                                  <span className='px-4'>
                                    <Field
                                      disabled={viewMood}
                                      name={`totalAvailableSpaceremarks`}
                                      type='text'
                                      className='form-control bg-white px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        <div className='mb-3 w-90'>
                          <label className='mb-2'>Total Area(sqft):</label>
                          <div className='d-flex'>
                            <Field
                              className='form-control bg-white px-4 mr-3 col-2'
                              name={`totalAvailableSpace`}
                              value={totalLength * totalBreath}
                              disabled
                            />

                            {/* <Field
                              className='form-control bg-white px-4 mx-3 col-2'
                              value={0}
                              
                              disabled
                            />
                            <Field
                              className='form-control bg-white px-4 mx-3 col-2'
                              value={0}
                              disabled
                            />
                            <Field
                              className='form-control bg-white px-4 mx-3 col-2'
                              value={0}
                              disabled
                            />
                            <Field
                              className='form-control bg-white px-4 col-2'
                              value={0}
                              disabled
                            /> */}
                          </div>
                          <div></div>
                        </div>
                        <div className='my-2 w-90'>
                          <label className='mb-2'>Pallets on Floor</label>
                          <div className='d-flex justify-content-between'>
                            <Field
                              className='form-control bg-white px-4 w-50'
                              name={`palletsOnFloor`}
                              disabled={viewMood}
                            />
                            <Field
                              disabled={viewMood}
                              name={`palletsOnFloorremarks`}
                              type='text'
                              className='form-control bg-white float-center w-25'
                              placeholder='Remarks'
                            />
                          </div>
                        </div>
                        <div className='my-3 border w-90'>
                          <div className='d-flex justify-content-between mt-2'>
                            <label className='mb-2'>Racks(Slotted Angle) No of Bins:</label>
                            <Field
                              disabled={viewMood}
                              name={`racks[0].rackInfo.racksSlottedYesremarks`}
                              type='text'
                              className='form-control bg-white float-center w-25'
                              placeholder='Remarks'
                            />
                          </div>
                          <div className='d-flex'>
                            <div className='form-check common-radio-deep-blue'>
                              <Field
                                name={`racksSlottedYes`}
                                onChange={(e) => handleYes(e)}
                                checked={storeForm.racksSlottedYes}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksSlotted'
                                value={true}
                                hidden=''
                                disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-1'
                                htmlFor='racksSlotted'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                name={`racksSlottedYes`}
                                onChange={(e) => handleYes(e)}
                                checked={!storeForm.racksSlottedYes}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksSlotted1'
                                value={false}
                                hidden=''
                                disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-1'
                                htmlFor='racksSlotted1'
                              >
                                No
                              </label>
                            </div>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Quantity *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.quantity`}
                                className={rackMultiRowValidation(
                                  `${0}`,
                                  'quantity',
                                  storeForm.racksSlottedYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? storeForm.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[0].rackInfo.quantity`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Length *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.length`}
                                className={rackMultiRowValidation(
                                  `${0}`,
                                  'length',
                                  storeForm.racksSlottedYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? storeForm.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[0].rackInfo.length`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Breath *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.breath`}
                                className={rackMultiRowValidation(
                                  `${0}`,
                                  'breath',
                                  storeForm.racksSlottedYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? storeForm.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[0].rackInfo.breath`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Height *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.height`}
                                className={rackMultiRowValidation(
                                  `${0}`,
                                  'height',
                                  storeForm.racksSlottedYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? storeForm.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[0].rackInfo.height`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                          </div>
                        </div>
                        <div className='my-3 border w-90'>
                          <div className='d-flex justify-content-between mt-2'>
                            <label className='mb-2'>Racks(Shelve Racks) No of Shelve:</label>
                            <Field
                              disabled={viewMood}
                              name={`racks[1].rackInfo.racksShelveYesremarks`}
                              type='text'
                              className='form-control bg-white float-center w-25'
                              placeholder='Remarks'
                            />
                          </div>
                          <div className='d-flex'>
                            <div className='form-check common-radio-deep-blue'>
                              <Field
                                name={`racksShelveYes`}
                                checked={storeForm.racksShelveYes}
                                onChange={(e) => handleYes(e)}
                                value={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksShelveYes'
                                disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-1'
                                htmlFor='racksShelveYes'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                name={`racksShelveYes`}
                                onChange={(e) => handleYes(e)}
                                checked={!storeForm.racksShelveYes}
                                value={false}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksShelveYes1'
                                disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-1'
                                htmlFor='racksShelveYes1'
                              >
                                No
                              </label>
                            </div>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Quantity *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.quantity`}
                                className={rackMultiRowValidation(
                                  `${1}`,
                                  'quantity',
                                  storeForm.racksShelveYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? storeForm.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[1].rackInfo.quantity`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Length *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.length`}
                                className={rackMultiRowValidation(
                                  `${1}`,
                                  'length',
                                  storeForm.racksShelveYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? storeForm.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[1].rackInfo.length`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Breath *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.breath`}
                                className={rackMultiRowValidation(
                                  `${1}`,
                                  'breath',
                                  storeForm.racksShelveYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? storeForm.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[1].rackInfo.breath`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Height *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.height`}
                                className={rackMultiRowValidation(
                                  `${1}`,
                                  'height',
                                  storeForm.racksShelveYes,
                                )}
                                disabled={
                                  !viewMood
                                    ? storeForm.racksSlottedYes === true
                                      ? false
                                      : true
                                    : true
                                }
                              />
                              <ErrorMessage
                                name={`racks[1].rackInfo.height`}
                                component='div'
                                className='invalid-feedback'
                              />
                            </span>
                          </div>
                        </div>
                        <div className='my-2 w-90'>
                          <label className='mb-2'>No of Shifts</label>
                          <div className='d-flex justify-content-between'>
                            <Field
                              // className='form-control bg-white px-4 w-50'
                              name={`noOfShift`}
                              placeholder='Enter No Of Shifts'
                              onChange={(event) =>
                                handleChangheFloorKeys(event.target.value, `noOfShift`)
                              }
                              className={fieldClass('noOfShift')}
                              disabled={viewMood}
                            />
                            <Field
                              disabled={viewMood}
                              name={`noOfShiftremarks`}
                              onChange={(event) =>
                                handleChangheFloorKeys(event.target.value, `noOfShiftremarks`)
                              }
                              type='text'
                              className='form-control bg-white float-center w-25'
                              placeholder='Remarks'
                            />
                          </div>
                        </div>

                        {!viewMood && (
                          <div
                            className={`${
                              data?.isError
                                ? 'row justify-content-between'
                                : 'row justify-content-end'
                            }`}
                          >
                            {data?.isError ? (
                              <div
                                class='alert alert-warning alert-dismissible fade show'
                                role='alert'
                              >
                                {data.isError}.
                                <button
                                  type='button'
                                  class='btn-close'
                                  data-bs-dismiss='alert'
                                  aria-label='Close'
                                  onClick={closeErrorMessage}
                                ></button>
                              </div>
                            ) : null}
                            <div className='col-auto'>
                              <button
                                type='submit'
                                className='btn btn-deep-blue add-className remove-className my-2'
                              >
                                Save
                              </button>
                            </div>
                            {console.log('values', values.values)}
                          </div>
                        )}
                      </div>
                    </Form>
                  </>
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateStorageSpaceForm;

import React, { useState, useEffect, useContext } from 'react';

// import { UserContext } from '../../../components/vendor/warehouseForm/WarehousePhotoForm';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import VendorLayout from '../../../layout/VendorLayout';
import MenuDrawer from '../../../components/vendor/MenuDrawer';
import UpdateITInfraForm from '../../../components/vendor/warehouseForm/UpdateItInfraFormRejected';
import SafetySecurityForm from '../../../components/vendor/warehouseForm/SafetySecurityFormRejected';
import PermitForm from '../../../components/vendor/warehouseForm/PermitFormRejected';
import MaterialTypeForm from '../../../components/vendor/warehouseForm/MaterialTypeFormRejected';
import WarehousePhotoForm from '../../../components/vendor/warehouseForm/WarehousePhotoFormRejected';
import FormEighty from '../../../components/vendor/warehouseForm/FormEightyRejected';
// import UpdateWarehouse from '../../../components/vendor/warehouseForm/UpdateWarehouseRejected';
import UpdateMHInfraForm from '../../../components/vendor/warehouseForm/UpdateMHInfraFormRejected';
import UpdateContactDetailForm from '../../../components/vendor/warehouseForm/UpdateContactDetailFormRejected';
import UpdateStorageSpaceForm from '../../../components/vendor/warehouseForm/UpdateStorageSpaceFormRejected';

import {
  // fetchWarehouseByIdAndType,
  fetchWarehouseByIdAndTypeNewRejected,
  clearSingleDataState,
  clearaddNewResponse,
  // accordion,
} from '../../../store/actions/vendor/warehouseList';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline, Container, Paper, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { FormProvider } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Typography, TextField, Button } from '@material-ui/core';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import SpinnerLoader from '../../dashboard/rfq/SpinnerLoader';
//linear stepper outer function

const steps = [
  'Contact detail',
  'Storage space',
  'IT Infra',
  'MH Infra',
  'Safety Info',
  'Permits',
  'Material Type',
  'Warehouse Photo',
  'Form 80',
];
const RoutesSteps = steps.map((elem) => {
  return elem.toLowerCase().replace(' ', '-');
});

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));
// let flag = false;
// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// }));

// const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
//   color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
//   display: 'flex',
//   height: 22,
//   alignItems: 'center',
//   ...(ownerState.active && {
//     color: '#784af4',
//   }),
//   '& .QontoStepIcon-completedIcon': {
//     color: '#784af4',
//     zIndex: 1,
//     fontSize: 18,
//   },
//   '& .QontoStepIcon-circle': {
//     width: 8,
//     height: 8,
//     borderRadius: '50%',
//     backgroundColor: 'currentColor',
//   },
// }));

const StepperSx = {
  '& .MuiStepConnector-root': {
    left: 'calc(-50% + 40px)',
    right: 'calc(50% + 40px)',
  },
  '& .MuiStepConnector-line': {
    marginTop: '22px',
  },
};

// function QontoStepIcon(props) {
//   const { active, completed, className } = props;

//   return (
//     <QontoStepIconRoot ownerState={{ active }} className={className}>
//       {completed ? (
//         <Check className='QontoStepIcon-completedIcon' />
//       ) : (
//         <div className='QontoStepIcon-circle' />
//       )}
//     </QontoStepIconRoot>
//   );
// }

// QontoStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    * @default false
//    */
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    * @default false
//    */
//   completed: PropTypes.bool,
// };

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <VideoLabelIcon />,
    5: <GroupAddIcon />,
    6: <VideoLabelIcon />,
    7: <GroupAddIcon />,
    8: <VideoLabelIcon />,
    9: <GroupAddIcon />,
    10: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function getStepContent(step, warehouseId, slug) {
  switch (slug) {
    case 'contact-detail':
      return <UpdateContactDetailForm warehouseId={warehouseId} slug={slug} viewMood={false} />;
    case 'storage-space':
      return <UpdateStorageSpaceForm warehouseId={warehouseId} slug={slug} viewMood={false} />;
    case 'it-infra':
      return <UpdateITInfraForm warehouseId={warehouseId} slug={slug} viewMood={false} />;
    case 'mh-infra':
      return <UpdateMHInfraForm warehouseId={warehouseId} slug={slug} viewMood={false} />;
    case 'safety-info':
      return <SafetySecurityForm warehouseId={warehouseId} slug={slug} viewMood={false} />;
    case 'permits':
      return <PermitForm warehouseId={warehouseId} slug={slug} viewMood={false} />;
    case 'material-type':
      return <MaterialTypeForm warehouseId={warehouseId} slug={slug} viewMood={false} />;
    case 'warehouse-photo':
      return <WarehousePhotoForm warehouseId={warehouseId} slug={slug} viewMood={false} />;
    case 'form-80':
      return <FormEighty warehouseId={warehouseId} slug={slug} viewMood={false} />;
  }
}

const UpdateOfWarehouseRejectedNew = () => {
  const data = useSelector((state) => state.WAREHOUSELIST);

  const { warehouseId, slug } = useParams();
  const [stepError, setStepError] = useState('error message Display');
  const history = useHistory();
  //   const classes = useStyles();
  const dispatch = useDispatch();
  //linear stepper state
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  // const [checkawait, setCheckawait] = useState(true);
  // const [index, setIndex] = useState(1);

  // const [isLoading, setIsLoading] = useState(false);
  const isStepFailed = (step) => {
    // return step === activeStep
  };

  useEffect(() => {
    const index = RoutesSteps.findIndex((elem) => elem === slug);
    setActiveStep(index);
  }, [slug]);

  const handleNext = () => {
    dispatch(clearSingleDataState([]));
    if (activeStep < steps.length - 1) {
      // dispatch(accordion('formEighty'));
      setActiveStep(activeStep + 1);
    }
    history.push(
      `/vendor/warehouse/update-warehouse-rejected/${warehouseId}/${RoutesSteps[activeStep + 1]}`,
    );
  };

  const handleBack = () => {
    dispatch(clearSingleDataState([]));
    setActiveStep(activeStep - 1);
    history.push(
      `/vendor/warehouse/update-warehouse-rejected/${warehouseId}/${RoutesSteps[activeStep - 1]}`,
    );
  };

  useEffect(() => {
    if (warehouseId !== undefined && slug !== undefined) {
      dispatch(fetchWarehouseByIdAndTypeNewRejected(warehouseId, slug));
      dispatch(clearaddNewResponse([]));
    }
  }, [slug, warehouseId, dispatch]);

  return (
    <VendorLayout>
      <div className='content-admin px-2'>
        <div className='row justify-content-end align-items-center py-3 px-3 mx-0'>
          <MenuDrawer />
          <div className='col-12 '>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <h5 className='backButton mb-5 text-dark'>
                    <i
                      onClick={() => history.goBack()}
                      className='fas fa-chevron-left mr-3 cursorPointer'
                    ></i>
                    Rejected by Warehouse
                  </h5>
                  <>
                    <CssBaseline />
                    <Container component={Box} p={4} style={{ padding: '0px' }}>
                      <Paper component={Box} p={3} style={{ padding: '0px' }}>
                        <>
                          <Stack sx={{ width: '100%', paddingBottom: '15px' }} spacing={4}>
                            <Stepper
                              alternativeLabel
                              activeStep={activeStep}
                              sx={StepperSx}
                              connector={<ColorlibConnector />}
                            >
                              {steps.map((label, index) => {
                                const labelProps = {};
                                if (isStepFailed(index)) {
                                  labelProps.optional = (
                                    <Typography variant='caption' color='error'>
                                      {stepError}
                                    </Typography>
                                  );

                                  labelProps.error = true;
                                }

                                return (
                                  <Step key={label}>
                                    <Typography align='center'>{label}</Typography>
                                    {Object.keys(labelProps).length > 0 ? (
                                      <>
                                        <StepLabel {...labelProps}></StepLabel>
                                      </>
                                    ) : (
                                      <>
                                        <StepLabel
                                          {...labelProps}
                                          StepIconComponent={ColorlibStepIcon}
                                        ></StepLabel>
                                      </>
                                    )}
                                  </Step>
                                );
                              })}
                            </Stepper>
                          </Stack>

                          <>
                            <FormProvider>
                              <form>
                                {getStepContent(activeStep, warehouseId, slug)}
                                <div className='nextPreviousform pb-0'>
                                  <span>
                                    <MobileStepper
                                      variant='dots'
                                      steps={10}
                                      position='static'
                                      activeStep={activeStep}
                                      sx={{ flexGrow: 1, width: '50%', margin: '0 auto' }}
                                      nextButton={
                                        <Button
                                          size='small'
                                          onClick={() => {
                                            // setIsLoading(true);
                                            handleNext();
                                          }}
                                          // disabled={activeStep === 8}
                                          disabled={true}
                                        >
                                          Next
                                          {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                          ) : (
                                            <KeyboardArrowRight />
                                          )}
                                        </Button>
                                      }
                                      backButton={
                                        <Button
                                          size='small'
                                          onClick={() => {
                                            // setIsLoading(true);
                                            handleBack();
                                          }}
                                          disabled={activeStep === 0}
                                        >
                                          {theme.direction === 'rtl' ? (
                                            <KeyboardArrowRight />
                                          ) : (
                                            <KeyboardArrowLeft />
                                          )}
                                          Back
                                        </Button>
                                      }
                                    />
                                  </span>
                                </div>
                              </form>
                            </FormProvider>
                          </>
                        </>
                      </Paper>
                    </Container>
                  </>
                  {/*test*/}
                </div>
                {/* {console.log('routes step')} */}
                {/* {console.log(RoutesSteps)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </VendorLayout>
  );
};

export default UpdateOfWarehouseRejectedNew;

import React from 'react';
import './FrequentlyAskedQuestions.css';
const FrequentlyHeader = () => {
  return (
    <header className='headerClass'>
      <h1>FAQ Page</h1>
    </header>
  );
};

export default FrequentlyHeader;

import React, { useEffect, useState } from 'react';
import CommonSideMenu from '../components/vendor/vendormenu/CommonSideMenu';
import DynamicMenu from '../components/vendor/vendormenu/DynamicMenu';
import { sidemenuList } from '../store/actions/sidemenuAction';
import { useDispatch, useSelector } from 'react-redux';
import VendorHeader from '../components/header/VendorHeader';

const VendorLayout = (props) => {
  const sidemenuData = useSelector((state) => state.SIDEMENU_INFO);
  const dispatch = useDispatch();

  /*const  [dataast,setDataast] = useState([
        {
            "name": "Warehouse Managment hhjjhhh1",
            "label": "Warehouse Managment",
            "canRead": true,
            "Icon":"fa-warehouse",
            "canWrite": false,
            "moduleId": 1,
            "items": [
                {
                    "canRead": true,
                    "canWrite": true,
                    "Icon":"fa-warehouse",
                    "label": "My Warehouses",
                    "name": "My Warehouses",
                    "link": "/vendor/warehouse/mywarehouse?page=1"
                },
                {
                    "canRead": true,
                    "canWrite": true,
                    "Icon":"fa-warehouse",
                    "label": "Rejected by WHS",
                    "name": "Rejected by WHS",
                    "link": "/vendor/warehouse/mywarehouserejected?page=1"
                },
                {
                    "label": "",
                    "canRead": true,
                    "Icon":"fa-warehouse",
                    "canWrite": true,
                    "name": "",
                    "link": "/vendor/warehouse/warehouse-details"
                }
            ]
        },
        {
            "name": "My Users hhjjhhh22",
            "label": "My Users hhjjhhh2",
            "canRead": true,
            "Icon":"fa-user-friends",
            "canWrite": false,
            "moduleId": 2,
            "items": [
                {
                    "canRead": true,
                    "canWrite": true,
                    "label": "Manage Users",
                    "Icon":"fa-user-friends",
                    "name": "Manage Users",
                    "link": "/vendor/user/managesubuser?page=1"
                },
                {
                    "canRead": true,
                    "canWrite": true,
                    "Icon":"fa-user-friends",
                    "label": "",
                    "name": "",
                    "link": "/vendor/user/viewuserdetail/4"
                },
                {
                    "label": "Manage Roles",
                    "canRead": true,
                    "canWrite": true,
                    "Icon":"fa-user-friends",
                    "name": "Manage Roles",
                    "link": "/vendor/user/managerole"
                },
                {
                    "label": "Manage Departments",
                    "canRead": true,
                    "canWrite": true,
                    "Icon":"fa-user-friends",
                    "name": "Manage Departments",
                    "link": "/vendor/user/department"
                },
                {
                    "label": "",
                    "canRead": true,
                    "Icon":"fa-user-friends",
                    "canWrite": true,
                    "name": "",
                    "link": "/vendor/user/managerole"
                }
            ]
        },
        {
            "name": "Manage RFQs",
            "label": "Manage RFQs",
            "canRead": true,
            "Icon":"fa-file-signature",            
            "canWrite": false,
            "moduleId": 3,
            "items": [
                {
                    "canRead": true,
                    "canWrite": true,
                    "label": "Manage Users",
                    "Icon":"fa-file-signature",
                    "name": "Manage Users",
                    "link": "/vendor/user/managesubuser?page=1"
                },
                {
                    "canRead": true,
                    "canWrite": true,
                    "Icon":"fa-file-signature",
                    "label": "",
                    "name": "",
                    "link": "/vendor/user/viewuserdetail/4"
                },
                {
                    "label": "Manage Roles",
                    "canRead": true,
                    "canWrite": true,
                    "Icon":"fa-file-signature",
                    "name": "Manage Roles",
                    "link": "/vendor/user/managerole"
                },
                {
                    "label": "Manage Departments",
                    "canRead": true,
                    "canWrite": true,
                    "Icon":"fa-file-signature",
                    "name": "Manage Departments",
                    "link": "/vendor/user/department"
                },
                {
                    "label": "",
                    "canRead": true,
                    "Icon":"fa-file-signature",
                    "canWrite": true,
                    "name": "",
                    "link": "/vendor/user/managerole"
                }
            ]
        }
    ])*/

  useEffect(() => {
    // console.log(sidemenuData.sidemenu,"sidemenuData data listed")

    dispatch(sidemenuList(1));
  }, [dispatch]);

  return (
    <main className='page-wrapper'>
      <VendorHeader />
      <div className='row' style={{ marginTop: '74px' }}>
        <div className='cal-auto d-none  d-lg-block'>
          {/* <div className="col-auto  d-none d-lg-block"> */}
          {sidemenuData.sidemenu && sidemenuData.sidemenu.length > 0 ? (
            <CommonSideMenu navData={sidemenuData.sidemenu} />
          ) : (
            <DynamicMenu />
          )}
        </div>

        {props.children}
      </div>
    </main>
  );
};

export default VendorLayout;

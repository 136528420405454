import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeWarehouseStatus,
  updateWarehouseStatus,
  updateWarehousependingDetails,
} from '../../../store/actions/vendor/warehouseList';
import Spinner from 'react-bootstrap/Spinner';
import { FormSuccess } from '../../helper/FormSuccess';
import ErrorCard, { FormErrorCard } from '../../helper/ErrorCard';
import { CardLoader } from '../../helper/CustomLoader';

const FormEighty = ({ warehouseId, slug }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSELIST);
  const [jsonData, setJsonData] = useState([
    {
      heading: 'Accessibility',
      parameter: 'How wide is the Road Access to the warehouse from the main road - Ft',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Distance from Fire Station (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },

    {
      heading: 'Accessibility',
      parameter: 'How wide is the Road / Pathway inside the premises - Ft.',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Is the WH in residential area?',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Is the WH in industrial area or any WH zone??',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Distance from Police Station (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },

    {
      heading: 'Accessibility',
      parameter: 'Distance from Nearest School (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Distance from Highway Road (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },

    {
      heading: 'Accessibility',
      parameter: 'Distance from transport Hub (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Distance from warehousing Hub (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Distance from Metro/ Bus Station (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Distance from City Centre (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Distance from ICD/CFS/Port (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Distance from the Labour hub',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Public transport availability',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accessibility',
      parameter: 'Distance from Hospital (KMs)',
      input: '',
      score: '',
      whsremark: '',
    },
    //
    {
      heading: 'Accommodation',
      parameter:
        'Are additional rooms available in the compound to be used as residence for Supervisors / workers?',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accommodation',
      parameter: 'How nearest is the resdential colony for workers from the warehouse?',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Affiliation',
      parameter: 'Is there any Labour Union in the area?',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Affiliation',
      parameter: 'Is there any Transportation Union in the area?',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Construction',
      parameter: 'Construction Type (RCC / Mixed / Shed)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Construction',
      parameter: 'Construction Age (No of Years)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Construction',
      parameter: 'Transparent Sheets in the roof (for Day Light) - Qty',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Construction',
      parameter: 'Turbo Ventilators in the shed - Qty',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Construction',
      parameter: 'Flooring Type – Trimix / Normal / Epoxy',
      input: '',
      score: '',
      whsremark: '',
    },
    //
    {
      heading: 'Construction',
      parameter: 'Shutter height from the platform',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Construction',
      parameter: 'Roof height of the storage area from the platform',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Construction',
      parameter: 'How many Loading / Unloading Docks avaibale in the warehouse?',
      input: '',
      score: '',
      whsremark: '',
    },

    {
      heading: 'Accommodation',
      parameter: 'Is the WH has Hydraulic Loading/Unloading Docs?',
      input: '',
      score: '',
      whsremark: '',
    },

    {
      heading: 'Construction',
      parameter: 'Dock height from the ground',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Construction',
      parameter: 'General apearance of the floor (Good / Bad / Ugly)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accommodation',
      parameter: 'What is the size of the parking area for personal vehicles inside the coumpound?',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Accommodation',
      parameter: 'What is the size of the parking area for trucks inside the coumpound?',
      input: '',
      score: '',
      whsremark: '',
    },

    {
      heading: 'Accommodation',
      parameter: 'Is there a meeting room available in the warehouse?',
      input: '',
      score: '',
      whsremark: '',
    },

    {
      heading: 'Accommodation',
      parameter: 'Is the WH structure capable to take load of solar panels?',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Electricity & Electrical Fittings',
      parameter: 'Is there Genset Area available in the coumpound.',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Electricity & Electrical Fittings',
      parameter: 'Electricity Connection (Kw)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Electricity & Electrical Fittings',
      parameter: 'Dedicated Transformer (Kva)',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Electricity & Electrical Fittings',
      parameter: 'Electrical fittings /wirings /gadgets are with ISI marks.',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Electricity & Electrical Fittings',
      parameter: 'Electrical Earthing is available  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Electricity & Electrical Fittings',
      parameter: 'Is there is Electricity panel / Power Panel Room in the compound ?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Electricity & Electrical Fittings',
      parameter: 'No open / loose wiring in and outside of the warehouse  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Electricity & Electrical Fittings',
      parameter: 'Is the WH using any green energy? Eg - Solar power  ',
      input: '',
      score: '',
      whsremark: '',
    },

    {
      heading: 'Emergency Exit & Fire Prevention',
      parameter: 'No of Emergency Door available inside the warehouse?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Emergency Exit & Fire Prevention',
      parameter: 'Is there an assembly area earmarked during fire breakout? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Emergency Exit & Fire Prevention',
      parameter: 'What is the size of open area? - SQ Ft  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Emergency Exit & Fire Prevention',
      parameter: 'Are the fire fighting equipments in approachable limits inside the WH?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Emergency Exit & Fire Prevention',
      parameter: 'Are the fire fighting equipments inside the WH are in adequate qty? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Emergency Exit & Fire Prevention',
      parameter: 'Are the workers trained to fire fight and use fire fighting equipments? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Emergency Exit & Fire Prevention',
      parameter: 'Is the evacuation route clearly marked and visible inside the warehouse?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Emergency Exit & Fire Prevention',
      parameter:
        'Emergency alarm is clearly audible, supported by battery and alarm buttons are available inside WH? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Environment Protection',
      parameter: 'Does the compound has Green belt / plantation?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Environment Protection',
      parameter: 'Is there a Rain Water Harvesting / Drainage system? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Environment Protection',
      parameter: 'Is the open area well metteled? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Environment Protection',
      parameter: 'Are the Sewage pipes connected to a sewage Pit ?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Environment Protection',
      parameter: 'Are the Sewage pipes connected to the approved system of the area?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Environment Protection',
      parameter: 'Ground contamination due to release of / keeping of chemical is taken care of?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Environment Protection',
      parameter:
        'Is the PUC getting done of the air pollutant releasing equipments as per Govt norms?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Environment Protection',
      parameter:
        'Handling of all of type of waste inluding Hazardous waste within WH premises is as per defined norms? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Environment Protection',
      parameter:
        'Disposal of all type of waste inluding Hazardous waste within WH premises is as per defined norms?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Policy',
      parameter: 'Policy for child labour is available? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Policy',
      parameter: 'Policy for ethical ways of conducting business is available?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Policy',
      parameter: 'Policy for environmental protection is available? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Policy',
      parameter: 'Policy for worker/labour insurance, compensation and benefits is available? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Safety & Security ',
      parameter: 'Is the Warehouse inside a gated compound? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Safety & Security ',
      parameter: 'Aprox Height of the Compound Boundary Wall height - Ft  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Safety & Security ',
      parameter: 'How far is HT (high tension) electric line from the WH premise - KMs  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Safety & Security ',
      parameter: 'Are their Glass pieces on boundary wall, as a safety maeasure.  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Safety & Security ',
      parameter: 'Are there Barbed Wire over boundary wall  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Safety & Security ',
      parameter: 'No. of gates into the premises compound ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Safety & Security ',
      parameter: 'Is there a proper Security Room at the main gate ?  ',
      input: '',
      score: '',
      whsremark: '',
    },

    {
      heading: 'Warehouse Operations',
      parameter: 'Are the windows on side walls are properly pilferage proof? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Warehouse Operations',
      parameter: 'Is WH operational for 24 hrs? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Warehouse Operations',
      parameter: 'Does the WH operator provides Handling Equipments?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Warehouse Operations',
      parameter: 'Does the WH operator provides Labour? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Warehouse Operations',
      parameter: 'Are the workers/labours working in WH are permanent?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Safety & Security ',
      parameter: 'Is the complete premises under CCTV survellience? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Warehouse Operations',
      parameter: 'Is there a Weigh Bridge inside the WH premises? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter: 'Is there a Pantry area available within the warehouse premises ?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter: 'Are there washroosm for staff / workers?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter: 'Is there a creche available inside WH premises? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter: 'Are there enough lights in the open area in the night?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter: 'Source of Water in the warehouse (for regular use)? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter: 'Is drinking water available and accessible to all 24hrs?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter: 'Are the basic safety gears available for the labours/workers? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter:
        'Are the safety signs visible and adequately availble inside the WH as well as WH premises?  ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter: 'Are the first aid boxes adequately available inside the WH? ',
      input: '',
      score: '',
      whsremark: '',
    },
    {
      heading: 'Worker Facility, Health & Safety  ',
      parameter: 'Is there an Ambulance parked 24/7 inside the WH premises? ',
      input: '',
      score: '',
      whsremark: '',
    },
  ]);
  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/mywarehouse?page=1`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);

  useEffect(() => {
    if (
      data?.singleFormData?.remarks === 0 &&
      data?.singleFormData?.warehouseRej?.warehouseFormEighty !== null &&
      data?.singleFormData?.warehouseRej?.warehouseFormEighty !== undefined
    ) {
      setJsonData(data?.singleFormData?.warehouseRej?.warehouseFormEighty);
    } else {
      if (
        data?.singleFormData?.warehouseRej !== null &&
        data?.singleFormData?.warehouseRej !== undefined &&
        data?.singleFormData?.warehouseRej?.warehouseFormEightyRemarks !== undefined &&
        data?.singleFormData?.warehouseRej?.warehouseFormEightyRemarks?.formEighty !== undefined
      ) {
        setJsonData(data?.singleFormData?.warehouseRej?.warehouseFormEightyRemarks.formEighty);
      }
    }
  }, [data?.singleFormData]);

  // const eightySchema = Yup.object().shape({
  //   formEighty: Yup.array().of(
  //     Yup.object().shape({
  //       input: Yup.string()
  //         .matches(/^[A-Za-z ]*$/, 'Please enter valid')
  //         .required('Required'),

  //       remark: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid'),
  //     }),
  //   ),
  // });

  return (
    <>
      {/* Loader */}
      {data?.isLoading ? (
        <CardLoader />
      ) : data?.isError !== '' ? (
        <ErrorCard message={data?.isError} />
      ) : (
        <>
          {data?.addNewResponse?.statusCode === 200 &&
            data?.addNewResponse?.message === 'Warehouse sent for approval' ? (
            <FormSuccess
              onClick={() => {
                history.push(`/vendor/warehouse/mywarehouse?page=1`);
              }}
              message='Warehouse sent for approval'
            />
          ) : (
            ''
          )}
          <div className='row justify-content-end align-items-center py-3 px-3 mx-0 w-100'>
            <div className='col-12'>
              <Formik
                enableReinitialize={true}
                // validationSchema={eightySchema}
                initialValues={{ formEighty: jsonData }}
                onSubmit={(fields) => {
                  let paylaod = {
                    data: {
                      fields: fields,
                    },
                    warehouseId: warehouseId,
                    slug: slug,
                  };
                  dispatch(updateWarehousependingDetails(paylaod));
                  dispatch(
                    updateWarehouseStatus(warehouseId, {
                      status: 'PENDINGFORAPPROVAL',
                    }),
                  );
                }}
                render={({ values, errors, status, onChange, touched }) => {
                  return (
                    <Form>
                      <div className='row'>
                        <div className='col-12 px-0 table-responsive table-gray-admin'>
                          <table className='table'>
                            <thead>
                              <tr>
                                <th className='w-100px'>S.NO</th>
                                <th>Headings</th>
                                <th className='mw-300px'>Parameters</th>
                                <th className=''>Input</th>
                                <th className=''>Score</th>
                                <th className='text-center'>Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.formEighty.length > 0 &&
                                values.formEighty.map((eighty, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{eighty?.heading}</td>
                                      <td>{eighty?.parameter}</td>
                                      <td>
                                        <Field
                                          type='text'
                                          name={`formEighty.${index}.input`}
                                          className={'form-control border-1 w-150px'}
                                        />
                                      </td>
                                      <td className='mw-150px'>
                                        <Field
                                          disabled
                                          name={`formEighty.${index}.score`}
                                          className='form-control border-1 w-150px'
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          disabled
                                          type='text'
                                          value={eighty?.whsremark}
                                          className='form-control border-1 w-150px'
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <div className='col-12 mt-4'>
                          <div className='row justify-content-end'>
                            <div className='col-auto'>
                              <button
                                type='submit'
                                disabled={data?.isPending}
                                className='btn btn-deep-blue add-className remove-className'
                              >
                                Save
                                {data?.isPending ? <Spinner animation='border' /> : null}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormEighty;

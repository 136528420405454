import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { BsPersonFill } from 'react-icons/bs';
import { BsLockFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../store/actions/login';
import { errorMessage } from '../../store/actions/utils';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import './LoginFormNew.css';
import axiosauth from '../../api/axios-auth';
import { Redirect } from 'react-router-dom';

const SigninSchema = Yup.object().shape({
  username: Yup.number()
    // .number()
    .required('Phone Number is required'),
  password: Yup.string().required('Please Enter your password'),
});

const redirect = () => {
  return <Redirect to='/dashboard' />;
};

const redirectVendor = () => {
  return <Redirect to='/vendor' />;
};

const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};
//New LoginForm File
const LoginFormNew = (props) => {
  const { setsignUpContentModal, signUpContentModal, setsignInModal, signInModal } = props;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [sendLink, setSendLink] = useState(false);
  const [resetpassword, setResetpassword] = useState(false);
  const [resetpasswordMsg, setResetpasswordMsg] = useState('');
  const [resetPasswordOTP, setResetPasswordOTP] = useState(false);
  const [sendEmail, setSendEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const loginPending = useSelector((state) => state.LOGIN_PENDING);
  const [userType, setUserType] = useState(2);
  const [phone, setPhone] = useState({
    phone: '',
  });

  const handleChange = (e) => {
    setPhone({ ...phone, [e.target.name]: e.target.value });
  };
  const handlePasswordOTP = (e) => {
    setResetPasswordOTP({ ...resetPasswordOTP, [e.target.name]: e.target.value });
  };

  const handlePasswordNew = (e) => {
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
  };

  const changeUserType = (e) => {
    setUserType(e);
    setEmailError(null);
    dispatch(errorMessage(null));
    console.log('*************');
  };

  const changePassword = (e) => {
    if (newPassword == null) {
      setNewPasswordErrorMsg('Enter your password');
      return false;
    }
    if (newPassword.Password.length < 6) {
      setNewPasswordErrorMsg('Password too short');
      return false;
    }
    if (resetPasswordOTP == false) {
      setNewPasswordErrorMsg('Enter you  OTP');
      return false;
    }
    setNewPasswordErrorMsg(null);
    setLoading(true);
    let obj = {
      newPassword: newPassword.Password,
      resetpasswordOTP: resetPasswordOTP.otp,
    };
    axiosauth
      .put('/api/v1/user/passwordchange', obj)
      .then((response) => {
        if (response.status === 200) {
          const res = JSON.parse(response.data);
          setSendLink(true);
          setResetpassword(false);
          setLoading(false);
          setTimeout(() => {
            setsignInModal(!signInModal);
          }, 5000);
        }
      })
      .catch((error) => {
        error = JSON.parse(error?.response?.data);
        setNewPasswordErrorMsg(error.message);
        setLoading(false);
      });
  };

  const submitPhone = (e) => {
    e.preventDefault();
    if (phone.phone.length != 10) {
      setEmailError('Invalid Phone Number');
      return false;
    }
    setEmailError(null);
    setLoading(true);
    let obj = {
      phone: phone.phone,
      roleId: userType,
    };
    axiosauth
      .put('/api/v1/user/forgotpassword', obj)
      .then((response) => {
        console.log(response, '********');
        if (response.status === 200) {
          setSendLink(true);
          setResetpassword(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        error = JSON.parse(error?.response?.data);
        setEmailError(error.message);
      });
  };

  return (
    <>
      {state.loginSuccess === 'Login Successful' && state.authenticated === true
        ? redirect()
        : null}
      {state.loginSuccess === 'Login Successful' && state.vendorAuthenticated === true
        ? redirectVendor()
        : null}

      <button
        style={{ backgroundColor: 'orange', width: '7%', borderRadius: '50%', zIndex: '100' }}
        type='button'
        className='close position-absolute top-0 right-0 text-white'
        onClick={() => setsignInModal(!signInModal)}
      >
        <span aria-hidden='true'>&times;</span>
      </button>

      <div
        role='document'
        className='modal-dialog modal-dialog-centered'
        style={{ width: '80%', display: 'flex' }}
      >
        <div className='modal-content border-0'>
          <div className='modal-body py-0' style={{ padding: '0' }}>
            <div className=''>
              {!forgotPassword ? (
                <Formik
                  initialValues={{
                    username: '',
                    password: '',
                    passwordShow: false,
                  }}
                  validationSchema={SigninSchema}
                  onSubmit={(fields) => {
                    // alert("under development")
                    dispatch(userLogin(fields, userType));
                  }}
                  render={({ errors, values, status, onChange, touched, setFieldValue }) => (
                    <Form
                      onChange={() => {
                        dispatch(errorMessage(null));
                      }}
                    >
                      <div className='modal-body-right-content'>
                        <div className='heading-lg'>
                          <div className='col-12 ps-0 pe-0'>
                            {/*popup postion removed*/}
                            <h4 className='mb-2 modal-title text-center text-uppercase text-nowrap font-weight-bold'>
                              Sign In{' '}
                            </h4>
                          </div>
                        </div>

                        <div className='custom-margin-lg'>
                          <div className='col-sm-12 form-group form-group-lg mb-3 ps-0 pe-0'>
                            <label htmlFor='spaceprovideremailid'>
                              Phone Number<sup className='text-danger'>*</sup>
                            </label>

                            <div className='input-group mb-2 mr-sm-2'>
                              <Field
                                name='username'
                                placeholder='Type Here'
                                type='number'
                                className={
                                  'form-control border-style' +
                                  (errors.username && touched.username ? ' is-invalid' : '')
                                }
                              />

                              <div
                                className='position-absolute'
                                style={{
                                  top: '15px',
                                  right: '10px',
                                  fontSize: '30px',
                                  transform: 'translateY(-50%)',
                                  zIndex: '30',
                                }}
                              >
                                <BsPersonFill />
                              </div>

                              <ErrorMessage
                                name='username'
                                component='div'
                                className='invalid-feedback'
                              />
                            </div>
                          </div>

                          <div className='col-sm-12 form-group form-group-lg mb-3 ps-0 pe-0'>
                            <label htmlFor='spaceproviderpassword'>
                              Password<sup className='text-danger'>*</sup>
                            </label>

                            <div className='input-group mb-2 mr-sm-2'>
                              <Field
                                name='password'
                                placeholder='Type Here'
                                type={values.passwordShow ? 'text' : 'password'}
                                className={
                                  'form-control' +
                                  (errors.password && touched.password ? ' is-invalid' : '')
                                }
                              />
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '15px',
                                  right: '10px',
                                  transform: 'translateY(-50%)',
                                  fontSize: '22px',
                                  zIndex: '40',
                                }}
                              >
                                <BsLockFill />
                              </div>

                              <ErrorMessage
                                name='password'
                                component='div'
                                className='invalid-feedback'
                              />

                              {errors.password && touched.password ? null : (
                                <div
                                  className='input-group-prepend'
                                  style={{ height: '33px' }}
                                ></div>
                              )}
                            </div>
                          </div>

                          <span className='errorMessage ml-3'>{state.errorMessage}</span>

                          <span className='forgot-lg'>
                            <a
                              onClick={() => {
                                setForgotPassword(true);
                              }}
                              href='#forgot-password-modal'
                              data-toggle='modal'
                              data-dismiss='modal'
                              className='btn btn-link text-gray px-0 pt-0 pb-2'
                            >
                              Forgot Password?
                            </a>
                          </span>
                        </div>
                        <div className='custom-lg'>
                          <div className='text-center'>
                            <button
                              type='submit'
                              disabled={loginPending}
                              className='btn btn-deep-primary position-relative col-6 mx-auto text-nowrap'
                            >
                              {'Sign In'}
                              {loginPending ? <Spinner animation='border' /> : null}
                            </button>

                            <p className='mb-0 pt-2'>
                              Don't have an Account?
                              <button
                                style={{ all: 'unset', cursor: 'pointer' }}
                                className='btn p-0'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setsignUpContentModal(!signUpContentModal);
                                  setsignInModal(!signInModal);
                                }}
                              >
                                {' '}
                                &nbsp; <span className='btn-line-deep-primary py-1'>Sign Up</span>
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                />
              ) : (
                <>
                  {!sendLink ? (
                    <div className='forgotpassword-lg'>
                      <div className='col-12 p-0 mt-3'>
                        <div className='row mx-n2'>
                          <h5 className='modal-title text-center text-uppercase'>
                            Forgot password
                          </h5>
                          <div className='col-12'>
                            <div></div>
                          </div>
                        </div>

                        <div className='row mx-n2'>
                          <div className='col-sm-12 form-group form-group-lg mb-2 pt-3 ps-0 pe-0'>
                            <div className='disabled-true inputs'>
                              <div className='input-group d-flex phone-group rounded-0'>
                                <div className='input-group-prepend'>
                                  <span
                                    className='input-group-text p-0 bg-none'
                                    id='mobile-number-group'
                                  >
                                    <select
                                      className='form-control custom-select border-0 rounded-0'
                                      name='countryCode'
                                    >
                                      <option data-countrycode='IN' value='91'>
                                        +91
                                      </option>
                                    </select>
                                  </span>
                                </div>
                                <input
                                  name='phone'
                                  type='number'
                                  onChange={handleChange}
                                  placeholder='Mobile Number'
                                  className='form-control'
                                  maxLength='10'
                                  onInput={maxLengthCheck}
                                />
                                <div
                                  className='position-absolute'
                                  style={{
                                    top: '15px',
                                    right: '10px',
                                    fontSize: '30px',
                                    transform: 'translateY(-50%)',
                                    zIndex: '30',
                                  }}
                                >
                                  <BsPersonFill />
                                </div>
                              </div>
                            </div>
                            <p className='errorMessage' style={{ marginLeft: '60px' }}>
                              {emailError}
                            </p>
                          </div>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={submitPhone}
                            disabled={loading}
                            type='button'
                            data-dismiss='modal'
                            data-target='#sent-mail-status-modal'
                            data-toggle='modal'
                            className='btn btn-deep-primary mx-auto'
                          >
                            Send Reset Link
                            {loading ? <Spinner animation='border' /> : null}
                          </button>
                        </div>
                        <div
                          className='backtologin'
                          style={{
                            marginTop: '15px',
                            display: 'flex',
                            gap: '11.5rem',
                            color: 'orange',
                          }}
                        >
                          <span className='signup'>Sign Up</span>
                          <span className='signin'>Sign In</span>
                        </div>
                      </div>
                    </div>
                  ) : resetpassword ? (
                    <div className='col-12 p-0'>
                      <div className='row'>
                        <div className='col-12'>
                          <h6 className='modal-title text-center text-uppercase font-weight-bold mb-3'>
                            Reset your password{' '}
                          </h6>
                        </div>
                        <div className=''>
                          <div className='col-sm-12 form-group form-group-lg mb-3'>
                            <label htmlFor='spaceprovideremailid'>
                              New Password
                              <sup className='text-danger'>*</sup>
                            </label>
                            <input
                              name='Password'
                              placeholder='Type Here'
                              type='password'
                              onChange={handlePasswordNew}
                              className='form-control'
                            />
                            <div
                              className='position-absolute'
                              style={{
                                top: '38px',
                                right: '25px',
                                fontSize: '30px',
                                transform: 'translateY(-50%)',
                                zIndex: '30',
                              }}
                            >
                              <BsPersonFill />
                            </div>
                          </div>
                          <div className='col-sm-12 form-group form-group-lg mb-3'>
                            <label htmlFor='spaceprovideremailid'>
                              Enter OTP
                              <sup className='text-danger'>*</sup>
                            </label>
                            <input
                              name='otp'
                              placeholder='Type Here'
                              type='number'
                              onChange={handlePasswordOTP}
                              className='form-control'
                            />
                            <div
                              className='position-absolute'
                              style={{
                                top: '38px',
                                right: '25px',
                                fontSize: '25px',
                                transform: 'translateY(-50%)',
                                zIndex: '30',
                              }}
                            >
                              <BsLockFill />
                            </div>
                          </div>
                          <div className='text-center'>
                            <p className='errorMessage'>{newPasswordErrorMsg}</p>
                            <button
                              onClick={changePassword}
                              disabled={loading}
                              type='button'
                              data-dismiss='modal'
                              data-target='#sent-mail-status-modal'
                              data-toggle='modal'
                              className='btn btn-deep-primary my-3 mx-auto'
                            >
                              Change Password
                              {loading ? <Spinner animation='border' /> : null}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='row'>
                      <div className='col-12'>
                        <button
                          type='button'
                          className='close'
                          onClick={() => setsignInModal(!signInModal)}
                        >
                          <span aria-hidden='true'>&times;</span>
                        </button>
                        <span className='text-success'>
                          <i class='fas fa-check h1 text-success'></i>
                          Password has been changed
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginFormNew;

import React, { useEffect } from "react";
import { Link ,useParams} from "react-router-dom";
import VendorLayout from "../../layout/VendorLayout";
import MenuDrawer from "../../components/vendor/MenuDrawer";
import BrowserTitle from "../../components/helper/BrowserTitle";
// import InfoCard from "../../wrapper/vendor/vendorDashboard/InfoCard";

// import { Routes, Route, useParams } from 'react-router-dom';
// eslint-disable-next-line
import WarehouseList from "../../wrapper/vendor/vendorDashboard/WarehouseList";
import {
  vendorDashboardV2,
  // vendorDashboardCategory,
  //  vendorDashboard,
  // vendorDashboardNoOFWarehouse
} from "../../store/actions/dashboardAction";
import { useSelector, useDispatch } from "react-redux";

const VendorSlug = () => {
  const dispatch = useDispatch();
    let { slug } = useParams();
  // eslint-disable-next-line
  const data = useSelector((state) => state.DASHBOARD_INFO);


  return (
    <VendorLayout>
      <BrowserTitle title="Vendor" />

      <div className="content-admin px-1">
        <MenuDrawer />

        <div className="row justify-content-end align-items-center py-3 px-3 ml-3 mr-0">
          <div className="col-12 custom-shadow p-1 mb-1  tabs-scroll">
            {slug}
          </div>           
          <div className="col-12 px-0 pt-1">
            VendorSlug
          </div>
        </div>
      </div>
    </VendorLayout>
  );
};

export default VendorSlug;

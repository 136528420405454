import {
  WAREHOUSE_ERROR,
  WAREHOUSE_PENDING,
  WAREHOUSE_LOADING,
  ADD_NEW_WAREHOUSE,
  WAREHOUSE_LIST,
  WAREHOUSE_BY_ID,
  NEW_WAREHOUSE_INFO,
  ACCORDION,
  PANEL_ROLE,
  FINAL_SUBMIT,
  IMAGE_ID,
  WAREHOUSE_BY_ID_AND_TYPE,
  WAREHOUSE_ERROR_MSG,
} from '../../types';

import {
  ADD_WAREHOUSE,
  ADD_WAREHOUSE_NEW,
  WAREHOUSE_LIST_URL,
  WAREHOUSE_LIST_URL_NEW,
  WAREHOUSE_VENDOR_LIST_URL_NEW,
  WAREHOUSE_FORM_TRACK,
  WAREHOUSE_BY_ID_URL,
  UPDATE_WAREHOUSE_URL,
  UPDATE_CONTACT_URL,
  UPDATE_CONTACT_URL_NEW,
  UPDATE_MHINFRA_URL,
  UPDATE_MHINFRA_URL_NEW,
  UPDATE_OFFICE_SPACE_URL,
  UPDATE_OFFICE_SPACE_URL_NEW,
  UPDATE_IT_INFRA_SPACE_URL,
  UPDATE_IT_INFRA_SPACE_URL_NEW,
  UPDATE_MATERIAL_TYPE_URL,
  UPDATE_MATERIAL_TYPE_URL_NEW,
  UPDATE_SAFETY_SECURITY,
  UPDATE_SAFETY_SECURITY_NEW,
  WAREHOUSE_IMAGES,
  WAREHOUSE_IMAGES_NEW,
  FORM_EIGHTY_URL_NEW,
  FORM_EIGHTY_URL,
} from '../../../api/urls';

import axiosauth from '../../../api/axios-auth';

export function isPending(data) {
  return {
    type: WAREHOUSE_PENDING,
    payload: data,
  };
}

export function isLoading(data) {
  return {
    type: WAREHOUSE_LOADING,
    payload: data,
  };
}
export function imageid(data) {
  return {
    type: IMAGE_ID,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: WAREHOUSE_ERROR,
    payload: data,
  };
}

export function isErrorMsg(data) {
  return {
    type: WAREHOUSE_ERROR_MSG,
    payload: data,
  };
}

export function accordion(data) {
  return {
    type: ACCORDION,
    payload: data,
  };
}

export function panelRole(data) {
  return {
    type: PANEL_ROLE,
    payload: data,
  };
}

export function finalSubmit(data) {
  return {
    type: FINAL_SUBMIT,
    payload: data,
  };
}

export function addNewWarehouse(data) {
  return {
    type: ADD_NEW_WAREHOUSE,
    payload: data,
  };
}

export function newWarehouseInfo(data) {
  return {
    type: NEW_WAREHOUSE_INFO,
    payload: data,
  };
}

export function warehouseList(data) {
  return {
    type: WAREHOUSE_LIST,
    payload: data,
  };
}

export function WarehouseById(data) {
  return {
    type: WAREHOUSE_BY_ID,
    payload: data,
  };
}

export function WarehouseByIdType(data) {
  return {
    type: WAREHOUSE_BY_ID_AND_TYPE,
    payload: data,
  };
}

export const changeWarehouseStatus = () => {
  return async (dispatch) => {
    dispatch(addNewWarehouse([]));
  };
};

export const retryGetData = () => {
  return async (dispatch) => {
    dispatch(isPending(true));
  };
};

//clear all error messages
export const clearErrorMessages = () => {
  return async (dispatch) => {
    dispatch(isLoading(false));
    dispatch(isError(''));
  };
};
// ###########  Fecth All Warehouse List ########

export const clearSingleDataState = (payload) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(WarehouseByIdType(payload));
  };
};

export const clearaddNewResponse = (payload) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch({
      type: ADD_NEW_WAREHOUSE,
      payload: payload,
    });
  };
};

export const formTrack = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .get(WAREHOUSE_FORM_TRACK)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res.data));
            dispatch(isLoading(false));
          } else {
            console.log('formTrack fail');
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('formTrack-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchWarehouse = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .get(WAREHOUSE_LIST_URL)
        .then((response) => {
          // alert("Hello i am fetch result")
          // console.log('Trigger action');
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res.data));
            dispatch(isLoading(false));
          } else {
            console.log('Add Warehouse Fail');
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  Fecth All Warehouse List By Page Number ########

export const warehoseMap = (page) => {
  return async (dispatch) => {
    // const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${100}`, {
          filter: { user: JSON.parse(localStorage.getItem('userData')).id },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  Fecth All Warehouse List By Page Number ########

export const fetchWarehouseByPage = (page, limit, filter = {}) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      filter['user'] = JSON.parse(localStorage.getItem('userData'))?.id;

      axiosauth
        .get(`/api/v1/warehouses/mywarehousenew` + `?page=${page ?? 1}&limit=${limit ?? 50}`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            // alert(JSON.stringify(res));
            dispatch(warehouseList(res));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) { }
  };
};

export const fetchWarehouseByPageRejected = (page) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}`, {
          filter: {
            type: 'adminStatus',
            status: 'Rejected',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const fetchWarehouseByPageRejectedNew = (page) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_VENDOR_LIST_URL_NEW + `?page=${page}&limit=${limit}`, {
          filter: {
            type: 'adminStatus',
            status: 'Rejected',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  Fetch Warehouse By Id ############

export const fetchWarehouseById = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      console.log(WAREHOUSE_BY_ID_URL, '*****');
      // warehouses/2/type/warehouse/usertype/vendor
      axiosauth
        .get(WAREHOUSE_BY_ID_URL + `/${id}/type/warehouse/usertype/vendor`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(WarehouseById(res.data));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Details Fail');
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // console.log("Fail--->", error.message)
          // dispatch(isError(error.message))
          // dispatch(isLoading(false))
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  On Load get accordion action ############

/*export const getAccordionOnLoad = (id, type) => {
  return async (dispatch) => {
    dispatch(isLoading(true))
    dispatch(isError(""))
    dispatch(isErrorMsg(""))
    try {
        axosauth.get(`/api/v1/current/${id}/accordion`).then(response => {
          let res = JSON.parse(response.data)
          console.log("single data || ==>", res)
          if (res.statusCode === 200) {
            dispatch(accordion("warehouseContactDetails"))
            dispatch(panelRole('panel1'))
            dispatch(isLoading(false))
          }
          else {
            console.log("Warehouse Details Fail")
            dispatch(isErrorMsg(res.message))
            dispatch(isLoading(false))
          }
        }).catch((error) => {
          // console.log("Fail---> Yyyyyyy", error?.message,"^^^^^^^^^^^^");
          dispatch(isError(error.message))
          dispatch(isLoading(false))
        }).then(() => {
          dispatch(isLoading(false))
          console.log("-----always executes");
        })
      } catch (e) { }
  }
}*/

// ###########  CLEAR SINGLE PAGE REDUX DATA ############
export const clearLoader = () => {
  return async (dispatch) => {
    dispatch(isLoading(false));
  };
};

export const clearSinglePageStateData = () => {
  return async (dispatch) => {
    dispatch(WarehouseByIdType([]));
  };
};

// ###########  Fetch Warehouse By Id And Type ############

export const fetchWarehouseByIdAndTypeNew = (id, type) => {
  return async (dispatch) => {
    console.log(id, type, 'id and type section first');
    dispatch(isLoading(true));
    dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    // dispatch(isPending(true));
    try {
      console.log(id, type, 'id and type section');
      axiosauth
        .get(`/api/v1/warehousesnew/${id}/type/${type}/usertype/vendor`)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('single data || ==>', res);
          if (res.statusCode === 200) {
            // console.log(
            //   res?.data,
            //   'Y^^^^^^fetchWarehouseByIdAndType^^^^^^^',
            //   res,
            //   `/api/v1/warehouses/${id}/type/${type}/usertype/vendor`,
            // );
            dispatch(WarehouseByIdType(res?.data));

            dispatch(isLoading(false));
            // dispatch(isPending(false));
          } else {
            console.log('Warehouse Details Fail');
            dispatch(isError(res.message));
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          // console.log("Fail---> Yyyyyyy", error?.message,"^^^^^^^^^^^^");
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const fetchWarehouseByIdAndTypeNewRejected = (id, type) => {
  return async (dispatch) => {
    console.log(id, type, 'id and type section first');
    dispatch(isLoading(true));
    dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    // dispatch(isPending(true));
    // /api/v1/warehousesnew/${id}/type/pending/${type}/usertype/vendor
    try {
      console.log(id, type, 'id and type section');
      axiosauth.get(`/api/v1/warehousesnew/${id}/type/rejected/${type}/usertype/vendor`)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('single data || ==>', res);
          if (res.statusCode === 200) {
            // console.log(
            //   res?.data,
            //   'Y^^^^^^fetchWarehouseByIdAndType^^^^^^^',
            //   res,
            //   `/api/v1/warehouses/${id}/type/${type}/usertype/vendor`,
            // );
            dispatch(WarehouseByIdType(res?.data));

            dispatch(isLoading(false));
            // dispatch(isPending(false));
          } else {
            console.log('Warehouse Details Fail');
            dispatch(isError(res.message));
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          // console.log("Fail---> Yyyyyyy", error?.message,"^^^^^^^^^^^^");
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchWarehouseByIdAndType = (id, type) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      console.log('^^^^^*&*&*&*^^^^^', id, type, '^^^^^&*&*&*^^^^^');
      // alert(type)
      // if(type !=""){
      axiosauth
        .get(`/api/v1/warehouses/${id}/type/${type}/usertype/vendor`)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('single data || ==>', res);
          if (res.statusCode === 200) {
            console.log(
              res?.data,
              'Y^^^^^^fetchWarehouseByIdAndType^^^^^^^',
              res,
              `/api/v1/warehouses/${id}/type/${type}/usertype/vendor`,
            );
            dispatch(WarehouseByIdType(res?.data));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Details Fail');
            // dispatch(isErrorMsg(res.message));
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          // console.log("Fail---> Yyyyyyy", error?.message,"^^^^^^^^^^^^");
          // dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
      // }
    } catch (e) { }
  };
};

//  ###########  post Warehouse By Id And Type ############
export const updateWarehousependingDetails = (data) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(`/api/v1/warehousesremark/${parseInt(data.warehouseId)}/${data.slug}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(isLoading(false));
            dispatch(addNewWarehouse(res));
            // dispatch(isPending(false));
            // dispatch(fetchWarehouseById(data.warehouseId));
            // dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'warehouseContactDetails'));
            // dispatch(accordian('spaceForm'));
          } else {
            dispatch(isLoading(false));
            // console.log('Add Warehouse Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          // dispatch(isError(error.message));
          // dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          // dispatch(isPending(false));
          // console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  Create New Warehouse ############

export const createNewWarehouse = (data) => {
  return async (dispatch) => {
    // alert("Create new warehouse");
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      // console.log("&&&&&&&&&&&&&&&")
      axiosauth
        .post(ADD_WAREHOUSE, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log(res, '&&&&&&&&&&&&&&');
          // return false;
          if (res.statusCode === 200) {
            console.log(res);
            dispatch(newWarehouseInfo(res));
            dispatch(fetchWarehouseById(res.data.id));
            dispatch(fetchWarehouseByIdAndType(res.data.id, 'warehouseContactDetails'));
            dispatch(isPending(false));
            dispatch(panelRole('panel1'));
            dispatch(accordion('warehouseContactDetails'));
            dispatch(addNewWarehouse(res));
          } else {
            // console.log(res,"^^^^^^^^^^^^^^^")
            console.log('Add Warehouse Fail');
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          console.log('Error section while create new warehouse', error?.response?.data);
          if (error?.response?.data) {
            let res = JSON.parse(error?.response?.data);
            // dispatch(isError(res.message));
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          } else {
            // dispatch(isError(error.message));
            dispatch(isPending(false));
          }
        });
    } catch (e) {
      // console.log(e,"DDDDDDDDDDD")
      dispatch(isError('Network issue'));
      dispatch(isPending(false));
    }
  };
};

// export const clearMessageAction = () => {};
export const createNewWarehouseNew = (data) => {
  return async (dispatch) => {
    // dispatch(isPending(true));
    dispatch(isLoading(true));
    // dispatch(isError(''));
    try {
      axiosauth
        .post(ADD_WAREHOUSE_NEW, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(isLoading(false));
            // dispatch(isPending(false));
            // console.log(res,"I am responce action for first request");
            // console.log(res);
            // dispatch(newWarehouseInfo(res));
            // dispatch(fetchWarehouseById(res.data.id));
            // dispatch(fetchWarehouseByIdAndType(res.data.id, 'warehouseContactDetails'));
            // dispatch(isPending(false));
            // dispatch(panelRole('panel1'));
            // dispatch(accordion('warehouseContactDetails'));
          } else {
            console.log(res, '^^^^^^^^^^^^^^^');
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          console.log('Error section while create new warehouse', error);
          if (error?.response?.data) {
            let res = JSON.parse(error?.response?.data);
            console.log('result');
            console.log(res);
            console.log('result');
            dispatch(addNewWarehouse(res));
            dispatch(isError(res.message));
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          } else {
            dispatch(isError(error.message));
            dispatch(isPending(false));
          }
        });
    } catch (e) {
      dispatch(isLoading(false));
      // console.log(e,"DDDDDDDDDDD")
      // dispatch(isError('Network issue'));
      // dispatch(isPending(false));
    }
  };
};

// ###########  Update Warehouse ############

export const updateWarehouse = (id, data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      console.log(data, 'Before add warehouse id');
      if (id) {
        data.warehouse = `${id}`;
      }
      console.log(data, 'After add warehouse id');
      axiosauth
        .put(UPDATE_WAREHOUSE_URL + `/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            // dispatch(addNewWarehouse(res));
            // dispatch(fetchWarehouseById(id));
            // dispatch(fetchWarehouseByIdAndType(id, 'warehouseContactDetails'));
            // dispatch(isPending(false));
          } else {
            // console.log('Add Warehouse Fail');
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          // dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const conciousUpdateWarehouseContact = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(UPDATE_CONTACT_URL, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            // dispatch(addNewWarehouse(res));
            // dispatch(isPending(false));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'warehouseContactDetails'));
            // dispatch(panelRole('panel1'));
            // dispatch(accordion('storageSpace'));
          } else {
            // console.log('Add Warehouse Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          //dispatch(isError(error.message));
          // dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// ###########  UPDATE WAREHOUSE CONTACT ############

export const updateWarehouseContact = (data) => {
  return async (dispatch) => {
    // dispatch(isPending(true));
    dispatch(isLoading(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(UPDATE_CONTACT_URL_NEW, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(isLoading(false));
            dispatch(addNewWarehouse(res));
            dispatch(isLoading(false));
            // dispatch(isPending(false));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'warehouseContactDetails'));
            // dispatch(panelRole('panel2'));
            // dispatch(accordion('storageSpace'));
          } else {
            // console.log('Add Warehouse Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE WAREHOUSE STORAGE ############

export const consiousUpdateStorageSpace = (data) => {
  return async (dispatch) => {
    // alert("Hello");
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      console.log(data, 'pre and post updateStorageSpace');
      axiosauth
        .put(UPDATE_OFFICE_SPACE_URL, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            console.log(data.warehouse, '**********||||');
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchWarehouseByIdAndType(data.warehouse, 'storageSpace'));
            dispatch(isPending(false));
            dispatch(panelRole('panel8'));
            dispatch(accordion('warehouseImages'));
            // dispatch(finalSubmit(true));
            // dispatch(panelRole('panel3'))
            // dispatch(accordion("itInfrAndOfficeSpace"))
          } else {
            console.log('Add Warehouse Storage Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          // dispatch(isPending(false));
        }); /*.then(() => {
        dispatch(isPending(false))
        console.log("-----always executes");
      })*/
    } catch (e) { }
  };
};

export const consiousUpdateStorageSpaceNew = (data, warehouseId) => {
  return async (dispatch) => {
    // alert("Hello");
    // dispatch(isPending(true));
    dispatch(isLoading(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      // console.log(data, 'pre and post updateStorageSpace');
      axiosauth
        .put(`${UPDATE_OFFICE_SPACE_URL_NEW}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            console.log(data.warehouse, '**********||||');
            dispatch(addNewWarehouse(res));
            dispatch(addNewWarehouse(res)); // add temprory data to show the message and handle data
            dispatch(isLoading(false));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'storageSpace'));
            // dispatch(isPending(false));
            // dispatch(panelRole('panel8'));
            // dispatch(accordion('warehouseImages'));
            // dispatch(finalSubmit(true));
            // dispatch(panelRole('panel3'))
            // dispatch(accordion("itInfrAndOfficeSpace"))
          } else {
            // console.log('Add Warehouse Storage Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
          // dispatch(isPending(false));
        }); /*.then(() => {
        dispatch(isPending(false))
        console.log("-----always executes");
      })*/
    } catch (errors) {
      dispatch(isError(errors.message));
      // dispatch(isPending(false));
      dispatch(isLoading(false));
    }
  };
};

export const updateStorageSpace = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      console.log(data, 'pre and post updateStorageSpace');
      axiosauth
        .put(UPDATE_OFFICE_SPACE_URL, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchWarehouseByIdAndType(data.warehouse, 'storageSpace'));
            dispatch(isPending(false));
            dispatch(panelRole('panel3'));
            dispatch(accordion('itInfrAndOfficeSpace'));
          } else {
            console.log('Add Warehouse Storage Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const UpdateStorageSpaceNew = (data) => {
  return async (dispatch) => {
    // dispatch(isPending(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      console.log(data, 'pre and post updateStorageSpace');
      axiosauth
        .put(UPDATE_OFFICE_SPACE_URL_NEW, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            // dispatch(addNewWarehouse(res));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'storageSpace'));
            // dispatch(isPending(false));
            // dispatch(panelRole('panel3'));
            // dispatch(accordion('itInfrAndOfficeSpace'));
          } else {
            console.log('Add Warehouse Storage Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          // dispatch(isPending(false));
        })
        .then(() => {
          // dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// ###########  UPDATE IT INFRA ############

export const updateItInfra = (itSpace) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    // it office infra Api class
    console.log('aaaaaa', itSpace);
    try {
      axiosauth
        .put(UPDATE_IT_INFRA_SPACE_URL, itSpace)
        .then((response) => {
          let res = JSON.parse(response.data);

          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(isPending(false));
            dispatch(accordion('mhForm'));
            dispatch(fetchWarehouseById(itSpace.warehouse));
            dispatch(fetchWarehouseByIdAndType(itSpace.warehouse, 'itInfrAndOfficeSpace'));
            dispatch(panelRole('panel4'));
            dispatch(accordion('mhInfra'));
          } else {
            console.log('Add Warehouse Storage Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          console.log('ddddddddddddd');
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const updateItInfraNew = (itSpace) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    // dispatch(isPending(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    // it office infra Api class
    console.log('aaaaaa', itSpace);
    try {
      axiosauth
        .put(UPDATE_IT_INFRA_SPACE_URL_NEW, itSpace)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(isLoading(false));
            dispatch(addNewWarehouse(res));
            // dispatch(isPending(false));
            // dispatch(accordion('mhForm'));
            // dispatch(fetchWarehouseById(itSpace.warehouse));
            // dispatch(fetchWarehouseByIdAndType(itSpace.warehouse, 'itInfrAndOfficeSpace'));
            // dispatch(panelRole('panel4'));
            // dispatch(accordion('mhInfra'));
          } else {
            console.log('Add Warehouse Storage Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          console.log('ddddddddddddd');
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          // dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// ###########  UPDATE WAREHOUSE MH INFRA ############

export const updateMhInfra = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(UPDATE_MHINFRA_URL, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(isPending(false));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchWarehouseByIdAndType(data.warehouse, 'mhInfra'));
            dispatch(panelRole('panel5'));
            dispatch(accordion('safetyAndSecurity'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const updateMhInfranew = (data) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    // dispatch(isPending(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(UPDATE_MHINFRA_URL_NEW, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(isLoading(false));
            dispatch(addNewWarehouse(res));
            // dispatch(isPending(false));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'mhInfra'));
            // dispatch(panelRole('panel5'));
            // dispatch(accordion('safetyAndSecurity'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          // dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE SAFETY SECURITY FORM ############

export const updateSafetySecurity = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(UPDATE_SAFETY_SECURITY, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            // console.log(res,"$$$$$$")
            // return false
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchWarehouseByIdAndType(data.warehouse, 'safetyAndSecurity'));
            dispatch(isPending(false));
            dispatch(panelRole('panel6'));
            dispatch(accordion('buildingTradeRelatedPermit'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updateSafetySecurityNew = (data) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    // dispatch(isPending(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(UPDATE_SAFETY_SECURITY_NEW, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            console.log('hey i am updateSafetyitnew');
            dispatch(isLoading(false));
            // console.log(res,"$$$$$$")
            // return false
            // dispatch(addNewWarehouse(res));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'safetyAndSecurity'));
            // dispatch(isPending(false));
            // dispatch(panelRole('panel6'));
            // dispatch(accordion('buildingTradeRelatedPermit'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          // dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// ###########  UPDATE PERMIN FORM ############

export const updatePermit = (permitData) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/buildingtraderelated', permitData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(permitData.warehouse));
            dispatch(fetchWarehouseByIdAndType(permitData.warehouse, 'buildingTradeRelatedPermit'));
            dispatch(isPending(false));
            dispatch(panelRole('panel7'));
            dispatch(accordion('materialType'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) {
      console.log('--Try catch---', e);
    }
  };
};

export const updatePermitNew = (permitData) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));

    try {
      axiosauth
        .put('/api/v1/permit', permitData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            // dispatch(addNewWarehouse(res));
            // dispatch(fetchWarehouseById(permitData.warehouse));
            // dispatch(fetchWarehouseByIdAndType(permitData.warehouse, 'buildingTradeRelatedPermit'));
            dispatch(isLoading(false));
            // dispatch(panelRole('panel7'));
            // dispatch(accordion('materialType'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          // dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) {
      console.log('--Try catch---', e);
    }
  };
};

// ###########  UPDATE MATERIAL TYPE FORM ############

export const updateMaterialType = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(UPDATE_MATERIAL_TYPE_URL, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('ressss---->', res, '%%%%%%', data.warehouse);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchWarehouseByIdAndType(data.warehouse, 'materialType'));
            dispatch(isPending(false));
            dispatch(panelRole('panel8'));
            dispatch(accordion('warehouseImages'));
          } else {
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
            console.log('second section1');
            return false;
          }
        })
        .catch((error) => {
          console.log('Third section1');
          // return false
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          console.log('forth or all section1');
          return false;
          dispatch(isPending(false));
          console.log('-----always executes');
        });
      return false;
    } catch (e) { }
  };
};

export const updateMaterialTypeNew = (data) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    // dispatch(isPending(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(UPDATE_MATERIAL_TYPE_URL_NEW, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('ressss---->', res, '%%%%%%', data.warehouse);
          if (res.statusCode === 200) {
            dispatch(isLoading(false));
            // dispatch(addNewWarehouse(res));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'materialType'));
            // dispatch(isPending(false));
            // dispatch(panelRole('panel8'));
            // dispatch(accordion('warehouseImages'));
          } else {
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
            // console.log('second section1');
            // return false;
          }
        })
        .catch((error) => {
          console.log('Third section1');
          // return false
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          console.log('forth or all section1');
          // return false;
          // dispatch(isPending(false));
          // console.log('-----always executes');
        });
      return false;
    } catch (e) { }
  };
};
// ###########  UPDATE IMAGES ############

export const updatePhotoFileUpload = (data, id) => {
  return async (dispatch) => {
    // data.type=type
    console.log('photo===>', data);
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .post(WAREHOUSE_IMAGES, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('res===>', res);
          if (res.statusCode === 200) {
            // dispatch(fetchWarehouseByIdAndType(id, "warehouseImages"))
            dispatch(fetchWarehouseById(id));
            dispatch(imageid(res.id));
            dispatch(isPending(false));
            console.log(res, 'action abcd');
            // dispatch(accordion(""));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add photo Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE IMAGES ############

export const updatePhoto = (data, id) => {
  return async (dispatch) => {
    console.log('photo===>', data);
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(WAREHOUSE_IMAGES, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('res===>', res, 'sdsds', data.warehouse);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseByIdAndType(data.warehouse, 'warehouseImages'));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(isPending(false));
            dispatch(panelRole('panel10'));
            dispatch(accordion('formEighty'));
          } else {
            console.log('Add photo Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// ###########  UPDATENEW IMAGES ############

export const consiousUpdateUpdatePhotoNew = (data, id) => {
  return async (dispatch) => {
    // console.log('photo===>', WAREHOUSE_IMAGES_NEW, data);
    // dispatch(isPending(true));
    dispatch(isLoading(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(WAREHOUSE_IMAGES_NEW, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('res===>', res, 'sdsds', data.warehouse);
          if (res.statusCode === 200) {
            dispatch(isLoading(false));
            dispatch(addNewWarehouse(res));
            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'warehouseImages'));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(isPending(false));
            // dispatch(panelRole('panel10'));
            // dispatch(accordion('formEighty'));
          } else {
            console.log('Add photo Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const updatePhotoNew = (data, id) => {
  return async (dispatch) => {
    // console.log('photo===>', data);
    // dispatch(isPending(true));
    dispatch(isLoading(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(WAREHOUSE_IMAGES_NEW, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('res===>', res, 'sdsds', data.warehouse);
          if (res.statusCode === 200) {
            dispatch(isLoading(false));
            // dispatch(addNewWarehouse(res));

            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'warehouseImages'));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(isPending(false));
            // dispatch(panelRole('panel10'));
            // dispatch(accordion('formEighty'));
          } else {
            console.log('Add photo Fail==>', res);
            dispatch(isError(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          // dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE FORM EIGHTY ############

export const updateForm80 = (data) => {
  return async (dispatch) => {
    console.log('form80===>', data);
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(FORM_EIGHTY_URL, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(isPending(false));
            dispatch(accordion(''));
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseByIdAndType(data.warehouse, 'formEighty'));
          } else {
            console.log('Add Warehouse 80 Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const updateForm80New = (data) => {
  return async (dispatch) => {
    // console.log('form80===>', data);
    dispatch(isLoading(true));
    // dispatch(isPending(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(FORM_EIGHTY_URL_NEW, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(isLoading(false));
            // dispatch(fetchWarehouseById(data.warehouse));
            // dispatch(isPending(false));
            // dispatch(accordion(''));
            dispatch(addNewWarehouse(res));
            // dispatch(fetchWarehouseByIdAndType(data.warehouse, 'formEighty'));
          } else {
            console.log('Add Warehouse 80 Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            // dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          // dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE WAREHOUSE STATUS ############

export const conciousUpdateWarehouseStatus = (id, data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    // dispatch(finalSubmit(true))
    dispatch(isError(''));
    try {
      axiosauth
        .put('/api/v1/warehouses/warehousefinalsubmit/' + id, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            console.log('consious form action', res);
            /* dispatch(addNewWarehouse(res))
          dispatch(fetchWarehouseById(id))*/
            dispatch(isPending(false));
            dispatch(finalSubmit(true));
            // dispatch(addNewWarehouse(res))
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          console.log('error from conciousUpdateWarehouseStatus');
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        }); /*.then(() => {
        console.log("error from then ")
        dispatch(isPending(false))
        console.log("-----always executes");
      })*/
    } catch (e) { }
  };
};

export const updateWarehouseStatus = (id, data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    // dispatch(finalSubmit(true))
    dispatch(isError(''));
    try {
      axiosauth
        .put('/api/v1/warehouses/warehousefinalsubmit/' + id, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            console.log('Test Action triger abcd', res);
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(id));
            dispatch(isPending(false));
            dispatch(finalSubmit(true));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          console.log('error from updateWarehouseStatus');
          // dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          console.log('error from then ');
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import memoize from 'memoize-one';
// import Maps from './../warehouse/Maps1';
import Maps from './../warehouse/Maps';
import { useHistory } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { CDBContainer } from 'cdbreact';
import Button from 'react-bootstrap/Button';
import VendorLayout from '../../layout/VendorLayout';
import MenuDrawer from '../../components/vendor/MenuDrawer';
import BrowserTitle from '../../components/helper/BrowserTitle';
import InfoCard from '../../wrapper/vendor/vendorDashboard/InfoCard';
import DataTable from 'react-data-table-component';
// import WarehouseList from '../../wrapper/vendor/vendorDashboard/WarehouseList';

import { vendorDashboardV2, graphandsummary } from '../../store/actions/dashboardAction';

import { useSelector, useDispatch } from 'react-redux';

const paginationOptions = {
  rowsPerPageText: 'Rows per page:',
  rangeSeparatorText: 'of',
  noRowsPerPage: false,
  selectAllRowsItem: false,
  selectAllRowsItemText: 'All',
};
const Index = () => {
  const dispatch = useDispatch();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [componentType, setComponentType] = useState('summary');
  const history = useHistory();
  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
  const dashboardData = useSelector((state) => state.DASHBOARD_INFO);
  const [searchText, setSearchText] = React.useState('');
  const [category, setCategory] = React.useState('');
  const tableCustomStyles = {
    headRow: {
      style: {
        color: '#223336',
        backgroundColor: '#e7eef0',
        fontSize: '13px',
      },
    },
    rows: {
      style: {
        color: 'STRIPEDCOLOR',
        backgroundColor: 'STRIPEDCOLOR',
      },
      stripedStyle: {
        color: 'NORMALCOLOR',
        backgroundColor: 'NORMALCOLOR',
      },
    },
  };

  const columns = memoize((handleAction) => [
    { name: 'State', selector: 'warehouse', sortable: true },
    { name: 'Total Warehouse', selector: 'totalwarehouse', sortable: true },
    { name: 'Total Space', selector: 'totalspace', sortable: true },
    { name: 'Available Space', selector: 'availableSpace', sortable: true },
    // {
    //   name: 'Action',
    //   cell: (event) => (
    //     <Button raised primary onClick={() => handleAction(event)}>
    //       {' '}
    //       View
    //     </Button>
    //   ),
    //   selector: 'view',
    //   sortable: true,
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ]);

  const changeTypeAction = () => {
    if (componentType === 'summary') {
      setComponentType('map');
    } else {
      setComponentType('summary');
    }
  };

  const [cdbcard, setCdbcard] = useState({
    labels: [],
    datasets: [
      {
        label: 'State Warehouse Show With Graph',
        fill: true,
        lineTension: 0,
        backgroundColor: 'rgba(194, 116, 161, 0.5)',
        // borderColor: 'rgb(194, 116, 161)',
        // borderCapStyle: 'butt',
        borderDash: [],
        // borderDashOffset: 0.0,
        // borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        beginAtZero: true,
        // pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(71, 225, 167, 0.5)',
        pointHoverBorderColor: 'rgb(71, 225, 167)',
        // pointHoverBorderWidth: 2,
        // pointRadius: 1,
        // pointHitRadius: 10,
        data: [],
      },
    ],
  });

  useEffect(() => {
    dispatch(graphandsummary());
    var oldDataSet = cdbcard?.datasets[0];
    var newData = dashboardData?.vendorDemography?.graphdata?.graphAry;
    var newDataSet = {
      ...oldDataSet,
    };
    newDataSet.data = newData;
    var newState = {
      ...cdbcard,
      labels: dashboardData?.vendorDemography?.graphdata?.whStateAry,
      datasets: [newDataSet],
    };
    console.log(newState, '||newState||');
    setCdbcard(newState);
    // }, 2000);
  }, [componentType]);



  // warehouse,totalspace,availablespace,view
  // const tableDataRecord = [
  //   {
  //     warehouse: "warewhouse Name",
  //     totalspace: 145,
  //     availableSpace: 520,
  //     view: "view Section",
  //   }, {
  //     warehouse: "warewhouse Name2",
  //     totalspace: 145,
  //     availableSpace: 520,
  //     view: "view Section",
  //   }, {
  //     warehouse: "warewhouse Name3",
  //     totalspace: 145,
  //     availableSpace: 520,
  //     view: "view Section",
  //   }, {
  //     warehouse: "warewhouse Name4",
  //     totalspace: 145,
  //     availableSpace: 520,
  //     view: "view Section",
  //   }, {
  //     warehouse: "warewhouse Name5",
  //     totalspace: 145,
  //     availableSpace: 520,
  //     view: "view Section",
  //   }
  // ]
  const tableDataRecord =
    dashboardData.vendorDemography !== null && dashboardData.vendorDemography !== undefined
      ? dashboardData?.vendorDemography?.graphAndSummary
      : [];
  const tableDataRecords =
    dashboardData.vendorDemography !== null && dashboardData.vendorDemography !== undefined
      ? dashboardData?.vendorDemography?.warehouseList
      : [];
  console.log(tableDataRecord, "Hello abcd trigger", tableDataRecord.length)
  const demoData = [
    { data: tableDataRecords.length, key: 'My Warehouses', url: '/', imgIcon: null },
    // {"data":27,"key":"My Operations","url":"/","imgIcon":null},
    // {"data":1,"key":"My Inventory","url":"/","imgIcon":null},
    // {"data":2,"key":"My Compliances","url":"/","imgIcon":null},
    // {"data":4,"key":"My Productivity","url":"/","imgIcon":null},
    // {"data":5,"key":"My Audit","url":"/","imgIcon":null},
    { data: 0, key: 'Rfq', url: '/', imgIcon: null },
    { data: 0, key: 'Users', url: '/', imgIcon: null },
    { data: 0, key: 'orders', url: '/', imgIcon: null },
    // {"data":35,"key":"RFQs","url":"/","imgIcon":null},
    // {"data":102,"key":"Documents","url":"/","imgIcon":null},
    // {"data":2,"key":"Orders","url":"/","imgIcon":null}
  ];

  const dashboardIcons = [
    {
      id: 1,
      imgIcon: '/dashboard-icons/warehouse.png',
    },
    {
      id: 2,
      imgIcon: '/dashboard-icons/user-circle-gear_.png',
    },
    {
      id: 3,
      imgIcon: '/dashboard-icons/myReports.png',
    },
    {
      id: 4,
      imgIcon: '/dashboard-icons/Document Edit_.png',
    },
    {
      id: 5,
      imgIcon: '/dashboard-icons/review-icon.png',
    },
    {
      id: 6,
      imgIcon: '/dashboard-icons/myaudit.png',
    },
    {
      id: 7,
      imgIcon: '/dashboard-icons/query_.png',
    },
    {
      id: 8,
      imgIcon: '/dashboard-icons/services_.png',
    },
    {
      id: 9,
      imgIcon: '/dashboard-icons/analytics_.png',
    },
    {
      id: 10,
      imgIcon: '/dashboard-icons/icon _note_.png',
    },
    {
      id: 11,
      imgIcon: '/dashboard-icons/My-Set-up.png',
    },
    {
      id: 12,
      imgIcon: '/dashboard-icons/icon _idea alt_.png',
    },
  ];

  // console.log( dashboardData.vendorDemography.graphdata, "||tableDataRecord||", tableDataRecord.length)

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];

    for (let i = 1; i <= page; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`btn-pagination ${currentPage === i ? 'active' : ''}`}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>,
      );
    }

    return (
      <div className='pagination-container'>
        <button
          className='btn-pagination'
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          ‹
        </button>
        {pageButtons}
        <button
          className='btn-pagination'
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          ›
        </button>
      </div>
    );
  };
  const filteredData = tableDataRecord.filter(
    (item) =>
      item.warehouse.toLowerCase().includes(searchText.toLowerCase()) &&
      (category ? item.department === category : true),
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage,
  );
  console.log(paginatedData, "||paginatedData||")
  const updateState = (state) => {
    history.replace(`/vendor/warehouse`);
  };

  return (
    <VendorLayout>
      <BrowserTitle title='Vendor' />
      <div className='content-admin px-1'>
        <MenuDrawer />
        <div className='row justify-content-end align-items-center py-3  ml-3 mr-0'>
          <div className='col-12 custom-shadow p-1 mb-1 pl-4 mr-3 tabs-scroll'>
            <ul className='nav nav-pills admin-tabs-blue' id='pills-tab' role='tablist'>
              <li className='nav-item vendorActive'>
                <Link to='/vendor' className='nav-link  text-uppercase'>
                  Overview
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/vendor/warehouse' className='nav-link text-uppercase'>
                  Warehouse
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/vendor/wms' className='nav-link text-uppercase'>
                  WMS
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-12 px-0 '>
            <div className='tab-content' id='pills-tabContent'>
              <div
                className='tab-pane fade show active'
                id='pills-warehouse-summary'
                role='tabpanel'
                aria-labelledby='pills-warehouse-summary-tab'
              >
                <div className='dashboard-cards'>
                  <div className='row pl-1 mr-4'>
                    {demoData &&
                      demoData.length > 0 &&
                      demoData.map((item, index) => (
                        <InfoCard
                          redirect={item?.url}
                          key={index}
                          data={item}
                          index={index}
                          title={item?.key}
                          // imgIcon={item?.imageUrl}
                          dashboardIcon={dashboardIcons[index]}
                        />
                      ))}
                    <div class='col pr-0'>
                      <div
                        className='col-md-12 mb-4 new-card-box summaryGraph'
                        style={{ borderRadius: '1%', height: '68vh' }}
                      >
                        <div class='d-flex justify-content-between'>
                          <h5 className='nav-link text-uppercase mb-0'>State-wise Warehouses</h5>
                          <div class='warehouseSummaryAndGraph'>
                            <div className='searchbarToggle d-flex'>
                              <div
                                className='px-2 h5 text-muted mt-2'
                                style={{ 'font-size': '16px' }}
                              >
                                Graph
                              </div>
                              <Switch
                                onClick={changeTypeAction}
                                checked={componentType === 'map' ? false : true}
                              />
                              <div
                                className='px-2 h5 text-muted mt-2'
                                style={{ 'font-size': '16px' }}
                              >
                                Table
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='section'>
                          {componentType === 'summary' ? (
                            <>
                              <DataTable
                                // columns={columns}
                                columns={columns(updateState)}
                                data={paginatedData}
                                pagination
                                paginationServer
                                paginationTotalRows={filteredData.length}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationComponentOptions={paginationOptions}
                                paginationComponent={customPagination}
                                customStyles={tableCustomStyles}
                              // highlightOnHover
                              // pointerOnHover
                              // subHeader
                              />
                            </>
                          ) : (
                            <CDBContainer>
                              <Bar data={cdbcard} options={{ responsive: true }} />
                            </CDBContainer>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class='col pr-0'>
                      <div className='col-md-12 mb-4 new-card-box dashboardMap px-0'>
                        <div className='dashboard-cards'>
                          <Maps />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VendorLayout>
  );
};

export default Index;

import React, { useState, useEffect } from 'react';
import {
  updateItInfra,
  changeWarehouseStatus,
  updateWarehousependingDetails,
} from '../../../store/actions/vendor/warehouseList';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { FormSuccess } from '../../helper/FormSuccess';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ErrorCard, { FormErrorCard } from '../../helper/ErrorCard';
import { CardLoader } from '../../helper/CustomLoader';

const UpdateITInfraForm = ({ warehouseId, slug, viewMood }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSELIST);

  const [yesForm, setYesForm] = useState({
    desktopYes: false,
    printerYes: false,
    printerMultiFunctionYes: false,
    upsYes: false,
    scannerYes: false,
    broadbandYes: false,
    landlineYes: false,
    wmsYes: false,
    airConditionerYes: false,
    workStationsYes: false,
    tablesYes: false,
    cabinsYes: false,
    meetingRoomsYes: false,
    recordRoomsYes: false,
    strongRoomsYes: false,
    guardOfficeYes: false,
  });

  const {
    desktopYes,
    printerYes,
    printerMultiFunctionYes,
    upsYes,
    scannerYes,
    broadbandYes,
    landlineYes,
    wmsYes,
    airConditionerYes,
    workStationsYes,
    tablesYes,
    cabinsYes,
    meetingRoomsYes,
    recordRoomsYes,
    strongRoomsYes,
    guardOfficeYes,
  } = yesForm;

  const handleChange = (e) => {
    setYesForm({ ...yesForm, [e.target.name]: e.target.value === 'true' });
  };

  const [itInfra, setItInfra] = useState({
    itInfra: {
      desktop: {
        value: '',
        whsremark: '',
      },
      printer: {
        value: '',
        whsremark: '',
      },
      printerMultifunction: {
        value: '',
        whsremark: '',
      },
      upsAndInverter: {
        value: '',
        whsremark: '',
      },
      broadBand: {
        value: '',
        whsremark: '',
      },
      scanner: {
        value: '',
        whsremark: '',
      },
      telephone: {
        value: '',
        whsremark: '',
      },
      wms: {
        whsremark: '',
      },
      itinfra_other: {
        whsremark: '',
      },
    },
    officeSpace: {
      area: {
        value: '',
        whsremark: '',
      },
      airConditioner: {
        value: '',
        whsremark: '',
      },
      workStations: {
        value: '',
        whsremark: '',
      },
      chairs: {
        value: '',
        whsremark: '',
      },
      tables: {
        value: '',
        whsremark: '',
      },
      cabins: {
        value: '',
        whsremark: '',
      },
      meetingRooms: {
        value: '',
        whsremark: '',
      },
      recordRooms: {
        value: '',
        whsremark: '',
      },
      strongRooms: {
        value: '',
        whsremark: '',
      },
      securityGuardOfficeTable: {
        value: '',
        whsremark: '',
      },
      electricLoad: {
        value: '',
        whsremark: '',
      },
      officespace_other: {
        value: '',
        whsremark: '',
      },
    },
  });

  useEffect(() => {
    if (data?.addNewResponse?.statusCode === 200) {
      history.push(`/vendor/warehouse/update-warehouse-rejected/${warehouseId}/mh-infra`);
      dispatch(changeWarehouseStatus());
    }
  }, [data, history, dispatch]);

  useEffect(() => {
    console.log('warehousity itinfra', data?.singleFormData);
    if (
      data?.singleFormData?.remarks === 0 &&
      data?.singleFormData?.warehouseRej?.warehouseItInfras !== null &&
      data?.singleFormData?.warehouseRej?.warehouseItInfras !== undefined
    ) {
      console.log(data?.singleFormData?.warehouseRej, 'warehouse action trigger');
      setItInfra({
        itInfra: {
          desktop: {
            value: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra?.desktop,
            whsremark: '',
          },
          printer: {
            value: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra?.printer,
            whsremark: '',
          },
          printerMultifunction: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra
                ?.printerMultifunction,
            whsremark: '',
          },
          upsAndInverter: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra?.upsAndInverter,
            whsremark: '',
          },
          broadBand: {
            value: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra?.broadBand,
            whsremark: '',
          },
          scanner: {
            value: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra?.scanner,
            whsremark: '',
          },
          telephone: {
            value: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra?.telephone,
            whsremark: '',
          },
          wms: {
            whsremark: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra?.wms,
          },
          itinfra_other: {
            whsremark: '',
          },
        },
        officeSpace: {
          area: {
            value: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.area,
            whsremark: '',
          },
          airConditioner: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.airConditioner,
            whsremark: '',
          },
          workStations: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.workStations,
            whsremark: '',
          },
          chairs: {
            value: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.chairs,
            whsremark: '',
          },
          tables: {
            value: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.tables,
            whsremark: '',
          },
          cabins: {
            value: data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.cabins,
            whsremark: '',
          },
          meetingRooms: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.meetingRooms,
            whsremark: '',
          },
          recordRooms: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.recordRooms,
            whsremark: '',
          },
          strongRooms: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.strongRooms,
            whsremark: '',
          },
          securityGuardOfficeTable: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace
                ?.securityGuardOfficeTable,
            whsremark: '',
          },
          electricLoad: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace?.electricLoad,
            whsremark: '',
          },
          officespace_other: {
            value:
              data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace
                ?.officespace_other,
            whsremark: '',
          },
        },
      });

      setYesForm({
        desktopYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra.desktop !== ''
            ? true
            : false,
        printerYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0].itInfra.printer !== ''
            ? true
            : false,
        printerMultiFunctionYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra.printerMultifunction !==
          ''
            ? true
            : false,
        upsYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra.upsAndInverter !== ''
            ? true
            : false,
        scannerYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra.scanner !== ''
            ? true
            : false,
        broadbandYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra.broadBand !== ''
            ? true
            : false,
        landlineYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra.telephone !== ''
            ? true
            : false,
        wmsYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.itInfra.wms !== ''
            ? true
            : false,
        airConditionerYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace.airConditioner !==
          ''
            ? true
            : false,
        workStationsYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace.workStations !== ''
            ? true
            : false,
        tablesYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace.tables !== ''
            ? true
            : false,
        cabinsYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace.cabins !== ''
            ? true
            : false,
        meetingRoomsYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace.meetingRooms !== ''
            ? true
            : false,
        recordRoomsYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace.recordRooms !== ''
            ? true
            : false,
        strongRoomsYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace.strongRooms !== ''
            ? true
            : false,
        guardOfficeYes:
          data?.singleFormData?.warehouseRej?.warehouseItInfras[0]?.officeSpace
            .securityGuardOfficeTable !== ''
            ? true
            : false,
      });
    } else {
      if (data?.singleFormData !== null && data?.singleFormData?.warehouseRej !== null) {
        if (data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks !== undefined) {
          if (
            Object.keys(data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks).length !== 0
          ) {
            console.log(
              'IT INFRA BOOKING',
              data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks,
            );
            setYesForm({
              desktopYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.itInfra.desktop
                  ?.value !== ''
                  ? true
                  : false,
              printerYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.itInfra.printer
                  ?.value !== ''
                  ? true
                  : false,
              printerMultiFunctionYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.itInfra
                  .printerMultifunction?.value !== ''
                  ? true
                  : false,
              upsYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.itInfra.upsAndInverter
                  ?.value !== ''
                  ? true
                  : false,
              scannerYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.itInfra.scanner
                  ?.value !== ''
                  ? true
                  : false,
              broadbandYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.itInfra.broadBand
                  ?.value !== ''
                  ? true
                  : false,
              landlineYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.itInfra.telephone
                  ?.value !== ''
                  ? true
                  : false,
              wmsYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.itInfra.wms?.value !==
                ''
                  ? true
                  : false,
              airConditionerYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.officeSpace
                  .airConditioner?.value !== ''
                  ? true
                  : false,
              workStationsYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.officeSpace
                  .workStations?.value !== ''
                  ? true
                  : false,
              tablesYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.officeSpace.tables
                  ?.value !== ''
                  ? true
                  : false,
              cabinsYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.officeSpace.cabins
                  ?.value !== ''
                  ? true
                  : false,
              meetingRoomsYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.officeSpace
                  .meetingRooms?.value !== ''
                  ? true
                  : false,
              recordRoomsYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.officeSpace
                  .recordRooms?.value !== ''
                  ? true
                  : false,
              strongRoomsYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.officeSpace
                  .strongRooms?.value !== ''
                  ? true
                  : false,
              guardOfficeYes:
                data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks?.officeSpace
                  .securityGuardOfficeTable?.value !== ''
                  ? true
                  : false,
            });
            setItInfra(data?.singleFormData?.warehouseRej?.warehouseItInfrasRemarks);
          }
        }
      }
    }
  }, [data?.singleFormData]);

  return (
    <>
      {/* Loader */}

      {data?.isLoading ? (
        <CardLoader />
      ) : data?.isError !== '' ? (
        <ErrorCard message={data.isError} />
      ) : (
        <>
          <div className='row align-items-center pb-3 px-3 mx-0'>
            <div className='col-12'>
              <Formik
                enableReinitialize={true}
                initialValues={itInfra}
                // validationSchema={InfraSchema}
                onSubmit={(fields) => {
                  const payload = {
                    data: {
                      fields: fields,
                    },
                    warehouseId: warehouseId,
                    slug: slug,
                  };

                  dispatch(updateWarehousependingDetails(payload));
                }}
                render={({ errors, status, onChange, touched }) => {
                  const fieldClass = (fieldName, isYes) => {
                    return (
                      'form-control bg-white px-4 mb-2' +
                      (isYes ? (errors[fieldName] && touched[fieldName] ? ' is-invalid' : '') : '')
                    );
                  };

                  return (
                    <Form>
                      <div className='row bg-white rounded mx-0 col-xxxl-11'>
                        <div className='col-12'>
                          <h5 className='pb-1 mb-3 border-bottom'>IT Infra</h5>
                        </div>
                        <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                          <table className='table customTable'>
                            <tbody>
                              <tr>
                                <td className='col-auto'>
                                  <p className='mb-0 pb-2'>Desktop:</p>
                                </td>
                                <td className='col-auto'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='desktopYes'
                                      checked={desktopYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='desktopOption'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='desktopOption'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='desktopYes'
                                      checked={!desktopYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='desktopOption2'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='desktopOption2'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <>
                                    <Field
                                      disabled
                                      name='itInfra.desktop.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <p className=''> Qty:</p>
                                  <Field
                                    disabled={!desktopYes}
                                    name='itInfra.desktop.value'
                                    type='number'
                                    className={fieldClass('desktop', desktopYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='desktop'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Printer:</p>
                                </td>
                                <td className='col-auto'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='printerYes'
                                      checked={printerYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='printerYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='printerYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='printerYes'
                                      checked={!printerYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='printerYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='printerYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='itInfra.printer.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    disabled={!printerYes}
                                    name='itInfra.printer.value'
                                    type='number'
                                    className={fieldClass('printer', printerYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='printer'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Printer (Multi Function):</p>
                                </td>
                                <td className='col-auto'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='printerMultiFunctionYes'
                                      checked={printerMultiFunctionYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='printerMultiFunctionYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='printerMultiFunctionYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='printerMultiFunctionYes'
                                      checked={!printerMultiFunctionYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='printerMultiFunctionYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='printerMultiFunctionYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>
                                  <>
                                    <Field
                                      disabled
                                      name='itInfra.printerMultifunction.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='itInfra.printerMultifunction.value'
                                    type='number'
                                    disabled={!printerMultiFunctionYes}
                                    className={fieldClass(
                                      'printerMultiFunction',
                                      printerMultiFunctionYes,
                                    )}
                                    placeholder='value'
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='printerMultiFunction'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>UPS / Inverter:</p>
                                </td>
                                <td className='col-auto '>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='upsYes'
                                      checked={upsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='upsYes'
                                      hidden
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='upsYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='upsYes'
                                      checked={!upsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='upsYes1'
                                      hidden
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='upsYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='itInfra.upsAndInverter.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='itInfra.upsAndInverter.value'
                                    disabled={!upsYes}
                                    type='number'
                                    className={fieldClass('ups', upsYes)}
                                    placeholder='KVA'
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='ups'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Broadband:</p>
                                </td>
                                <td className='col-auto  mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='broadbandYes'
                                      checked={broadbandYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='broadbandYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='broadbandYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='broadbandYes'
                                      checked={!broadbandYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='broadbandYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='broadbandYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>

                                <td className=''>
                                  <p>WHS Remarks:</p>
                                  <>
                                    <Field
                                      disabled
                                      name='itInfra.broadBand.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='itInfra.broadBand.value'
                                    type='number'
                                    disabled={!broadbandYes}
                                    className={fieldClass('broadband', broadbandYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='broadband'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Scanner:</p>
                                </td>
                                <td className='col-auto  mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='scannerYes'
                                      checked={scannerYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='scannerYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='scannerYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='scannerYes'
                                      checked={!scannerYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='scannerYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='scannerYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>
                                  <>
                                    <Field
                                      disabled
                                      name='itInfra.scanner.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='itInfra.scanner.value'
                                    type='number'
                                    disabled={!scannerYes}
                                    className={fieldClass('scanner', scannerYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='scanner'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Telephone(Landline):</p>
                                </td>
                                <td className='col-auto mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='landlineYes'
                                      checked={landlineYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='landlineYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='landlineYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='landlineYes'
                                      checked={!landlineYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='landlineYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='landlineYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='itInfra.telephone.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    type='number'
                                    name='itInfra.telephone.value'
                                    disabled={!landlineYes}
                                    className={fieldClass('landline', landlineYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='landline'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>WMS:</p>
                                </td>
                                <td className='col-auto mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='wmsYes'
                                      checked={wmsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='wmsYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='wmsYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='wmsYes'
                                      checked={!wmsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='wmsYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='wmsYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='itInfra.wms.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className='' colSpan='2'>
                                  <Field
                                    name='itInfra.wms.value'
                                    disabled={!wmsYes}
                                    className={fieldClass('wms', wmsYes)}
                                    placeholder='Enter WMS Company'
                                  />
                                  <ErrorMessage
                                    name='wms'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-5 pb-1'>Others:</p>
                                </td>
                                <td className='mw-150px'></td>
                                <td className='mw-150px'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='itInfra.itinfra_other.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className='' colSpan='3'>
                                  <Field
                                    as='textarea'
                                    name='itInfra.itinfra_other.value'
                                    className={
                                      'form-control bg-white px-4 rounded-sm' +
                                      (errors.others && touched.others ? ' is-invalid' : '')
                                    }
                                    placeholder=''
                                    rows='3'
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='others'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className='row bg-white px-4 py-3 rounded mx-0 col-xxxl-11'>
                        <div className='col-12'>
                          <h5 className='py-3 mb-3 border-bottom'>Office Space</h5>
                        </div>
                        <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                          <table className='table customTable'>
                            <tbody>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Area (sqft):</p>
                                </td>
                                <td className='mw-120px'></td>
                                <td className='mw-150px'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.area.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className='' colSpan='2'>
                                  <Field
                                    type='number'
                                    name='officeSpace.area.value'
                                    className={
                                      'form-control bg-white px-4mb-2' +
                                      (errors.area && touched.area ? ' is-invalid' : '')
                                    }
                                    placeholder='Enter Area'
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='area'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Air Conditioner:</p>
                                </td>
                                <td className='col-auto mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='airConditionerYes'
                                      checked={airConditionerYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='airConditionerYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='airConditionerYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='airConditionerYes'
                                      checked={!airConditionerYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='airConditionerYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='airConditionerYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.airConditioner.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='officeSpace.airConditioner.value'
                                    type='number'
                                    disabled={!airConditionerYes}
                                    className={fieldClass('airConditioner', airConditionerYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='airConditioner'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Work Stations:</p>
                                </td>
                                <td className='col-auto mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='workStationsYes'
                                      checked={workStationsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='workStationsYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='workStationsYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='workStationsYes'
                                      checked={!workStationsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='workStationsYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='workStationsYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.workStations.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='officeSpace.workStations.value'
                                    type='number'
                                    disabled={!workStationsYes}
                                    className={fieldClass('workStations', workStationsYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='workStations'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Tables:</p>
                                </td>
                                <td className='col-auto mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='tablesYes'
                                      checked={tablesYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='tablesYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='tablesYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='tablesYes'
                                      checked={!tablesYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='tablesYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='tablesYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.tables.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='officeSpace.tables.value'
                                    type='number'
                                    disabled={!tablesYes}
                                    className={fieldClass('tables', tablesYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='tables'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Cabins:</p>
                                </td>
                                <td className='col-auto  mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='cabinsYes'
                                      checked={cabinsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='cabinsYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='cabinsYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='cabinsYes'
                                      checked={!cabinsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='cabinsYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='cabinsYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.cabins.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='officeSpace.cabins.value'
                                    type='number'
                                    disabled={!cabinsYes}
                                    className={fieldClass('cabins', cabinsYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='cabins'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Meeting Rooms:</p>
                                </td>
                                <td className='col-auto  mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='meetingRoomsYes'
                                      checked={meetingRoomsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='meetingRoomsYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='meetingRoomsYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='meetingRoomsYes'
                                      checked={!meetingRoomsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='meetingRoomsYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='meetingRoomsYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.meetingRooms.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='officeSpace.meetingRooms.value'
                                    type='number'
                                    disabled={!meetingRoomsYes}
                                    className={fieldClass('meetingRooms', meetingRoomsYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='meetingRooms'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Record Rooms:</p>
                                </td>
                                <td className='col-auto mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='recordRoomsYes'
                                      checked={recordRoomsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='recordRoomsYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='recordRoomsYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='recordRoomsYes'
                                      checked={!recordRoomsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='recordRoomsYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='recordRoomsYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.recordRooms.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='officeSpace.recordRooms.value'
                                    type='number'
                                    disabled={!recordRoomsYes}
                                    className={fieldClass('recordRooms', recordRoomsYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='recordRooms'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Strong Rooms:</p>
                                </td>
                                <td className='col-auto mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='strongRoomsYes'
                                      checked={strongRoomsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='strongRoomsYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='strongRoomsYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='strongRoomsYes'
                                      checked={!strongRoomsYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='strongRoomsYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='strongRoomsYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.strongRooms.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='officeSpace.strongRooms.value'
                                    type='number'
                                    disabled={!strongRoomsYes}
                                    className={fieldClass('strongRooms', strongRoomsYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='strongRooms'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Security Guard Office / Table:</p>
                                </td>
                                <td className='col-auto mt-1'>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={true}
                                      name='guardOfficeYes'
                                      checked={guardOfficeYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='guardOfficeYes'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='guardOfficeYes'
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className='form-check common-radio-deep-blue mx-3'>
                                    <input
                                      onChange={(e) => handleChange(e)}
                                      value={false}
                                      name='guardOfficeYes'
                                      checked={!guardOfficeYes}
                                      className='common-radio-deep-blue-input'
                                      type='radio'
                                      id='guardOfficeYes1'
                                      hidden
                                      disabled={viewMood}
                                    />
                                    <label
                                      className='common-radio-deep-blue-label pl-4'
                                      htmlFor='guardOfficeYes1'
                                    >
                                      No
                                    </label>
                                  </div>
                                </td>
                                <td className='col-auto mt-1'>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.securityGuardOfficeTable.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className=''>
                                  <Field
                                    name='officeSpace.securityGuardOfficeTable.value'
                                    type='number'
                                    disabled={!guardOfficeYes}
                                    className={fieldClass('guardOffice', guardOfficeYes)}
                                    placeholder=''
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='officeSpace.securityGuardOfficeTable.value'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td className=''>
                                  <p className='mb-0 pb-1'>Electric Load:</p>
                                </td>
                                <td className='mw-130px'></td>
                                <td>
                                  <p>WHS Remarks:</p>

                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.electricLoad.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className='' colSpan='2'>
                                  <Field
                                    name='officeSpace.electricLoad.value'
                                    className={
                                      'form-control bg-white px-4'
                                      // (errors.electricLoad && touched.electricLoad
                                      //   ? ' is-invalid'
                                      //   : '')
                                    }
                                    placeholder='KVA'
                                    readOnly={viewMood}
                                  />
                                  <ErrorMessage
                                    name='officeSpace.electricLoad.value'
                                    component='div'
                                    className='invalid-feedback'
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td className=''>
                                  <p className='mb-5 pb-1'>Others:</p>
                                </td>
                                <td className='mw-130px'></td>
                                <td>
                                  <p>WHS Remarks:</p>
                                  <>
                                    <Field
                                      disabled
                                      name='officeSpace.officespace_other.whsremark'
                                      type='text'
                                      className='form-control px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </>
                                </td>
                                <td className='' colSpan='3'>
                                  <Field
                                    as='textarea'
                                    name='officeSpace.officespace_other.value'
                                    className={'form-control bg-white px-4 rounded-sm'}
                                    placeholder=''
                                    rows='3'
                                    readOnly={viewMood}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className={`col-12 mt-4 ${viewMood ? 'd-none' : ''}`}>
                          <div className='row justify-content-end'>
                            {Object.keys(errors).length !== 0 ? (
                              <FormErrorCard message='Fill All Required Fields' />
                            ) : null}
                            {data.isError !== '' ? <FormErrorCard message={data.isError} /> : null}

                            <div className='col-auto'>
                              <button
                                type='submit'
                                disabled={data.isPending}
                                className='btn btn-deep-blue add-className remove-class'
                              >
                                Save
                                {data.isPending ? <Spinner animation='border' /> : null}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UpdateITInfraForm;

import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const WarehouseList = ({ data, index }) => {
  console.log(data, "**data**")
  const { id, vendorStatus, adminStatus, warehouseName, category, type } = data;
  const categoryList = useSelector((state) => state.CATEGORY_INFO.categoryList);
  const typeList = useSelector((state) => state.WHY_INFO.typeList.data);

  const warehouseCategory = (id) => {
    // console.log(categoryList, "||categoryList||,i", id)
    if (categoryList !== null && categoryList !== undefined && id !== undefined && id !== null) {
      const categoryListAd = categoryList.find((currentvalue, index) => currentvalue.id === id)
      // console.log(categoryListAd, "|*|categoryListAd|*|")
      return categoryListAd.categoryName;
    }
  }
  const warehouseType = (id) => {
    if (typeList !== null && typeList !== undefined && id !== undefined && id !== null) {
      const typeListAd = typeList.find((currentvalue, index) => currentvalue.id === id)
      console.log(typeListAd, "|&|***|&|")
      return typeListAd.type;
    }
  }

  return (
    <tr>
      {/* <td>763247AH</td> */}
      <td className='text-center'>{index}</td>
      <td className='text-capitalize'>{warehouseName}</td>
      <td className='text-capitalize'>{warehouseCategory(data.warehouseCategory)} </td>
      <td>{warehouseType(data?.warehouseType)}</td>
      <td>
        {adminStatus === 'Rejected' ? (
          <>
            <div className='text-left' style={{ float: 'left' }}>
              <Link
                to={`/vendor/warehouse/update-warehouse-rejected/${id}/contact-detail`}
                className='btn px-0 text-deep-blue font-weight-bold text-uppercase'
              >
                <i className='fa fa-edit'></i>
              </Link>
            </div>
            <div className='text-right'>
              {/* <Link
              to={`/vendor/warehouse/warehouse-details/${id}`}
              className="btn font-weight-bold px-1"
              >
                <i className="fas fa-eye"></i>
              </Link> */}
            </div>
          </>
        ) : (
          <>
            <div className='text-left' style={{ float: 'left' }}>
              <span className='btn px-0 text-deep-blue font-weight-bold text-uppercase'>
                <i className='fa fa-edit'></i>
              </span>
            </div>
            <div className='text-right'>
              {/* <Link
              to={`/vendor/warehouse/warehouse-details/${id}`}
              className="btn font-weight-bold px-1"
              >
                <i className="fas fa-eye"></i>
              </Link> */}
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default WarehouseList;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import axiosauth from '../../../api/axios-auth';
import { FormSuccess } from '../../../components/helper/FormSuccess';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchWarehouseByPage } from '../../../store/actions/vendor/warehouseList';
import { useDispatch, useSelector } from 'react-redux';
// import { BsJournalArrowDown } from 'react-icons/bs';

const WarehouseList = ({ data, index, pageCount, warehouseId }) => {
  // console.log(data, '**&&& Helo abcd &&& I AM warehouseList page**');

  const categoryList = useSelector((state) => state.CATEGORY_INFO.categoryList);
  const typeList = useSelector((state) => state.WHY_INFO.typeList.data);

  const { id, adminStatus, warehouseName } = data;
  // const warehouseCategoryAry = ["Custom Bonded", "General WH"]
  // const warehouseTypeAry = ["General"]

  // console.log('***Data Details category***', data);
  const [modalShow, setModalShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();

  const [error, setError] = useState('');


  const [fetureDate, setFetureData] = useState({
    startDate: '',
    endDate: '',
    commentByCustomer: '',
  });

  const handleChange = (e) => {
    setFetureData({ ...fetureDate, [e.target.name]: e.target.value });
    setError(null);
  };
  const warehouseCategory = (id) => {
    const categoryListAd = categoryList.find((currentvalue, index) => currentvalue.id === id)
    return categoryListAd.categoryName;
  }
  const warehouseType = (id) => {
    const typeListAd = typeList.find((currentvalue, index) => currentvalue.id === id)
    return typeListAd.type;
  }

  const submitFuture = () => {
    if (
      fetureDate.startDate === '' ||
      fetureDate.endDate === '' ||
      fetureDate.commentByCustomer === ''
    ) {
      setError('Field is required');
      return 0;
    }
    let data = {
      warehouse: id,
      startDate: fetureDate.startDate,
      endDate: fetureDate.endDate,
      commentByCustomer: fetureDate.commentByCustomer,
    };

    try {
      axiosauth
        .post(`/api/v1/featuredwarehouse`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log(res);
          dispatch(fetchWarehouseByPage(parseInt(pageCount)));
          setIsSuccess(true);
          setModalShow(false);
        })
        .catch((error) => {
          console.log('rerr-->', error);
        })
        .then(() => {
          console.log('-----always executes');
        });
    } catch (e) { }
  };

  return (
    <>
      {/* <FontAwesomeIcon icon="fa-solid fa-badge-check" /> */}
      {isSuccess ? (
        <FormSuccess onClick={() => setIsSuccess(false)} message={`Feture Request Send`} />
      ) : null}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size='xm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Request For Feature Warehouse
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='form-group col-md-6'>
              <label htmlFor='startdate'>
                Start Date<span className='errorMsg'>*</span>
              </label>
              <input
                type='date'
                onChange={handleChange}
                name='startDate'
                className='form-control'
                id='startdate'
                aria-describedby='emailHelp'
                placeholder='start date'
                required={true}
              />
            </div>

            <div className='form-group col-md-6'>
              <label htmlFor='enddate'>
                End Date<span className='errorMsg'>*</span>
              </label>
              <input
                type='date'
                onChange={handleChange}
                name='endDate'
                className='form-control'
                id='enddate'
                placeholder='end date'
                required={true}
              />
            </div>
            <div className='form-group col-12'>
              <label htmlFor='commentByCustomer'>
                Comment<span className='errorMsg'>*</span>
              </label>
              <textarea
                type='textarea'
                onChange={handleChange}
                name='commentByCustomer'
                className='form-control'
                id='commentByCustomer'
                placeholder='Type comment'
                required={true}
              />
            </div>
            <span className='errorMsg'>{error ? `* ${error}` : null}</span>
          </div>

          <button onClick={submitFuture} type='button' className='btn btn-primary mt-3'>
            Submit
          </button>
        </Modal.Body>
      </Modal>

      <tr>
        <td className='text-capitalize px-2'> {index} </td>
        <td className='text-capitalize px-2' style={{ textAlign: 'center' }}>
          {' '}
          {data?.warehouseId ? data?.warehouseId : '--'}{' '}
        </td>
        <td className='text-capitalize px-2'> {warehouseName} </td>
        <td className='px-2'> {warehouseCategory(data.warehouseCategory)}</td>
        <td className='font-weight-bold px-2'> {warehouseType(data.warehouseType)}  </td>
        <td className='font-weight-bold px-2'>
          {adminStatus.replaceAll("_", " ")}
          {adminStatus === "Listed_but_Unverified" ? <> {' '} <i className="fa fa-wrench" title="To get verify warehouse you have to fill all forms!"></i> {'  '}</> : ""}
        </td>
        <td className='px-2 text-center'>
          {adminStatus === 'Listed_but_Unverified' ? (
            <>
              <Link
                to={`/vendor/warehouse/update-warehouse/${id}/contact-detail`}
                className='btn px-0 text-deep-blue font-weight-bold text-uppercase'
              >
                <i className='fa fa-edit'></i>
              </Link>
            </>
          ) : (
            <>
              <span className='btn disabled px-0 font-weight-bold d-none'>
                <i className='fa fa-edit'></i>
              </span>
              <Link
                to={`/vendor/warehouse/warehouse-details/${id}/contact-detail`}
                className='btn font-weight-bold px-1'
              >
                <i className='fas fa-eye'></i>
              </Link>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

export default WarehouseList;

const latlong = [
  {
    lat: 11.66702557,
    long: 92.73598262,
  },
  {
    lat: 25.78541445,
    long: 87.4799727,
  },
  {
    lat: 23.80039349,
    long: 86.41998572,
  },
  {
    lat: 21.30039105,
    long: 76.13001949,
  },
  {
    lat: 25.162998,
    long: 75.845322,
  },
];

export default latlong;
